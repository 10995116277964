import {
  faFemale,
  faMale,
  faUser,
  faIdCard,
  faGavel,
  faMapMarkerAlt,
  faBriefcase,
  faGraduationCap,
  faDatabase,
  faPrescriptionBottleAlt,
  faCreditCard,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import moment from 'moment/moment';

export const statusFilters = [
  { label: 'All', value: 'All' },
  { label: 'Active', value: 'active' },
  { label: 'In-Active', value: 'inactive' }
];
//Emp Doc types
export const DOCTYPES = [
  { label: 'Salary Slip', value: 'SalarySlip' },
  { label: 'Experience Letter', value: 'ExperienceLetter' },
  { label: 'Relieving Letter', value: 'RelievingLetter' },
  { label: 'Appointment Letter', value: 'AppointmentLetter' },
  { label: 'Other', value: 'Other' }
];

export const COMPENSATIONTYPES = [
  { label: 'Per Month', value: 'per month' },
  { label: 'Per Year', value: 'per year' }
];

export const DataFoundStatus = [
  { label: 'Select status', value: null },
  { label: 'Verified', value: 5 },
  { label: 'Unable to Verify', value: 8 },
  { label: 'Discrepancy', value: 11 },
  { label: 'N/A', value: 11 }
];

export const courseType = [
  { label: 'No Education', value: 'NO_EDUCATION' },
  { label: 'Less than Fifth', value: 'LESS_THEN_FIFTH_STD' },
  { label: 'Fifth', value: 'FIFTH_STD' },
  { label: 'Tenth', value: 'TENTH_STD' },
  { label: 'Twelfth', value: 'TWELFTH_STD' },
  { label: 'Diploma', value: 'DIPLOMA' },
  { label: 'Graduate', value: 'GRADUATE' },
  { label: 'Masters', value: 'MASTERS' },
  { label: 'PhD', value: 'PHD' },
  { label: 'Postdoctoral', value: 'POST_DOC' },
  { label: 'Post Graduate Diploma', value: 'POST_GRADUATE_DIPLOMA' },
  { label: 'Other', value: 'OTHER' },
  { label: 'University', value: 'UNIVERSITY' },
  { label: 'High School', value: 'HIGH_SCHOOL' },
  { label: 'Professional Course', value: 'PROFESSIONAL_COURSE' },
  { label: 'Post Graduation', value: 'POST_GRADUATION' }
];

export const attendanceType = [
  { label: 'Regular', value: 'REGULAR' },
  { label: 'Part-time', value: 'PARTTIME' },
  { label: 'Distance learning', value: 'DISTANCE' }
];

export const documentType = [
  { label: 'Consolidated Marks Sheet', value: 'Consolidated Marks Sheet' },
  { label: 'Degree Certificate', value: 'Degree Certificate' },
  { label: 'Degree Certificate + All semester marksheet', value: 'Degree Semester' },
  { label: 'Provisional Certificate', value: 'Provisional Certificate' },
  { label: 'Other', value: 'Other' }
];

export const uploadDocumentType = [
  { label: 'Certificate', value: 'CERTIFICATE' },
  { label: 'Grade Sheet', value: 'GRADE_SHEET' }
];

export const docFrontBackType = [
  { label: 'FRONT', value: 'FRONT' },
  { label: 'BACK', value: 'BACK' },
  { label: 'COMBINED', value: 'COMBINED' }
];

export const inputDateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const displayDateTimeFormat = 'DD MMM YYYY hh:mm A';

export const commentDateTimeFormat = 'DD MMM YYYY hh:mm A';

export const displayDateFormat = 'DD MMM YYYY';

export const displayDateReportFormat = 'DD-MMM-YYYY';

export const displayMonthAndYearFormat = 'MMM YYYY';

export const displayDateNewReportFormat = 'MMMM YYYY';

export const INPUT_VALUE_DATEFORMAT = 'YYYY-MM-DD';

export const INPUT_VALUE_DATE_YEAR_MONTH_FORMAT = 'YYYY-MM';

export const INPUT_VALUE_DATE_YEAR_FORMAT = 'YYYY';

export const displayUnbilledDateFormat = 'MMM YYYY';

export const DATE_VIEWS = {
  YEAR: { format: 'YYYY', views: ['year'] },
  YEAR_MONTH: { format: 'YYYY-MM', views: ['year', 'month'] },
  YEAR_MONTH_DATE: { format: 'YYYY-MM-DD', views: ['year', 'month', 'date'] }
};

// Gender Icons
export const GENDER = {
  1: { value: 'M', icon: faMale, text: 'Male' },
  2: { value: 'F', icon: faFemale, text: 'Female' },
  3: { value: 'NB', icon: faUser, text: 'Non-binary' },
  4: { value: 'NA', icon: faUser, text: 'Prefer not to disclose' }
};

export const CANDIDATE_USER_TYPE = {
  1: 'SA',
  2: 'CA',
  3: 'Candidate',
  4: 'System',
  5: 'API',
  6: 'Alumni'
};

export const educationDocType = {
  GRADE_SHEET: 'Grade Sheet',
  CERTIFICATE: 'Certificate',
  FRONT: 'Front',
  BACK: 'Back',
  COMBINED: 'Combined',
  VP_EXTERNAL_REPORT: 'VP External Report'
};

export const completedStatus = [1, 2, -2, 6, '1', '2', '-2', '6', 12, '12'];

export const ocrValues = {
  Pan: 'ind_pan',
  Passport: 'ind_passport',
  driving: 'ind_driving_license',
  VoterId: 'ind_voter_id',
  Aadhaar: 'ind_aadhaar'
};

export const EMP_VERFICATION_TYPE = [
  { label: 'Physical', value: 'Physical' },
  { label: 'Electronic', value: 'Electronic' }
];

export const EMP_VERFICATION_SOURCE = [
  { label: 'Official', value: 'Official' },
  { label: 'Reporting Manager', value: 'RM' },
  { label: 'UAN', value: 'UAN' },
  { label: 'ITR', value: 'ITR' },
  { label: 'Bank Statement', value: 'BankStatement' }
];

export const RECOMMENDHIRE = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: -1 }
];

export const WORLDCHECKSOURCE = {
  TransUnion: 'TLOxp Global Watch List Search',
  Refinitiv: 'WORLD-CHECK ONE'
};

export const INSUFF_STATUSES = [
  { label: 'Open L1', value: 'OPEN_L1' },
  { label: 'Open L2', value: 'OPEN_L2' },
  { label: 'Review clearance L1', value: 'REVIEW_CLEARANCE_L1' },
  { label: 'Review clearance L2', value: 'REVIEW_CLEARANCE_L2' },
  { label: 'Unresolved', value: 'UNRESOLVED' },
  { label: 'Resolved', value: 'RESOLVED' }
];

export const INSUFF_REVIEW_STATUSES = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Review Clearance', value: 'REVIEW_CLEARANCE' },
  { label: 'Unresolved', value: 'UNRESOLVED' },
  { label: 'Resolved', value: 'RESOLVED' }
];

export const EMAIL_INFORMATION_STATUS = [
  { label: 'Candidate Completed', value: 'CANDIDATE' },
  { label: 'Check Completed', value: 'CHECK' }
];

export const EMAIL_FREQUENCY_STATUS = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' }
];

export const APICONSTANTS = {
  GET: 'get',
  UPDATE: 'put',
  SET: 'post',
  DEL: 'delete'
};

export const EMP_DOC_TYPES = {
  SalarySlip: 'Salary Slip',
  ExperienceLetter: 'Experience Letter',
  RelievingLetter: 'Relieving Letter',
  AppointmentLetter: 'Appointment Letter',
  Other: 'Other'
};
///0
export const PLAIN_CHECK_ICONS = {
  identity: { icon: faIdCard, title: 'Identity Verification' },
  court: { icon: faGavel, title: 'Court Record' },
  address: { icon: faMapMarkerAlt, title: 'Address Verification' },
  employment: { icon: faBriefcase, title: 'Employment Verification' },
  education: { icon: faGraduationCap, title: 'Education Verification' },
  education_official: { icon: faGraduationCap, title: 'Education Verification' },
  education_check: { icon: faGraduationCap, title: 'Education Verification' },
  world: { icon: faDatabase, title: 'World Check' },
  drug: { icon: faPrescriptionBottleAlt, title: 'Drug Check' },
  credit: { icon: faCreditCard, title: 'Cibil check' },
  cibil: { icon: faCreditCard, title: 'Cibil check' },
  reference: { icon: faUsers, title: 'Reference' },
  custom: {
    icon: VerifiedUserIcon,
    title: 'Custom'
  },
  custom_check: {
    icon: VerifiedUserIcon,
    title: 'Custom'
  }
};

export const PACKAGE_TYPE = {
  PREPAID: 'Prepaid package',
  POSTPAID: 'Postpaid package'
};

export const CHECK_TYPE = {
  identity: 'Identity',
  employment: 'Employment',
  education: 'Education',
  address: 'Address',
  court: 'Court',
  reference: 'Reference',
  cibil: 'Credit Check',
  world: 'World',
  drug: 'Drug',
  custom: 'Custom',
  credit: 'Credit Check',
  drugs: 'Drug'
};

export const INDENTITES_FOR_PACKAGE_CONFIG = [
  { name: 'PAN', label: 'PAN' },
  { name: 'AADHAAR', label: 'Aadhaar' },
  { name: 'DL', label: 'Driving License' },
  { name: 'VOTER_ID', label: 'Voter ID' },
  { name: 'PASSPORT', label: 'Passport' }
];

export const ADDRESSES_FOR_PACKAGE_CONFIG = [
  { name: 'PERMANENT', label: 'Permanent Address' },
  { name: 'CURRENT', label: 'Current Address' }
];

export const EDUCATION_TYPES_FOR_PACKAGE_CONFIG = [
  { name: '10', label: 'Tenth' },
  { name: '12', label: 'Twelfth / Diploma' },
  { name: 'UNDERGRAD', label: 'Graduate' },
  { name: 'POSTGRAD', label: 'Post Graduation' },
  { name: 'PHD', label: 'PhD' }
];

export const DRUG_PANEL_TYPE = [
  { label: '5 PANEL', value: '5 PANEL' },
  { label: '7 PANEL', value: '7 PANEL' },
  { label: '10 PANEL', value: '10 PANEL' }
];

export const ADDRESS_FOR_CUSTOMIZE_PACKAGE_CONFIG = [
  { name: 'ANY_1', label: 'Any 1 address' },
  { name: 'PERMANENT', label: 'Permanent Address' },
  { name: 'CURRENT', label: 'Current Address' }
];

export const DRUG_TEXT = {
  '5_PANEL': '5 PANEL',
  '7_PANEL': '7 PANEL',
  '10_PANEL': '10 PANEL'
};

export const IdentityArr = {
  PAN: 'PAN Card',
  DL: 'Driving license',
  VOTER_ID: 'Voter ID',
  AADHAAR: 'Aadhaar',
  PASSPORT: 'Passport'
};

export const AddressArr = {
  CURRENT: 'Current address',
  PERMANENT: 'Permanent address'
};
export const EducationArr = {
  10: 'Tenth',
  12: 'Twelfth / Diploma',
  UG: 'Graduate',
  PG: 'Post Graduation',
  UNDERGRAD: 'Graduate',
  POSTGRAD: 'Post Graduation',
  PHD: 'PhD',
  NONE: 'NONE',
  NO_EDUCATION: 'No Education',
  LESS_THEN_FIFTH_STD: 'Less then fifth std',
  FIFTH_STD: 'Fifth std',
  MASTERS: 'Masters',
  POST_DOC: 'Post Doc',
  POST_GRADUATE_DIPLOMA: 'Post Graduation diploma',
  OTHER: 'Other',
  UNIVERSITY: 'University',
  HIGH_SCHOOL: 'High school',
  PROFESSIONAL_COURSE: 'Professional course'
};

export const DRUG_PACKAGE_PANEL_TYPE = [
  { label: '5 PANEL', value: '5_PANEL' },
  { label: '7 PANEL', value: '7_PANEL' },
  { label: '10 PANEL', value: '10_PANEL' }
];
export const ADMIN_COMMUNICATION = [
  { label: 'All', value: 'all' },
  { label: 'Important Only', value: 'important_only' },
  { label: 'None', value: 'none' }
];

export const MONTHS_LIST = [
  { label: 'None', value: -1 },
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

export const EVENT_LIST = [
  { label: '- Add-on Check', value: 'ADDON_BOUGHT' },
  { label: '+ Add-on Check Refund', value: 'CHECK_REFUND' },
  { label: '- Additional Cost', value: 'ADDITIONAL_CHARGE' },
  { label: '+ Additional Cost Refund', value: 'ADDITIONAL_CHARGE_REFUND' },
  { label: '- Verification Type Convert', value: 'CHECK_VERIFICATION_TYPE_CONVERSION' }
];
export const EVENT_PREPAID = [
  { label: '- Credit Note', value: 'CREDIT_NOTE' },
  { label: '+ Split Purchase', value: 'SPLIT_PURCHASE' },
  { label: '+ Purchase(Recharge)', value: 'RECHARGE' },
  { label: '- Refund to Customer', value: 'CREDIT_REFUND' },
  { label: '- Package', value: 'PACKAGE_BOUGHT' },
  { label: '+ Package Refund', value: 'PACKAGE_UNITS_CONVERTED_TO_CREDITS' },
  { label: '+ Free Credits', value: 'FREE_CREDITS' },
  { label: '+ Free Credits (Trial)', value: 'FREE_CREDITS_TRIAL' },
  { label: '+ Bridge Credits', value: 'BRIDGE_CREDITS' },
  { label: '- Bridge Credits Deduction', value: 'CREDIT_DEDUCTION_BRIDGE_CREDITS' },
  { label: '- Credit Adjustment', value: 'CREDIT_DEDUCTION' },
  { label: '- Credits Locked', value: 'CREDITS_LOCKED' },
  { label: '+ Credits Unlocked', value: 'CREDITS_UNLOCKED' },
  { label: '+ Alumni Verification Bonus', value: 'ALUMNI_VERIFICATION_BONUS' }
];
export const EVENT_POSTPAID = [
  { label: '- Candidate added', value: 'CANDIDATE_ADDED' },
  { label: '+ Candidate deleted', value: 'CANDIDATE_DELETED' },
  { label: '+ Discount', value: 'DISCOUNT' },
  { label: '+ Penalty', value: 'PENALTY' },
  { label: '+ Trial', value: 'TRIAL' },
  { label: '+ Invoice paid', value: 'INVOICE_PAID' },
  { label: '+ Credit limit increased', value: 'CREDIT_LIMIT_INCREASED' },
  { label: '- Credit limit decreased', value: 'CREDIT_LIMIT_DECREASED' },
  { label: '+ Discount (Alumni Credits Redeemed)', value: 'CREDITS_APPLIED_AS_DISCOUNT' }
];

export const POSTPAID_BILLING_TYPE = {
  CHECK_COMPLETED: 'On Completion',
  CHECK_INTIATION: 'On Intiation'
};

export const POSTPAID_BILLING_STATUSES = [
  { label: 'Under Review', value: 'UNDER_REVIEW' },
  { label: 'Invoice Issued', value: 'INVOICE_ISSUED' },
  { label: 'Payment Completed', value: 'PAYMENT_COMPLETED' }
];

export const POSTPAID_BILLING_STATUS = {
  UNDER_REVIEW: 'Under Review',
  INVOICE_ISSUED: 'Invoice Issued',
  PAYMENT_COMPLETED: 'Payment Completed'
};

export const POSTPAID_BILLING_DISCOUNT_TYPES = [
  { label: 'Discount', value: 'DISCOUNT' },
  { label: 'Trial', value: 'TRIAL' }
];

export const POSTPAID_BILLING_PAYMENTS_MODES = [
  { label: 'Cash', value: 'CASH' },
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Demand Draft', value: 'DEMAND_DRAFT' },
  { label: 'Payment Gateway', value: 'PAYMENT_GATEWAY' },
  { label: 'Cheque', value: 'CHEQUE' },
  { label: 'NEFT/UPI', value: 'NEFT_OR_UPI' }
];

export const POSTPAID_BILLING_PAYMENTS_MODES_VALUES = {
  CASH: 'Cash',
  CC: 'CC',
  DD: 'DD',
  CHEQUE: 'Cheque',
  NEFT: 'NEFT/UPI'
};
export const showAddOnLabel = (addon) => {
  const addOnCases = {
    address: addon['address'].length,
    cibil: addon['cibil'].cost,
    court: addon['court'].length,
    custom: addon['custom'].length,
    drug: addon['drug'].length,
    education: addon['education']?.cost || addon['education']?.config?.length,
    employment: addon['employment'].cost,
    identity: addon['identity'].length,
    reference: addon['reference'].length,
    world: addon['world'].cost
  };

  for (let check in addon) {
    if (addOnCases[check]) {
      return true;
    }
  }
  return false;
};

export const EDUCATION_TYPES = [
  { name: 'OFFICIAL', label: 'Official Verification' },
  { name: 'REGIONAL_PARTNER', label: 'Regional Partner Verification' },
  { name: 'HYBRID', label: 'Hybrid Verification' }
];

export const EDUCATION_TYPES_ADDONS = {
  OFFICIAL: 'education',
  REGIONAL_PARTNER: 'educationThirdParty',
  HYBRID: 'educationHybrid'
};

export const BILLING_MONTHS_LIST = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

export const UNBILLED_MONTHS_LIST = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];

export const CHECKS_LIMIT = {
  ID: 5,
  ADD: 2,
  COURT: 2,
  EMP: 10,
  DRUG: 1,
  EDU: 5,
  REF: 10,
  WORLD: 1,
  CIBIL: 1
};

export const CallLogsFilter = [
  // using isWide key for the css part for setting it properly that aligning upper and lower check box on same level and isRight key for the filters params
  {
    label: 'Completed: Outgoing',
    value: 'COMPLETED_OUTGOING',
    checked: false,
    isWide: true,
    isRights: false
  },
  {
    label: 'Completed: Incoming',
    value: 'COMPLETED_INCOMING',
    checked: false,
    isWide: true,
    isRights: false
  },
  // { label: 'Incomplete', value: 'INCOMPLETE', checked: false, isRights: false },
  { label: 'Missed', value: 'MISSED', checked: false, isRights: false },
  { label: 'Failed', value: 'FAILED', checked: false, isRights: false },
  { label: 'ADD', value: 'ADDRESS', checked: false, isRights: true },
  { label: 'EDU', value: 'EDUCATION', checked: false, isWide: true, isRights: true },
  { label: 'EMP', value: 'EMPLOYMENT', checked: false, isWide: true, isRights: true },
  { label: 'REF', value: 'REFERENCE', checked: false, isRights: true },
  { label: 'Escalation', value: 'ESCALATION', checked: false, isRights: true },
  {
    label: 'Customer Support',
    value: 'CUSTOMER_SUPPORT',
    checked: false,
    isWide: true,
    isRights: true
  }
];
// preipaid update balance modal constants

export const PAYMENT_MODE = [
  { value: 'CASH', label: 'Cash' },
  { value: 'CREDIT_CARD', label: 'Credit / Debit Card' },
  { value: 'DEMAND_DRAFT', label: 'Demand Draft' },
  { value: 'CHEQUE', label: 'Cheque' },
  { value: 'NEFT_OR_UPI', label: 'Bank Transfer' },
  { value: 'PAYMENT_GATEWAY', label: 'Payment Gateway' }
];
export const TRANSACTION_TYPE = [
  { value: 'RECHARGE', label: '+ Purchase (Recharge)' },
  { value: 'CREDIT_REFUND', label: '- Refund to Customer' },
  { value: 'BRIDGE_CREDITS', label: '+ Bridge credits' },
  { value: 'CREDIT_DEDUCTION_BRIDGE_CREDITS', label: '- Bridge Credits Deduction' },
  { value: 'FREE_CREDITS', label: '+ Free Credits' },
  { value: 'FREE_CREDITS_TRIAL', label: '+ Free credits (Trial)' },
  { value: 'CREDIT_DEDUCTION', label: '- Credit Adjustment' }
];

export const RECHARGE = 'RECHARGE';
export const CREDIT_REFUND = 'CREDIT_REFUND';
export const FREE_CREDITS = 'FREE_CREDITS';
export const CREDIT_DEDUCTION = 'CREDIT_DEDUCTION';
export const BRIDGE_CREDITS = 'BRIDGE_CREDITS';
export const FREE_CREDITS_TRIAL = 'FREE_CREDITS_TRIAL';
export const CREDIT_DEDUCTION_BRIDGE_CREDITS = 'CREDIT_DEDUCTION_BRIDGE_CREDITS';
export const USER_ID_TO_EXEMPT_FROM_AUTO_LOGOUT = [348, 777]; // for testing purposes only

// company TDS options

export const COMPANY_TDS = [
  { value: 'NONE', label: 'None' },
  { value: '2', label: '2%' },
  { value: '10', label: '10%' },
  { value: 'BOTH', label: 'Both' }
];

export const USER_TYPE = {
  1: 'SA',
  2: 'CA',
  3: 'Candidate',
  webhook: 'Webhook',
  4: 'System'
};

export const ALLOWED_USER_NAMES = {
  'mandrill-events': 'mandrill-events',
  'ses-events': 'ses-events',
  EXOTEL_INCOMING_CALL_WEBHOOK: 'EXOTEL_INCOMING_CALL_WEBHOOK'
};

export const ACCESS_LEVEL = {
  5: 'Admin',
  10: 'HR'
};
export const TAX_TYPES = {
  business_sez: 'SEZ',
  business_gst: 'GST',
  business_none: 'BUSINESS NONE',
  overseas: 'OVERSEAS'
};

export const STATE_CODES = {
  AN: 'Andaman and Nicobar Islands',
  AD: 'Andhra Pradesh',
  AR: 'Arunachal Pradesh',
  AS: 'Assam',
  BR: 'Bihar',
  CH: 'Chandigarh',
  CG: 'Chhattisgarh',
  DN: 'Dadra and Nagar Haveli and Daman and Diu',
  DD: 'Daman and Diu',
  DL: 'Delhi',
  GA: 'Goa',
  GJ: 'Gujarat',
  HR: 'Haryana',
  HP: 'Himachal Pradesh',
  JK: 'Jammu and Kashmir',
  JH: 'Jharkhand',
  KA: 'Karnataka',
  KL: 'Kerala',
  LA: 'Ladakh',
  LD: 'Lakshadweep',
  MP: 'Madhya Pradesh',
  MH: 'Maharashtra',
  MN: 'Manipur',
  ML: 'Meghalaya',
  MZ: 'Mizoram',
  NL: 'Nagaland',
  OD: 'Odisha',
  OT: 'Other Territory',
  PY: 'Puducherry',
  PB: 'Punjab',
  RJ: 'Rajasthan',
  SK: 'Sikkim',
  TN: 'Tamil Nadu',
  TS: 'Telangana',
  TR: 'Tripura',
  UP: 'Uttar Pradesh',
  UK: 'Uttarakhand',
  WB: 'West Bengal'
};

export const ZOHO_BOOK_DIALOG_TITLE = 'Connect with Zoho Books';
export const CLIENT_NAME_DISCLOSE_TITLE = 'Disclose Client Name for Work Experience';

export const DRUG_NAMES = {
  amphetamines: 'Amphetamine',
  barbiturates: 'Barbiturates',
  benzodiazepines: 'Benzodiazepine',
  cannabis: 'Cannabinoids',
  cocaine: 'Cocaine',
  methaqualone: 'Methaqualone',
  morphine: 'Opioids',
  pcp: 'Phencyclidine Phosphate',
  methadone: 'Methadone',
  propoxyphene: 'Propoxyphene'
};

export const CHECK_TYPE_SHORT_VERSION = {
  ID: 'ID',
  ADDRESS: 'ADD',
  COURT: 'CRT',
  EMPLOYMENT: 'EMP',
  DRUG: 'DRG',
  EDUCATION: 'EDU',
  REFERENCE: 'REF',
  WORLD: 'WCK',
  CIBIL: 'Credit Check',
  CUSTOM: 'CUSTOM'
};
export const RECHARGE_LOGS_EVENT = [
  { label: '+ Purchase (Recharge)', value: 'RECHARGE' },
  { label: '- Refund to Customer', value: 'CREDIT_REFUND' },
  { label: '+ Free Credits', value: 'FREE_CREDITS' },
  { label: '+ Free Credits (Trial)', value: 'FREE_CREDITS_TRIAL' },
  { label: '+ Bridge Credits', value: 'BRIDGE_CREDITS' },
  { label: '- Bridge Credits Deduction', value: 'CREDIT_DEDUCTION_BRIDGE_CREDITS' },
  { label: '- Credit Adjustment', value: 'CREDIT_DEDUCTION' }
];

export const ADC_TYPE_USAGE_LOG = {
  PASS_THROUGH: 'Passthrough',
  INTERNATIONAL: 'International',
  RE_INITIATION: 'Re-initiation',
  OTHER: 'Other'
};
export const PAYMENT_MODE_OBJ = {
  CASH: 'Cash',
  CREDIT_CARD: 'Credit / Debit card',
  DEMAND_DRAFT: 'Demand Draft',
  CHEQUE: 'Cheque',
  NEFT_OR_UPI: 'Bank Transfer',
  PAYMENT_GATEWAY: 'Payment Gateway'
};
export const transactionRevampDate = moment('2022-12-26T00:00:00.000Z');

export const INIT_STATE = 'INIT_STATE';

export const COMPLETED_DATE_FORMAT = 'YYYY-MM-DD';

export const PURCHASE_STATUS = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'Expired', value: 'EXPIRED' }
];
export const IDENTITY_TITLE = {
  Pan: 'PAN',
  PAN: 'PAN',
  'Driving License': 'DRIVING LICENSE',
  'DRIVING LICENCE': 'DRIVING LICENSE',
  'Voter Id': 'VOTER ID',
  'VOTER ID': 'VOTER ID',
  Aadhaar: 'AADHAAR',
  AADHAAR: 'AADHAAR',
  PASSPORT: 'PASSPORT',
  Passport: 'PASSPORT'
};
//Whitelisted domain for Admin
export const allowedAdminDomain = ['springworks.in', 'springverify.com'];

export const addressVerificationMediumDropdown = [
  {
    label: 'Digital',
    value: 'DIGITAL'
  },
  {
    label: 'Physical',
    value: 'PHYSICAL'
  },
  {
    label: 'Postal',
    value: 'POSTAL'
  }
];
export const courtVerificationMediumDropdown = [
  {
    label: 'Standard',
    value: 'STANDARD'
  },
  {
    label: 'Standard (Letterhead)',
    value: 'STANDARD_LAWFIRM'
  }
];
export const educationVerificationMediumDropdown = [
  { value: 'OFFICIAL', label: 'Official' },
  { value: 'REGIONAL_PARTNER', label: 'Regional Partner' },
  { value: 'HYBRID', label: 'Hybrid' }
];
export const addressVerificationMediumObject = {
  DIGITAL: 'Digital',
  PHYSICAL: 'Physical',
  POSTAL: 'Postal'
};
export const courtVerificationMediumObject = {
  STANDARD: 'Standard',
  STANDARD_LAWFIRM: 'Standard (Letterhead)'
};

export const educationVerificationMediumObject = {
  OFFICIAL: 'Official',
  REGIONAL_PARTNER: 'Regional Partner',
  HYBRID: 'Hybrid'
};

export const checksVerificationMediumObject = {
  ...addressVerificationMediumObject,
  ...courtVerificationMediumObject,
  ...educationVerificationMediumObject,
  'STANDARD (LETTERHEAD)': 'Standard (Letterhead)',
  'REGIONAL PARTNER': 'Regional Partner'
};

export const CONVERSION_TYPE = {
  FREE: 'Convert for Free',
  COST: 'Convert at Cost',
  CUSTOM_COST: 'Convert at Custom Cost'
};
export const ADDRESS_TYPE = {
  permanent_address: 'Permanent Address',
  current_address: 'Current Address',
  other_address: 'Other Address'
};
export const ADDON_CONFIG_MAPPING = {
  PHYSICAL: 'addressPhysical',
  POSTAL: 'addressPostal',
  DIGITAL: 'addressDigital',
  STANDARD: 'courtStandard',
  STANDARD_LAWFIRM: 'courtStandardLawfirm',
  OFFICIAL: 'education',
  REGIONAL_PARTNER: 'educationThirdParty',
  HYBRID: 'educationHybrid'
};
export const ADDON_CONFIG_MAPPING_PRICE = {
  PHYSICAL: 'ADDRESS_PHYSICAL',
  POSTAL: 'ADDRESS_POSTAL',
  DIGITAL: 'ADDRESS_DIGITAL',
  STANDARD: 'COURT_STANDARD',
  STANDARD_LAWFIRM: 'COURT_STANDARD_LAWFIRM'
};
export const EDUCATION_TYPE = {
  CHRONOLOGICAL: 'Chronological',
  SPECIFIC: 'Specific',
  NO_EDUCATIONID: 'None'
};
export const CHECK_WITH_VERIFICATION_TYPES = ['address', 'court', 'education'];
export const COMPANY_ADMINS_LIMIT = 50;
export const CONVERT_STATE = [0, 4, 5, -1];
export const UPLOAD_FILE_TYPES = {
  RESUME: 'CANDIDATE_RESUME',
  CHECK_DOCUMENT: 'CHECK_DOCUMENT',
  CONSENT: 'CANDIDATE_CONSENT',
  BULK_UPLOAD: 'CANDIDATE_BULK_UPLOAD',
  COMPANY_UNDERTAKING_LETTER: 'COMPANY_UNDERTAKING_LETTER',
  COMPANY_AGREEMENT: 'COMPANY_AGREEMENT',
  INVOICE: 'COMPANY_INVOICE',
  LOGO: 'COMPANY_LOGO',
  SA_CHECK_DOCUMENT: 'SA_CHECK_DOCUMENT'
};

export const SUPPORTED_MIME_TYPE = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv'
};

export const DATE_REGX = {
  year: /^[0-9]{4}$/,
  yearMonth: /^[0-9]{4}-[0-9]{2}$/,
  yearMonthDay: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
  shortMonthAndYear: /^Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec [0-9]{4}$/i,
  fullMonthAndYear:
    /^January|February|March|April|May|June|July|August|September|October|November|December [0-9]{4}$/i
};

export const SAMPLE_FILE_ALUMNI =
  'https://docs.google.com/spreadsheets/d/10P9OPT7njhWcL6U-WMcK4Iid-UCJ_ctl4M0npzgZUJw/edit?usp=sharing';
export const UPSELL_CHECK_LABELS_FOR_REPORT = {
  CREDIT: `Credit Check`,
  EDUCATION: `EDUCATION CHECK (REGIONAL/HYBRID)`,
  REFERENCE: `REFERENCE VERIFICATION`,
  RESUME: `RESUME CHECK`,
  SOCIAL: `SOCIAL MEDIA`,
  POSTAL: `POSTAL ADDRESS VERIFICATION`,
  WORLD: `WORLD CHECK`,
  UAN: `UAN`,
  EMP_GAP: `EMPLOYMENT GAP`,
  DRUG: `DRUG TEST`
};

export const UPSELL_CHECKS_INFO_REPORT = {
  CREDIT: `Get informed about addresses associated with candidates in addition to their primary residency.`,
  EDUCATION: `Make informed hiring decisions by confirming your candidate's education details with this highest quality of Education check.`,
  REFERENCE: `Understand your candidates better from the perspective of their ex-managers/peers.`,
  RESUME: `Evaluate if resume details match the actual details of your candidates.`,
  SOCIAL: `Screen their online behaviour to ensure it aligns to your company's values.`,
  POSTAL: `An additional layer of address verification in case for any discrepancy with current mode of verification.`,
  WORLD: `Stay aware of potential threats by Identifying if a candidate is charged with legal or criminal lawsuits.`,
  UAN: `Fetch the service history instantly and get informed of employee moonlighting.`,
  EMP_GAP: `Get the right reasons for gaps in work experiences to ensure it's not a cause of concern for you.`,
  DRUG: `Help your company stay compliant and meet work policy requirements by screening pre/post hires.`
};

export const HEADER_SEARCH_PARAMETERS = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'phone' },
  { label: 'SVID', value: 'svid' },
  { label: 'EMP ID', value: 'employee_id' }
];

export const LOGS_FILTER_CATEGORY = {
  CHECK: 'CHECK',
  CANDIDATE: 'Candidate'
};
export const LOGS_CHECKTYPE = {
  ID: 'ID',
  ADDRESS: 'ADD',
  EMPLOYMENT: 'EMP',
  EDUCATION: 'EDU',
  COURT: 'CRT',
  WORLD: 'WORLD',
  DRUG: 'DRUG',
  CUSTOM: 'Custom',
  CIBIL: 'CIBIL',
  REF: 'REF',
  General: 'GENERAL'
};

export const CANDIDATE_SECTION = [
  'OVERALL_STATUS',
  'BASIC_INFO',
  'CONSENT',
  'CONSENT_EMAIL',
  'BGV_EMAIL',
  'EXISTING_CANDIDATE',
  'CS_NOTES',
  'OPS_NOTES'
];
export const CHECK_SECTION = [
  'DP',
  'DF',
  'STATUS',
  'ADD_TO_REPORT',
  'QC',
  'VERIFICATION_TYPE',
  'VP',
  'ECOM',
  'SV_POSTAL'
];
export const EMP_REASONS_FOR_LEAVING = [
  {
    label: 'None',
    value: 'NONE'
  },
  {
    label: 'Resignation',
    value: 'RESIGNATION'
  },
  {
    label: 'Better Opportunity',
    value: 'BETTER_OPPORTUNITY'
  },
  {
    label: 'Career Growth',
    value: 'CAREER_GROWTH'
  },
  {
    label: 'Personal Reasons',
    value: 'PERSONAL_REASONS'
  },
  {
    label: 'Pursuing Higher Education',
    value: 'PURSUING_HIGHER_EDUCATION'
  },
  {
    label: 'Company Downsizing/Restructuring',
    value: 'COMPANY_DOWNSIZING/RESTRUCTURING'
  },
  {
    label: 'Termination',
    value: 'TERMINATION'
  },
  {
    label: 'Other',
    value: 'OTHER'
  }
];

export const CUSTOM_CHECK_NAMES = [
  { value: 'ADVERSE_MEDIA_CHECK', label: 'Adverse Media Check' },
  { value: 'CORPORATE_AFFILIATION_CHECK', label: 'Corporate Affiliation Check' },
  { value: 'OIG_EXCLUSIONS', label: 'OIG Exclusions' },
  { value: 'POLITICAL_AFFILIATION_CHECK', label: 'Political Affiliation Check' },
  { value: 'RESUME_REVIEW', label: 'Resume Review' },
  { value: 'SOCIAL_MEDIA_CHECK', label: 'Social Media Check' },
  { value: 'OVERLAP_CHECK', label: 'Overlap Check' },
  { value: 'FACIS_CHECK', label: 'FACIS Check' },
  { value: 'GAP_REVIEW', label: 'Gap Review' },
  { value: 'FORM_26AS', label: 'Form 26AS' },
  { value: 'ECONOMIC_DEFAULT_CHECK', label: 'Economic Default Check' },
  { value: 'DIRECTORSHIP_CHECK', label: 'Directorship Check' },
  { value: 'MEDICAL_TEST_PACKAGE_A', label: 'Medical Test Package A' },
  { value: 'MEDICAL_TEST_PACKAGE_B', label: 'Medical Test Package B' },
  { value: 'MEDICAL_TEST_PACKAGE_C', label: 'Medical Test Package C' },
  { value: 'MEDICAL_TEST_PACKAGE_D', label: 'Medical Test Package D' },
  { value: 'MEDICAL_TEST_PACKAGE_E', label: 'Medical Test Package E' },
  { value: 'MEDICAL_TEST_PACKAGE_F', label: 'Medical Test Package F' },
  { value: 'MEDICAL_TEST_CHEST_XRAY', label: 'Medical Test Chest X Ray' },
  { value: 'MEDICAL_TEST_ULTRASOUND_ABDOMEN', label: 'Medical Test Ultrasound of Abdomen' },
  {
    value: 'MEDICAL_TEST_PULMONARY_FUNCTION_TEST',
    label: 'Medical Test Pulmonary Function Test (PFT)'
  },
  { value: 'MEDICAL_TEST_ELECTROCARDIOGRAM', label: 'Medical Test Electrocardiogram (ECG)' },
  { value: 'OTHER', label: 'OTHER' }
];
