import axios from 'axios';
import http from './http';
import { toSnakeCase } from '../utils/Utlities';
import AppConstants from '../core-components/AppConstants';
import { APICONSTANTS } from '../core-components/CommonConstants';
import AppHelper from '../core-components/AppHelper';

const googleEnvironmentConstant = require('../config/googleauth.json');

const constructQueryString = (args) => {
  let url = null;
  Object.keys(args).forEach((key) => {
    if (args[key] === 0 || args[key] === false || args[key]) {
      if (!url) {
        url = `${key}=${args[key]}`;
      } else {
        url = `${url}&${key}=${args[key]}`;
      }
    }
  });
  return url;
};

export const getCandidates = (
  limit,
  offset,
  includeVerifications,
  name,
  email,
  companyId,
  status,
  category,
  id
) => {
  return http.get('company/candidates', {
    params: {
      limit,
      offset,
      includeVerifications,
      name: name || null,
      email: email || null,
      companyId,
      status,
      category,
      candidate_id: id
    }
  });
};

export const getCompanyServices = (companyId) => {
  return http.get(`company/companyservicemapping?company_id=${companyId}&status=active`);
};

export const getViewCandidate = (candidateId = null, companyId = null) => {
  return http.get(`candidate?candidate_id=${candidateId}&company_id=${companyId}`);
};

export const getCompanies = (limit, offset, status, search, company_type, trial_account) => {
  return http.get('company/companies', {
    params: {
      limit,
      offset,
      status,
      search,
      company_type,
      is_trial_account: trial_account
    }
  });
};

export const getCompanyById = (companyId = null) => {
  return http.get(`company/company?companyId=${companyId}`);
};

export const createCandidate = (data) => {
  return http.post('candidate/profile', toSnakeCase(data));
};

export const createCandidateBulk = (data) => {
  return http.post('candidate/profile/bulk-candidates', toSnakeCase(data));
};

export const deleteCandidate = (candidateId, companyId) => {
  return http.delete(`candidate/profile?candidate_id=${candidateId}&company_id=${companyId}`);
};

export const globalSearch = (data) => {
  return http.post('search/companycandidate', data);
};

export const editCandidate = (data) => {
  let copyData = { ...data };
  copyData.resume = copyData.resume || null;
  copyData.resumeLink = typeof copyData.resumeLink === 'string' ? copyData.resumeLink : null;

  return http({
    method: 'put',
    url: 'candidate/profile',
    data: toSnakeCase(copyData),
    headers: { 'Content-Type': 'application/json' }
  });
};

export const candidateExistanceCheck = (data) => {
  return http.post('candidate/profile/existence', toSnakeCase(data));
};

export const getInsufficiency = (
  limit,
  offset,
  company_id,
  status,
  candidate_id,
  candidate_name,
  company_name,
  check_type,
  order_according_to_days
) => {
  return http.get('company/insufficiency', {
    params: {
      limit,
      offset,
      company_id,
      status,
      candidate_id,
      candidate_name,
      company_name,
      check_type,
      order_according_to_days
    }
  });
};

export const getBGVLink = (data) => {
  return http.post('/candidate/profile/bgv-link', toSnakeCase(data));
};

export const updateCandidateStatus = (data) => {
  return http.patch('/candidate/profile/status', toSnakeCase(data));
};

export const exportCompanyData = (data) => {
  return http.post('/company/analyticsexcel', data);
};

// update company info
export const updateCompany = (data) => {
  return http.put('/company/company', data);
};

export const getCompanyBgvConfig = (companyId) => {
  return http.get('company/bgvconfiguration', {
    params: {
      company_id: companyId
    }
  });
};

export const updateBgvConfig = (data) => {
  return http.put('company/bgvconfiguration', data);
};

export const getRefQuestions = (company_uuid, limit, offset, status) => {
  return http.get('company/questions', {
    params: {
      company_uuid,
      limit,
      offset,
      status
    }
  });
};

export const deleteQuestion = (questionUuid, companyUuid, userUuid = '12345678') => {
  return http.delete(
    `company/questions?question_id=${questionUuid}&user_uuid=${userUuid}&company_uuid=${companyUuid}`
  );
};
export const addQuestion = (data) => {
  return http.post('company/questions', toSnakeCase(data));
};

//fetch company categories
export const getCompanyCategories = (companyId, limit, offset, name) => {
  return http.get('company/categories', {
    params: {
      company_id: companyId,
      limit,
      offset,
      category_name: name || null
    }
  });
};

export const getCompanyLogs = (company_uuid, limit, offset, table_name) => {
  return http.get('company/logs', {
    params: {
      company_uuid,
      limit,
      offset,
      table_name
    }
  });
};

export const getServices = (limit, offset, order_id, id, price, name, status) => {
  return http.get('admin/services', {
    params: {
      limit,
      offset,
      order_id,
      id,
      price,
      name,
      status
    }
  });
};

// Categories API
export const createCategory = (data) => {
  return http.post('company/category', data);
};

export const editCategory = (data) => {
  return http.post('company/category', data);
};

export const deleteCategories = (companyId = null, categoryId = null) => {
  return http.delete(`company/category?category_id=${categoryId}`);
};

//this is used to create and edit service
export const updateServices = (data) => {
  return http.post('admin/service', data);
};

export const deleteServices = (id) => {
  return http.delete(`/admin/service?serviceId=${id}`);
};

//upload compnay logo to s3
export const companyLogoUpload = (data) => {
  return http({
    method: 'post',
    url: 'company/logo',
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const importDefaultQuestions = (data) => {
  return http.put('admin/defaultquestions', toSnakeCase(data));
};

export const getCompanyAdmin = (company_id, limit, offset) => {
  return http.get('/company/admins', {
    params: {
      company_id,
      limit,
      offset
    }
  });
};

export const createCompanyAdmin = (data) => {
  return http.post('company/admin', data);
};

export const updateCompanyAdmin = (data) => {
  let value = toSnakeCase(data);
  value.user_category_ids = data.userCategoryIds;
  return http.put('company/admin', value);
};

export const deleteCompanyAdmin = (companyId, adminId) => {
  return http.delete(`company/admin?company_id=${companyId}&admin_id=${adminId}`);
};

export const getCompanyAssignedServices = (company_id) => {
  return http.get('company/companyservicemapping', {
    params: {
      company_id
    }
  });
};

export const saveCompanyAssignedServices = (data) => {
  return http.post('company/assignedservices', toSnakeCase(data));
};

export const getCompanySettings = (company_id) => {
  return http.get('company/settings', {
    params: {
      company_id
    }
  });
};

export const deleteAgreementDoc = (companyId, agreementUuid, docUuid) => {
  return http.delete(
    `company/agreementdocumentfile?company_id=${companyId}&agreement_uuid=${agreementUuid}&agreement_document_uuid=${docUuid}`
  );
};

//upload agreement docs
export const uploadAgreementDocs = (data) => {
  let formData = new FormData();
  if (data.companyId) {
    formData.append('company_id', data.companyId);
  }
  if (data.fileType) {
    formData.append('file_type', data.fileType);
  }
  formData.append('agreement_document_file', data.agreementDocumentFile);
  return http({
    method: 'post',
    url: 'company/agreementdocumentfile',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const editCompanySettings = (data) => {
  return http.put('/company/settings', toSnakeCase(data));
};

//ops status Apis
export const getOpsStatus = (limit, offset, name, status) => {
  return http.get('/admin/opsstatuses', {
    params: {
      limit,
      offset,
      name,
      status
    }
  });
};

export const opsStatusAction = (data) => {
  return http.post('admin/opsstatus', toSnakeCase(data));
};

export const getVendors = (limit = 10, offset = 0, status = null) => {
  return http.get('admin/vendors', {
    params: {
      limit,
      offset,
      status
    }
  });
};

export const addVendor = (data) => {
  return http.post('admin/vendor', toSnakeCase(data));
};

export const getDefaultQuestions = (limit = 10, offset = 0, status = null) => {
  return http.get('admin/defaultquestions', {
    params: {
      limit,
      offset,
      status
    }
  });
};

export const addDefaultQuestion = (data) => {
  return http.post('admin/defaultquestion', toSnakeCase(data));
};

export const deleteDefaultQuestion = (questionUuid) => {
  return http.delete(`admin/defaultquestion?question_id=${questionUuid}`);
};

//get company bgv config to render in create company page
export const getCompanyConfigCreateCompany = () => {
  return http.get('company/config');
};

//creating company
export const createCompany = (data) => {
  return http.post('company', data);
};

//password update
export const updatePassword = (data) => {
  return http.put('auth/password', data);
};

//get global admins
export const getAdmins = (
  limit = 10,
  offset = 0,
  include_company_admins,
  name,
  email,
  access_level,
  status
) => {
  return http.get('admin/admins', {
    params: {
      limit,
      offset,
      include_company_admins,
      name,
      email,
      access_level,
      status
    }
  });
};

export const createAdmin = (data) => {
  return http.post('admin', toSnakeCase(data));
};

export const updateAdmin = (data) => {
  return http.put('admin', toSnakeCase(data));
};

export const deleteAdmin = (adminId) => {
  return http.delete(`admin?admin_id=${adminId}`);
};

export const getViewCandidateDetails = (companyId, candidateId) => {
  return http.get(`candidate/profile/view?company_id=${companyId}&candidate_id=${candidateId}`);
};

export const getADCList = (checkId, checkType, limit, offset) => {
  return http.get('/candidate/additional-charges/', {
    params: {
      limit,
      offset,
      check_type: checkType,
      check_id: checkId
    }
  });
};

export const getADCConfig = (companyId) => {
  return http.get(`/company/additional-charge-settings/company-settings?company_id=${companyId}`);
};

export const setADCConfig = (data) => {
  return http.put(`/company/additional-charge-settings/company-settings`, data);
};

export const getAdminsCA = (companyId) => {
  return http.get(`/candidate/additional-charges/approval-ca-list?company_id=${companyId}`);
};
export const getCompaniesList = (companyId) => {
  return http.get(`/candidate/additional-charges/company-list`);
};

export const getWarnings = (company_uuid, candidate_uuid) => {
  return http.get('candidate/profile/warnings', {
    params: {
      candidate_uuid,
      company_uuid
    }
  });
};

export const setWarnings = (data) => {
  return http.put('candidate/profile/warning', toSnakeCase(data));
};

//Data found identity
export const getIdentityDataFound = (id, candidateId) => {
  return http.get(`/candidate/identity/data-found?id=${id}&candidate_id=${candidateId}`);
};

//Data provided identity
export const getIdentityDataProvided = (id) => {
  return http.get(`/candidate/identity/data-provided?id=${id}`);
};

//GET Data Provided Education
export const getEducationDataProvided = (id) => {
  return http.get(`/candidate/education/data-provided?id=${id}`);
};

//GET Data Found Education
export const getEducationDataFound = (id) => {
  return http.get(`/candidate/education/data-found?id=${id}`);
};

//POST Data provided API Education
export const updateEducationDataProvided = (data) => {
  return http.post('/candidate/education/data-provided', toSnakeCase(data));
};

//Data provided post API Idenity
export const updateIdentityDataProvided = (data) => {
  return http.post('/candidate/identity/data-provided', toSnakeCase(data));
};

//Data found post API Identity
export const updateIdentityDataFound = (data) => {
  return http.post('/candidate/identity/data-found', toSnakeCase(data));
};

// Send consent link
export const sendConsentLink = (data) => {
  return http.post('/candidate/profile/consent-mail', toSnakeCase(data));
};
export const deleteConsentLink = (candidateId, companyId) => {
  return http.delete(
    `/candidate/profile/consent?candidate_id=${candidateId}&company_id=${companyId}`
  );
};

//POST Data Found API Education
export const updateEducationDataFound = (data) => {
  return http.put('/candidate/education/data-found', toSnakeCase(data));
};

//Data provided Employment
export const getEmploymentDataProvided = (id) => {
  return http.get(`/candidate/employment/data-provided?id=${id}`);
}; // end of func :getEmploymentDataProvided

//Data Found Employment
export const getEmploymentDataFound = (id) => {
  return http.get(`/candidate/employment/data-found?id=${id}`);
}; // end of func :getEmploymentDataFound

//Data provided post API Employment
export const updateEmploymentDataProvided = (data) => {
  return http.post('/candidate/employment/data-provided', data);
}; // end of func :updateEmploymentDataProvided

//Data found post API Employment
export const updateEmploymentDataFound = (data) => {
  return http.put('/candidate/employment/data-found', toSnakeCase(data));
}; // end of func :updateEmploymentDataFound

//Drugs Data Provided get api
export const getDrugDataProvided = (id, candidateId) => {
  //here remove hardcoded data later
  return http.get(`/candidate/drug/data-provided?id=${id}&candidate_id=${candidateId}`);
}; //end of func: getDrugsDataProvided

//Data provided post API Drug
export const updateDrugDataProvided = (data) => {
  return http.put('/candidate/drug/data-provided', toSnakeCase(data));
}; // end of func :updateDrugDataProvided

//Drugs data found get API
export const getDrugDataFound = (id, candidateId) => {
  //here remove hardcoded data later
  return http.get(`/candidate/drug/data-found?id=${id}&candidate_id=${candidateId}`);
}; //end of func: getDrugsDataFound

//Drugs data found post API
export const updateDrugDataFound = (data) => {
  return http.put('/candidate/drug/data-found', data);
}; //end of func: updateDrugsDataFound

//World get Data => for data found send is_verified as 1 and DP as 0
export const getWorldData = (id, isVerified) => {
  //here remove hardcoded data later
  return http.get(`/candidate/world/data?id=${id}&is_verified=${isVerified}`);
}; //end of func: getWorldData

//World Data provided post api
export const updateWorldDataProvided = (data) => {
  return http.post('/candidate/world/data-provided', toSnakeCase(data));
}; //end of func: Data Provided world

//World datafound api
export const updateWorldDataFound = (data) => {
  return http.post('/candidate/world/data-found', toSnakeCase(data));
}; //end of func: Data Found World

//Identity RUN CHECK API
export const getIdentityRunCheck = (data) => {
  return http.post('/candidate/identity/run-check', toSnakeCase(data));
}; //end of func: RUN CHECK

//Data provided Cibil
export const getCibilDataProvided = (id) => {
  return http.get(`/candidate/cibil/data-provided?id=${id}`);
}; // end of func :getCibilDataProvided

//Data Found Cibil
export const getCibilDataFound = (id) => {
  return http.get(`/candidate/cibil/data-found?id=${id}`);
}; // end of func :getCibilDataFound

//Data provided post API Cibil
export const updateCibilDataProvided = (data) => {
  return http.post('/candidate/cibil/data-provided', toSnakeCase(data));
}; // end of func :updateCibilDataProvided

//Data found post API Cibil
export const updateCibilDataFound = (data) => {
  return http.put('/candidate/cibil/data-found', toSnakeCase(data));
}; // end of func :updateCibilDataFound

//GET Data Provided Reference Check
export const getReferenceDataProvided = (id) => {
  return http.get(`/candidate/reference/data-provided?id=${id}`);
};

//GET Data Found Reference Check
export const getReferenceDataFound = (id) => {
  return http.get(`/candidate/reference/data-found?id=${id}`);
};

//Data provided get api
export const getAddressDataProvided = (id) => {
  return http.get(`/candidate/address/data-provided?id=${id}`);
}; // end of func :getAddressDataProvided

//Data provided post api Address
export const updateAddressDataProvided = (data) => {
  return http.post('/candidate/address/data-provided', toSnakeCase(data));
}; //en of func : updateAddressDataProvided

//Address SV postal initate call
export const initiateSVPostal = (data) => {
  return http.post('/candidate/address/initiate-sv-postal', toSnakeCase(data));
}; //en of func : SV postal initiate call

//Data Provided PUT API reference
export const updateReferenceDataProvided = (data) => {
  return http.put('/candidate/reference/data-provided', data);
};

//Data Found PUT API reference
export const updateReferenceDataFound = (data) => {
  return http.put('/candidate/reference/data-found', toSnakeCase(data));
};

//GET Data Provided Court
export const getCourtDataProvided = (id) => {
  return http.get(`/candidate/court/data-provided?id=${id}`);
};

//DP POST API Court
export const updateCourtDataProvided = (data) => {
  return http.post('/candidate/court/data-provided', toSnakeCase(data));
};

//GET Data Found Reference Check
export const getCourtDataFound = (id) => {
  return http.get(`/candidate/court/data-found?id=${id}`);
};

//Data Found PUT API reference
export const updateCourtDataFound = (data) => {
  return http.post('/candidate/court/data-found', toSnakeCase(data));
};

//Address Datafound get api
export const getAddressDataFound = (id) => {
  return http.get(`/candidate/address/data-found?id=${id}`);
}; // end of func :getAddressDataFound

//Notes Get API
export const getNotes = (request) => {
  let queryString = '';
  if (request.checkUuid) {
    queryString = `candidate_id=${request.candidateId}&company_id=${request.companyId}&check_uuid=${request.checkUuid}`;
  } else {
    queryString = `candidate_id=${request.candidateId}&company_id=${request.companyId}`;
  }
  return http.get(`/candidate/common/notes?${queryString}`);
};

// Add, Update notes
export const addNote = (request) => {
  return http.post('/candidate/common/note', toSnakeCase(request));
};

// Delete Note
export const deleteNote = (id) => {
  return http.delete(`/candidate/common/note?id=${id}`);
};

//Address Datafound post api
export const updateAddressDataFound = (data) => {
  return http.post('/candidate/address/data-found', toSnakeCase(data));
}; //end of func : update address data found

//EMP Swap api
export const swapEmp = (data) => {
  return http.post('/candidate/employment/swap', data);
}; //end of func : swapEmp

//Add check to report
export const addToReport = (data) => {
  let formData = new FormData();
  formData.append('candidate_id', data.candidateId);
  formData.append('candidate_uuid', data.candidateUuid);
  formData.append('check_id', data.checkId);
  formData.append('check_type', data.checkType);

  return http({
    method: 'put',
    url: '/candidate/common/add-check-to-report',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}; //end of func : addToReport

//Remove check to report
export const removeFromReport = (data) => {
  let formData = new FormData();
  formData.append('candidate_id', data.candidateId);
  formData.append('candidate_uuid', data.candidateUuid);
  formData.append('check_id', data.checkId);
  formData.append('check_type', data.checkType);

  return http({
    method: 'delete',
    url: '/candidate/common/remove-check-from-report',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}; //end of func : removeFromReport

//Get ops-status for notes
export const getOpsStatusNotes = (id) => {
  return http.get(`/candidate/common/ops-status`);
}; // end of func :getOpsStatusNote

//Save ops-status for notes
export const saveOpsStatusNotes = (data) => {
  return http.post('/candidate/common/ops-status-log', toSnakeCase(data));
}; // end of func :saveOpsStatusNote

//Add Custom Services
export const addCustomServices = (data) => {
  return http.post('/candidate/common/custom-service', toSnakeCase(data));
}; // end of func : addCustomServices

//Get Employment list while adding custom verification
export const getEmploymentList = (candidateId) => {
  return http.get(`/candidate/employment/without-reference?candidate_id=${candidateId}`);
}; // end of func:getEmploymentList

//Get Excel for Emp
export const getEmpExcel = (data) => {
  return http.post(
    '/candidate/common/export-to-excel',
    toSnakeCase({ ...data, checkType: 'employment' })
  );
}; // end of func :getEmpExcel

//Get Excel for Emp
export const getCourtExcel = (data) => {
  return http.post(
    '/candidate/common/export-to-excel',
    toSnakeCase({ ...data, checkType: 'court' })
  );
}; // end of func:getCourtExcel

//Delete Indiviual check
export const deleteCheck = ({ checkType, checkUuid, checkId, candidateID, candidateUuid }) => {
  let url = `/candidate/common/delete-check?check_name=${checkType}&check_uuid=${checkUuid}&candidate_id=${candidateID}&candidate_uuid=${candidateUuid}`;
  if (checkId) {
    url = `${url}&check_id=${checkId}`;
  }
  return http.delete(url);
}; // end of func:deleteCheck

//Indiviual cand. logs
export const getCandidateLogs = (candidate_id, limit, offset, filter = {}) => {
  const { category, section, check_id, check_name, user_id, user_type, field_name } = filter;
  return http.get('/candidate/logs', {
    params: {
      candidate_id,
      limit,
      offset,
      category,
      section,
      check_id,
      check_name,
      user_id,
      user_type,
      field_name
    }
  });
}; // end of func:getCandidateLogs

export const getCandidateCallLogs = (candidateId, limit, offset, rights, callStatus, userId) => {
  const url = constructQueryString({
    candidate_id: candidateId,
    limit,
    offset,
    check_names: rights,
    call_status: callStatus,
    user_id: userId
  });
  if (url) {
    return http.get(`candidate/profile/telephony-logs?${url}`);
  }
}; // end of func:getCandidateCallLogs

export const getUserArrayForCallLogsFilter = (id) => {
  return http.get(`candidate/profile/telephony-users-list?candidate_id=${id}`);
}; // end of func:getUserArrayForCallLogsFilter

//QC Status check
export const qcCheck = (data) => {
  return http.post('candidate/common/mark-qc', toSnakeCase(data));
}; // end of func:qcCheck

//Get Vendors List
export const getVendorsList = (candidateId, checkUuid) => {
  return http.get(
    `candidate/common/vendor-list?candidate_id=${candidateId}&check_uuid=${checkUuid}`
  );
}; // end of func:getVendorsList

//Get Vendors Info
export const getVendorsInfo = (candidateId, checkUuid) => {
  return http.get(
    `candidate/common/vendor-information?candidate_id=${candidateId}&check_uuid=${checkUuid}`
  );
}; // end of func:getVendorsInfo

//Add Vendor
export const addVendorInfo = (data) => {
  return http.post('candidate/common/vendor-information', toSnakeCase(data));
}; // end of func:addVendor

//Update Vendor
export const updateVendorInfo = (data) => {
  return http.put('candidate/common/vendor-information', toSnakeCase(data));
}; // end of func:updateVendor

/**
 * Common File Upload API
 * @param {JSON} data
 */
export const fileUpload = (data) => {
  let formData = new FormData();
  formData.append('candidate_id', data.candidateId);
  if (data.checkId) {
    formData.append('check_id', data.checkId);
  }
  if (data.checkType) {
    formData.append('check_type', data.checkType);
  }
  if (data.fileType) {
    formData.append('file_type', data.fileType);
  }
  if (data.companyId) formData.append('company_id', data.companyId);

  formData.append('file', data.file);

  return http({
    method: 'post',
    url: '/candidate/common/upload-file',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}; //end of func : fileUpload

//Identity Ocr
export const getIdentityOcrCheck = (data) => {
  return http.post('/candidate/identity/ocr', toSnakeCase(data));
};

//Add Phone
export const setCandPhones = (data) => {
  return http.post('candidate/profile/phone-numbers', toSnakeCase(data));
}; // end of func:updateVendor

//Address : ECOM initiate
export const addressEcomInititate = (data) => {
  return http.post('/candidate/address/ecom-initiate', toSnakeCase(data));
}; // end of Ecom initiate

//Get Feedback
export const getFeedback = (limit, offset, company_id) => {
  return http.get('/candidate/profile/feedback', {
    params: {
      limit,
      offset,
      company_id
    }
  });
}; // end of getFeedback

//Address : ECOM reschedule
export const ecomReschedule = (data) => {
  return http.put('/candidate/address/ecom-reschedule', toSnakeCase(data));
}; // end of function ecom reschedule

//Address :  ECOM cancel AWB number
export const ecomDeleteAwbNumber = (checkId, awdNumber) => {
  return http.delete(`/candidate/address/ecom-cancel?id=${checkId}&awb_number=${awdNumber}`);
}; //end of function delete Ecom Awb Number

//Address : ECOM Track details (fetch api )
export const ecomFetchDetails = (checkId, awbNumber) => {
  return http.get(`/candidate/address/ecom-fetch?id=${checkId}&awb_number=${awbNumber}`);
}; //end of function track details

//S3 piping
export const documentPiping = ({ uuid, token }) => {
  return http.get('/auth/file', {
    params: {
      uuid,
      token
    }
  });
}; // end of Ecom initiate

// Login API
export const login = (data) => {
  return http.post('/auth/login', toSnakeCase(data));
}; //end of login API

// Logout API
export const logout = () => {
  return http.delete('/auth/logout');
}; //end of logout API

//Set Password , Invite Page
export const setPassword = (data) => {
  return http.post('/auth/invite', toSnakeCase(data));
}; // end of set password for new added admin

//Reports (Internal/External)
export const getReports = (data) => {
  return http.get(
    `/candidate/report?candidate_id=${data.candidateId}&is_internal=${data.isInternal}&company_id=${data.companyId}`
  );
}; //end of function getReports

//Reports (External)
export const getReportsExternal = (data) => {
  return http({
    method: 'get',
    url: `${AppConstants.developmentAPIURL}external/v1/candidate/report?candidate_id=${data.candidateId}`,
    headers: { Authorization: `Bearer: ${data.token}` }
  });
};

// PDF to image API conversion
export const pdfToImage = (data) => {
  return http.post('/candidate/report/pdf-to-image', toSnakeCase(data));
}; //end of pdfToImage API

export const loginViaPhp = (data) => {
  let formData = new FormData();
  formData.append('LoginForm[email]', data.email);
  formData.append('LoginForm[password]', data.password);
  formData.append('LoginForm[rememberMe]', 1);
  formData.append('jsonResponse', 1);

  return http({
    method: 'post',
    url: `${AppConstants.phpURL}site/login`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

// login to php using node JWT
export const loginViaJWT = (token) => {
  let formData = new FormData();
  formData.append('token', token);

  return http({
    method: 'post',
    url: `${AppConstants.phpURL}site/login-with-jwt`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}; //end of loginViaJWT API

//Add custom check
export const addCustomCheck = (data) => {
  return http.post('/candidate/custom-check', toSnakeCase(data));
}; // end of func : addCustomCheck

//POST Data Found API Education
export const updateCustomCheckDF = (data) => {
  return http.put('/candidate/custom-check/data-found', toSnakeCase(data));
};

//Custom check get DF
export const getCustomCheckDF = (uuid) => {
  return http.get(`/candidate/custom-check/data-found?uuid=${uuid}`);
}; //end of function getCustomCheckDF

//Insuff Ticket List
export const getInsuffTicketList = (data) => {
  let query = toSnakeCase(data);
  // delete query['status'];
  // delete query['only_private'];
  return http.get('/company/insufficiency-ticket-list', {
    params: {
      ...query
    }
  });
}; //end Insuff Ticket List

//Publish insuff comments (Review page)
export const publishComments = (data) => {
  return http.post('/candidate/common/publish-comments', toSnakeCase(data));
}; // end of func : publishComments

/**
 * Post call for adding new manual insuff
 * @param   {object} data
 * @return  {promise}
 */
export const setManualInsuffTkt = (data) => {
  return http.post('/candidate/common/manual-insufficiency', toSnakeCase(data));
}; //end of function setManualInsuffTkt

/**
 * Update Insuff tkt tittl/status
 * @param   {object} data
 * @return  {promise}
 */
export const updateManualInsuffTkt = (data) => {
  return http.put('/candidate/common/manual-insufficiency', toSnakeCase(data));
}; //end of function setManualInsuffTkt

//Get Insuff Review (http://localhost:3000/admin/insufficiency-review)
export const getInsuffReview = (data) => {
  let url = `limit=${data.limit}&offset=${data.offset}`;

  if (data.status !== 'NONE') {
    url = `${url}&status=${data.status}`;
  }
  if (data.candidateName) {
    url = `${url}&candidate_name=${data.candidateName}`;
  }
  if (data.onlyUnreviewedPublic) {
    url = `${url}&only_unreviewed_public=${data.onlyUnreviewedPublic}`;
  }
  if (data.onlyUnreviewedPrivate) {
    url = `${url}&only_unreviewed_private=${data.onlyUnreviewedPrivate}`;
  }
  if (data.onlyPrivate) {
    url = `${url}&only_private=${data.onlyPrivate}`;
  }

  return http.get(`/candidate/insufficiency-review?${url}`);
}; //end of function getInsuffReview

/**
 * Update Email Settings
 * @param   {object} data
 * @return  {promise}
 */
export const updateEmailSettings = (data) => {
  return http.post('/company/settings/email-reminder', data);
}; // end of func : updateEmailSettings

/**
 * Get Email Settings
 * @param   {object} data
 * @return  {promise}
 */
export const getCompanyEmailSettings = (data) => {
  return http.get(
    `/company/settings/email-reminder?company_id=${data.companyId}&admin_id=${data.adminId}`
  );
}; // end of func : getEmailSettings

/**
 * Get Insufficiency for candidate
 * @param   {object} uuid candidate uuid
 * @return  {promise}
 */
export const getInsufficiencyForCandidate = (uuid) => {
  return http.get(`/candidate/profile/manual-insufficiency?candidate_uuid=${uuid}`);
}; // end of func : getEmailSettings

/**
 * GET/SET/UPDATE/DELETE Insufficiency comments of candidate
 * @param   {object} uuid candidate uuid
 * @return  {promise}
 */
export const setManualInsuffComment = (data, action) => {
  return http({
    method: APICONSTANTS[action],
    url: '/candidate/common/manual-insufficiency-comment',
    data: toSnakeCase(data),
    headers: { 'Content-Type': 'application/json' }
  });
}; // end of func : setManualInsuffComment

/**
 * GET/SET/UPDATE Global Email Settings
 * @param   {object} data data API
 * @param   {string} action API action
 * @return  {promise}
 */
export const setGlobalEmailSettings = (data, action = 'SET') => {
  return http[APICONSTANTS[action]]('/company/settings/email-reminder', toSnakeCase(data));
}; // end of func: setGlobalEmailSettings

/**
 * SET Push cand. comments to review
 * @param   {object} data data for API
 * @param   {string} action API action
 * @return  {promise}
 */
export const pushCommentsToReview = (data, action = 'SET') => {
  return http[APICONSTANTS[action]](
    '/candidate/profile/push-manual-insufficiency-comments-to-review',
    toSnakeCase(data)
  );
}; // end of func: pushCommentsToReview

// Get particular package details
export const getPackageDetails = (packageId) => {
  return http.get(`/payments/packages/details?package_id=${packageId}`);
}; // end of func : getPackageDetails

// Get company list assigned to particular package
export const getPackageCompanyList = (data) => {
  return http.get(
    `payments/packages/assigned-company-details?package_id=${data.packageId}&limit=${data.limit}&offset=${data.offset}'`
  );
}; // end of func : getPackageCompanyList

/**
 * Get Package base config for create package form
 * @return  {promise}
 */
export const getPackageBaseConfig = (packageId) => {
  return http.get(`/candidate/profile/manual-insufficiency?package_id=${packageId}`);
}; // end of func : getPackageDetails

// Get company list assigned to particular package
export const getPackageDetailsAuditLogs = (data) => {
  return http.get(
    `/payments/packages/logs?limit=${data.limit}&offset=${data.offset}&package_id=${data.packageId}`
  );
}; // end of func : getPackageDetailsAuditLogs

//Get all Packages list
export const getPackageList = (
  limit,
  offset,
  package_name,
  company,
  status,
  package_type,
  company_id
) => {
  const where = {
    limit,
    offset
  };
  if (package_name) {
    where['package_name'] = package_name;
  }

  if (company) {
    where['company_name'] = company;
  }

  if (status === 0 || status === 1) {
    where['status'] = status;
  }

  if (package_type) {
    where['package_type'] = package_type;
  }
  if (company_id) {
    where['company_id'] = company_id;
  }

  return http.get('payments/packages/list', {
    params: {
      ...where
    }
  });
};

//Get Credit balance
export const getCreditBalance = (data) => {
  let query = toSnakeCase(data);
  return http.get('/payments/credits', { params: { ...query } });
};

//Pending Order API for request payment
export const getCompanyPendingOrders = (data) => {
  return http.get(`payments/purchase-order`, { params: { ...data } });
};
//Cancel Order API for request payment
export const cancelOrder = (data) => {
  return http.put(`payments/purchase-order/cancel-order`, toSnakeCase(data));
};

//Get Company Credit balance
export const getPostpaidCreditCompanies = (limit, offset, company_name) => {
  return http.get(
    `payments/credits/postpaid?limit=${limit}&offset=${offset}&company_name=${company_name}`
  );
};

//Get Company Credit balance
export const getPostpaidCompanyCreditView = (company_id) => {
  return http.get(`payments/credits/postpaid-balance?company_id=${company_id}`);
};

//Get Company Packages
export const getCompanyPackages = (company_id) => {
  return http.get('/payments/packages/company', {
    params: {
      company_id
    }
  });
};

// convert credits to company package balance
export const convertToPackageBalance = (data) => {
  return http.put('/payments/credits/convert-to-package', toSnakeCase(data));
};

// update company credits balance
export const updateCompanyCreditBalance = (data) => {
  return http.put(`/payments/credits/update-balance`, toSnakeCase(data));
};
// Update credit_refund
export const updateCreditRefundLog = (data) => {
  return http.put(`/payments/credits/transaction`, toSnakeCase(data));
};

// update company credits limit
export const updateCompanyCreditLimit = (data) => {
  return http.put('/payments/credits/credit-limit', toSnakeCase(data));
};

//Get Company Recharge Log for Credit balance
export const getCompanyRechargeLogs = (query) => {
  return http.get('/payments/credits/recharge-logs', {
    params: {
      ...toSnakeCase(query)
    }
  });
};

//Get Company Usage Log for Credit balance
export const getCompanyUsageLogs = (
  company_id,
  limit,
  offset,
  event,
  from_date,
  to_date,
  candidate_name,
  user_name,
  invoice_number,
  credit_type,
  user_id
) => {
  return http.get('/payments/credits/balance-logs', {
    params: {
      company_id,
      limit,
      offset,
      event,
      from_date,
      to_date,
      candidate_name,
      user_name,
      invoice_number,
      credit_type,
      user_id
    }
  });
};

//Get Overall Package / Credits Logs
export const getOverallPaymentLogs = (limit, offset, packageType) => {
  return http.get('/payments/logs/overall', {
    params: {
      limit: limit,
      offset: offset,
      type: packageType
    }
  });
};

//Get Company Package Usage Log for UsageLog
export const getPDUsageLog = (query) => {
  return http.get('/payments/packages/usage-logs', {
    params: {
      ...toSnakeCase(query)
    }
  });
};

//Get Company package Recharge Log
export const getPDRechargeLogs = (query) => {
  return http.get('/payments/packages/recharge-logs', {
    params: {
      ...toSnakeCase(query)
    }
  });
};
//end of PD recharge logs

/**
 * Get Base Config of package for rendering
 * @param   {object} data data for API
 * @param   {string} action API action
 * @return  {promise}
 */
export const getBasePackageConfig = (data, action = 'GET') => {
  return http[APICONSTANTS[action]]('/payments/packages/config', toSnakeCase(data));
}; // end of func: getBasePackageConfig

//Get Review Additional charges list
export const getReviewAdditionalCharges = (query) => {
  return http.get('/candidate/additional-charges', {
    params: {
      ...toSnakeCase(query)
    }
  });
}; //end of additional charges list

//Get Insuff Funds For Checks
export const getInsuffFundsForChecks = (limit, offset, status, candidateName, companyName) => {
  return http.get('/payments/packages/insuff-funds', {
    params: {
      limit,
      offset,
      status,
      candidateName,
      companyName
    }
  });
};

// Get add candidate config
export const getAddCandidateConfig = (companyId = null) => {
  return http.get(`/company/candidate/config?company_id=${companyId}`);
};

/**
 * SET/Create of Package
 * @param   {object} data data for API
 * @param   {string} action API action
 * @return  {promise}
 */
export const setPackageCount = (data, action = 'SET') => {
  return http[APICONSTANTS[action]]('/payments/packages/package', toSnakeCase(data));
}; // end of func: setPackageCount

//Get Company package Basic info
export const getPDBasicInfo = (companyId, packageId) => {
  return http.get(`/payments/packages/basic-info?package_id=${packageId}&company_id=${companyId}`);
}; //end of PD Basic Info

//Get Company package Recharge Details
export const getPDRechargeDetails = (companyId, packageId) => {
  return http.get(
    `/payments/packages/recharge-details?company_id=${companyId}&package_id=${packageId}`
  );
};
//end of PD Recharge Details

//Get Global Configuration
export const getGlobalAddonConfiguration = (model_type) => {
  return http.get(`/payments/packages/addons?model_type=${model_type}`);
};

//Set addon Configuration
export const updateAddonConfiguration = (data) => {
  return http.put('/payments/packages/addons', toSnakeCase(data));
};

//Get Company package Package Config
export const getPDPackageconfiguration = (company_id, package_id) => {
  return http.get('/payments/packages/company-subtype-config', {
    params: {
      company_id,
      package_id
    }
  });
}; //end of PD Recharge Details

//update package config from package details
export const updatePDPackageConfiguration = (data) => {
  return http.put('/payments/packages/company-subtype-mapping', toSnakeCase(data));
};

//set additional charge auto approve setting
export const setAutoApproveSetting = (data) => {
  //need to update the url
  return http.post('/payments/packages/additional-charge-settings', toSnakeCase(data));
};

//Update Approve Additional Charges
export const approveAdditionalCharges = (data) => {
  //need to update the url
  return http.put('/payments/packages/additional-charge/approve', toSnakeCase(data));
};

export const addAdditionalCharges = (data) => {
  //need to update the url
  return http.post('/payments/packages/additional-charge', toSnakeCase(data));
};

export const deleteAdditionalCharges = (additionalChargesId) => {
  return http.delete(
    `/payments/packages/additional-charge?additional_charge_id=${additionalChargesId}`
  );
};

//Get Verification order summary for Candidate
export const getVerificationOrderSummary = (company_id, candidate_id) => {
  return http.get('/payments/packages/candidate-package-details', {
    params: {
      company_id,
      candidate_id
    }
  });
};

/**
 * SET/Create of Package
 * @param   {object} data data for API
 * @param   {string} action API action
 * @return  {promise}
 */
export const getCompanyAddonConfiguration = (companyId) => {
  return http.get(`/payments/packages/addons?company_id=${companyId}`);
}; //end of getCompanyAddonConfiguration

export const getPackageUnassignedCompany = (limit, offset, package_id, company_name) => {
  const where = {
    limit,
    offset,
    package_id,
    company_name
  };

  return http.get('payments/packages/unassigned-companies', {
    params: {
      ...where
    }
  });
};

export const addRemovePackageBalance = (data) => {
  return http.put('/payments/credits/balance', toSnakeCase(data));
};

// Assign company to package
export const assignCompanyToPackage = (data) => {
  return http.post('payments/packages/assign-package', data);
};

// Add Sub Type POST
export const setSubType = (data) => {
  return http.post('/payments/packages/subtype', toSnakeCase(data));
};

export const launchPackage = (data) => {
  return http.put('/payments/packages/launch-package', toSnakeCase(data));
};

// PD recharge details PUT API
export const updatePDRechargeDetails = (data) => {
  return http.put('/payments/packages/recharge-details', toSnakeCase(data));
};

// PD - Package subtype name update API
export const updatePDPackageSubtypeName = (data) => {
  return http.put('/payments/packages/subtype-package-text', toSnakeCase(data));
};

//Get Verification order summary for Candidate
export const getSkippedCheck = (company_id, candidate_id) => {
  return http.get('/payments/packages/info-skipped', {
    params: {
      company_id,
      candidate_id
    }
  });
};

export const getCompanyAuditLogs = (companyID, packageID, limit, offset) => {
  return http.get(
    `/payments/packages/logs?package_id=${packageID}&company_id=${companyID}&limit=${limit}&offset=${offset}`
  );
};

//Get POSTPAID Package details - Montly Usage
export const getPostpaidPackageDetailsMonthlyUsage = (companyId, packageId, limit, offset) => {
  return http.get(
    `payments/packages/monthly-usage?company_id=${companyId}&package_id=${packageId}&limit=${limit}&offset=${offset}`
  );
}; // end : POSTPAID PD Monthly usage

//Get POSTPAID Package details -  Usage log
export const getPostpaidPackageDetailsUsageLog = (query) => {
  return http.get('payments/packages/usage-logs', {
    params: toSnakeCase(query)
  });
}; // end : POSTPAID PD Usage Log

//Get POSTPAID Package details -  Usage config
export const getPostpaidPackageDetailsUsageConfig = (query, companyId) => {
  return http.get(
    `/payments/credits/usage-configuration?package_id=${query}&company_id=${companyId}`
  );
}; // end : POSTPAID PD Usage Config

export const updatePackageDetailsUsageConfig = (data) => {
  return http.put('/payments/credits/usage-configuration', toSnakeCase(data));
};

//Get POSTPAID Package details - Basic Info
export const getPostpaidPackageDetailsBasicInfo = (query) => {
  return http.get('payments/packages/basic-info', {
    params: {
      ...query
    }
  });
}; // end : POSTPAID PD Basic info

//Get POSTPAID Package details - Montly Usage
export const getPostpaidCreditDetailsMonthlyUsageLogs = (companyId, limit, offset) => {
  return http.get(
    `payments/credits/monthly-usage-logs?company_id=${companyId}&limit=${limit}&offset=${offset}&month=7&year=2020`
  );
};

//Get POSTPAID Package details -  Usage log
export const getPostpaidCreditDetailsUsageLogs = (companyId, limit, offset, month, year) => {
  return http.get(
    `/payments/credits/usage-logs?company_id=${companyId}&limit=${limit}&offset=${offset}&month=${month}&year=${year}`
  );
}; // end : POSTPAID Credit Monthly usage

//Get POSTPAID Package details -  Usage log
export const getPostpaidCreditDetailsAuditLogs = (companyId, limit, offset) => {
  return http.get(`/payments/logs/overall?limit=${limit}&offset=${offset}&company_id=${companyId}`);
}; // end : POSTPAID Credit Monthly usage

//Get getPostpaidUnbilledCandidates
export const getPostpaidUnbilledCandidates = (company_id, limit, offset, useMonthYearFilter) => {
  return http.get(
    `/payments/billing/unbilled-candidates?company_id=${company_id}&limit=${limit}&offset=${offset}&useMonthYearFilter=${useMonthYearFilter}`
  );
};

//Get getPostpaidUnbilledCandidates Filters
export const getPostpaidUnbilledCandidatesListFilters = (company_id) => {
  return http.get(`payments/billing/unbilled-candidates-filters?company_id=${company_id}`);
};

//Get getPostpaidUnbilledCandidates
export const getPostpaidUnbilledCandidatesList = (
  split,
  limit,
  offset,
  company_id,
  month,
  year
) => {
  return http.get(
    `/payments/billing/unbilled-candidates?split=${split}&company_id=${company_id}&month=${month}&year=${year}&limit=${limit}&offset=${offset}`
  );
};

//Get getPostpaidBillingCompanies
export const getPostpaidBillingCompanies = (
  limit,
  offset,
  company_name,
  month,
  year,
  showPartialDueOrInvoiceIssues = false,
  status_filter
) => {
  const url = constructQueryString({
    limit,
    offset,
    company_name,
    month,
    year,
    showPartialDueOrInvoiceIssues,
    status_filter
  });
  if (url) {
    return http.get(`payments/billing/postpaid?${url}`);
  }
  return http.get(`payments/billing/postpaid`);
};

//Get getPostpaidCompanyInvoicesFilters
export const getPostpaidCompanyInvoicesFilters = (company_id) => {
  return http.get(`/payments/billing/billing-filter?company_id=${company_id}`);
};

//Get getPostpaidCompanyInvoicesList
export const getPostpaidCompanyInvoicesList = (limit, offset, invoice_id) => {
  return http.get(
    `/payments/billing/billed-candidates?invoice_id=${invoice_id}&limit=${limit}&offset=${offset}`
  );
};

//   Add/edit Discount Api

export const updateDiscountOnInvoice = (values) => {
  return http.put('payments/billing/invoice-discount', toSnakeCase(values));
};

//Download CSV - Billed Candidates
export const getBilledCandidatesListCSVDownload = (invoice_id) => {
  return http.get(`payments/billing/billed-candidates/download?invoice_id=${invoice_id}`);
};

//Update Invoice Status
export const updatePostpaidCompanyInvoicesListStatus = (data) => {
  return http.put('payments/billing/invoice-status', toSnakeCase(data));
};

// allow adding candidate to a company
export const updateCandidateAllowCompany = (data) => {
  return http.put('payments/packages/allow-adding-candidates', data);
};

//Upload Invoice - Billed Candidates
export const uploadInvoice = (date) => {
  return http.post('payments/billing/invoice-upload', toSnakeCase(date));
};
//update/add invoice payment details
export const addInvoicePayment = (data) => {
  return http.put('payments/billing/invoice-payment-details', toSnakeCase(data));
};

//Remove Billed Candidates
export const removeBilledCandidatesFromInvoice = (data) => {
  return http.put('payments/billing/remove-from-invoice', toSnakeCase(data));
};

//Add Unbilled Candidates to Billed Candidate List
export const addUnbilledToBilled = (data) => {
  return http.post('payments/billing/unbilled-candidates-to-invoice', toSnakeCase(data));
};

//Package Present
export const getPackagePresent = (packageName) => {
  var encodedString = encodeURIComponent(packageName);
  return http.get(`payments/packages/check-name?name=${encodedString}`);
};

//Get Billing Months
export const getBillingMonths = (company_id) => {
  return http.get(`payments/billing/available-cycles?company_id=${company_id}`);
};

// Add/Update Candidate Discount API
export const updateCandidateDiscount = (data) => {
  return http.put('payments/billing/candidate-discount', toSnakeCase(data));
};

//Donwload CSV - UnBilled Candidates
export const getUnBilledCandidatesListCSVDownload = (split, company_id, month, year, limit) => {
  return http.get(
    `payments/billing/download-unbilled-candidates?split=${split}&company_id=${company_id}&month=${month}&year=${year}&limit=${limit}`
  );
};
export const getBillingInfoTags = (candidateId, companyId) => {
  return http.get(
    `candidate/profile/billing-tags?candidate_id=${candidateId}&company_id=${companyId}`
  );
};
//Billing Logs
export const getBillingLogs = (company_id, limit, offset) => {
  return http.get(`payments/billing/logs?company_id=${company_id}&limit=${limit}&offset=${offset}`);
};

// Delete ADC
export const deleteAdditionalCost = (data) => {
  const url = `/candidate/additional-charges?id=${data.adc_id}&deletion_reason=${data.deletion_reason}`;
  if (data.deletion_comment) {
    return http.delete(`${url}&deletion_comment=${data.deletion_comment}`);
  }
  return http.delete(`${url}`);
};

// Create ADC
export const createAdditionalCost = (data) => {
  return http.post('/candidate/additional-charges/', toSnakeCase(data));
};

// Update ADC
export const updateAdditionalCost = (data) => {
  return http.put('/candidate/additional-charges/public-note', toSnakeCase(data));
};

// Clear ADC
export const approveADC = (data) => {
  return http.put(`candidate/additional-charges/approve`, data);
};

// Reject ADC
export const rejectADC = (data) => {
  return http.put(`candidate/additional-charges/reject`, data);
};
export const exotelCallInitiate = (data) => {
  return http.post(`/candidate/common/initiate-call`, data);
};
// create deduction transaction log
export const createDeductionTransactionLogs = (data) => {
  return http.post('payments/credits/credit-balance-transaction', toSnakeCase(data));
};

export const forceLogoutAdmin = (data) => {
  return http.post('admin/force-logout', toSnakeCase(data));
};

export const updateAdminStatus = (data) => {
  return http.put('/admin/status', data);
};

export const getFuzzySearch = (data) => {
  return http.get(`/company/fuzzy-search?search=${data}`);
};

export const uploadCandidateCSV = (data) => {
  return http.post('company/candidate-bulk-upload', data);
};

export const bulkValidation = (data) => {
  return http.post('candidate/profile/validations', toSnakeCase(data));
};

export const getZohoBookDetails = (contact_id) => {
  return http.get('company/zohobooks/config', {
    params: {
      zoho_books_contact_id: contact_id
    }
  });
};

export const disconnectZohoBook = (id) => {
  return http.delete(`/company/zohobooks?company_id=${id}`);
};
export const fetchGstin = (gstin) => {
  return http.get(`company/fetch-gstin?gstin=${gstin}`);
};
// validating friedly name
export const validateFriendlyName = (data) => {
  return http.post('payments/packages/check-subtype-friendly-name', toSnakeCase(data));
};

export const updateFinanceConfig = (data, isPutRequest) => {
  if (isPutRequest) return http.put(`/company/settings/finance-config`, data);

  /** POST request is for ZB connect modal */
  return http.post(`/company/zohobooks`, data);
};

export const getLinkedCandidates = (candidateId, companyId) => {
  return http.get(
    `candidate/profile/existing-candidate-list?candidate_id=${candidateId}&company_id=${companyId}`
  );
};

export const getExistingCandidates = (candidateId, companyId) => {
  return http.get(
    `candidate/profile/existing-candidate-search?candidate_id=${candidateId}&company_id=${companyId}`
  );
};

export const addExistingCandidates = (data) => {
  return http.put('candidate/profile/existing-candidates', toSnakeCase(data));
};

export const getAddressGeocode = (data) => {
  const addressRequest = axios.create({
    transformRequest: (data, headers) => {
      delete headers.common['Authorization'];
      return data;
    }
  });
  return addressRequest({
    url: `https://maps.googleapis.com/maps/api/geocode/json?`,
    method: 'GET',
    params: {
      address: `${data.postalCode}, ${data.country}`,
      key: `${googleEnvironmentConstant[AppHelper.getEnv()].googleMapKey}`
    }
  });
};

export const getDocumentMimetype = (
  uuid,
  isInvoice = false,
  company_type = null,
  isProformaInvoice = false
) => {
  var data = {};

  company_type ? (data.type = company_type) : null;
  isInvoice ? (data.invoice_id = uuid) : (data.uuid = uuid);
  isProformaInvoice ? (data.is_proforma_invoice = isProformaInvoice) : null;

  return http.get(`/candidate/report/get-report-mimetype`, {
    params: data
  });
};

export const getCompanyUserList = (data) => {
  return http.get('company/filters', {
    params: {
      company_id: data.companyId,
      source: data.source,
      field: data.field
    }
  });
};

export const getInvoiceData = (data) => {
  return http.get('company/zohobooks/invoice', {
    params: {
      invoice_number: data.invoiceNumber
    }
  });
};
export const getAllowedDomains = (companyId) => {
  return http.get(`/company/security-settings/allowed-domains?company_id=${companyId}`);
};

export const convertCheckType = (payload) => {
  return http.put(`candidate/common/convert-check-verification-type`, payload);
};
export const initiateCourtVP = (payload) => {
  return http.post(`candidate/court/initiate-vp`, toSnakeCase(payload));
};

export const getChartData = (req) => {
  return http.get(
    `/company/analytics/get-charts?start_year=${req.start_year}&end_year=${req.end_year}&start_month=${req.start_month}&company_id=${req.company_id}&end_month=${req.end_month}`
  );
};

/**
 * Update Email preference API
 * @param {number} companyId
 * @param {object} data
 */
export const postEmailPreferences = (companyId, data) => {
  return http.post(`/company/settings/email-reminder?company_id=${companyId}`, data);
};
// unique records for logs
export const getUniqueRecords = (candidate_id, limit, offset) => {
  return http.get('/candidate/logs/unique-records', {
    params: {
      candidate_id
    }
  });
};
