import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import { BILLING_MONTHS_LIST } from '../core-components/CommonConstants';

import { setAuthToken, setCookieValue } from '../api/storage';
import AppConstants from '../core-components/AppConstants';

import {
  getCandidates as getCandidatesAPI,
  getCompanyServices as getCompanyServicesAPI,
  getViewCandidate as getViewCandidateAPI,
  globalSearch as globalSearchAPI,
  getCompanies as getCompaniesAPI,
  createCandidate as createCandidateAPI,
  editCandidate as editCandidateAPI,
  getInsufficiency as getInsufficiencyAPI,
  getCompanyById as getCompanyByIdAPI,
  getCompanyBgvConfig as getCompanyBgvConfigAPI,
  getRefQuestions as getRefQuestionsAPI,
  getCompanyCategories as getCompanyCategoriesAPI,
  getCompanyLogs as getCompanyLogsAPI,
  getServices as getServicesAPI,
  deleteServices as deleteServicesAPI,
  getCompanyAdmin as getCompanyAdminAPI,
  getCompanyAssignedServices as getCompanyAssignedServicesAPI,
  getCompanySettings as getCompanySettingsAPI,
  editCompanySettings as editCompanySettingsAPI,
  getOpsStatus as getOpsStatusAPI,
  getVendors as getVendorsAPI,
  getDefaultQuestions as getDefaultQuestionsAPI,
  getAdmins as getAdminsAPI,
  getViewCandidateDetails as getViewCandidateDetailsAPI,
  // getAdcCount as getAdcCountAPI,
  getADCList as getADCListAPI,
  getWarnings as getWarningsAPI,
  getIdentityDataFound as getIdenityDataFoundAPI,
  getIdentityDataProvided as getIdentityDataProvidedAPI,
  getEducationDataProvided as getEducationDataProvidedAPI,
  getEducationDataFound as getEducationDataFoundAPI,
  getEmploymentDataFound as getEmploymentDataFoundAPI,
  getEmploymentDataProvided as getEmploymentDataProvidedAPI,
  getDrugDataProvided as getDrugDataProvidedAPI,
  getWorldData as getWorldDataAPI,
  getDrugDataFound as getDrugDataFoundAPI,
  getIdentityRunCheck as getIdentityRunCheckAPI,
  getCibilDataFound as getCibilDataFoundAPI,
  getCibilDataProvided as getCibilDataProvidedAPI,
  getReferenceDataProvided as getReferenceDataProvidedAPI,
  getReferenceDataFound as getReferenceDataFoundAPI,
  getAddressDataProvided as getAddressDataProvidedAPI,
  getCourtDataProvided as getCourtDataProvidedAPI,
  getCourtDataFound as getCourtDataFoundAPI,
  getAddressDataFound as getAddressDataFoundAPI,
  getNotes as getNotesAPI,
  getOpsStatusNotes as getOpsStatusNotesAPI,
  getCandidateLogs as getCandidateLogsAPI,
  getCandidateCallLogs as getCandidateCallLogsAPI,
  getVendorsList as getVendorsListAPI,
  getVendorsInfo as getVendorsInfoAPI,
  getIdentityOcrCheck as getIdentityOcrCheckAPI,
  getFeedback as getFeedbackAPI,
  login as loginAPI,
  getReports as getReportsAPI,
  loginViaJWT as loginViaJWTAPI,
  getCustomCheckDF as getCustomCheckDFAPI,
  getInsuffReview as getInsuffReviewAPI,
  getCompanyEmailSettings as getCompanyEmailSettingsAPI,
  getInsuffTicketList as getInsuffTicketListAPI,
  getInsufficiencyForCandidate as getInsufficiencyForCandidateAPI,
  getPackageDetails as getPackageDetailsAPI,
  getPackageCompanyList as getPackageCompanyListAPI,
  getPackageDetailsAuditLogs as getPackageDetailsAuditLogsAPI,
  getPackageList as getPackageListAPI,
  getCreditBalance as getCreditBalanceAPI,
  getCompanyPendingOrders as getCompanyPendingOrdersAPI,
  getPostpaidCreditCompanies as getPostpaidCreditCompaniesAPI,
  getCompanyRechargeLogs as getCompanyRechargeLogsAPI,
  getCompanyUsageLogs as getCompanyUsageLogsAPI,
  getOverallPaymentLogs as getOverallPaymentLogsAPI,
  getCompanyPackages as getCompanyPackagesAPI,
  getPDRechargeLogs as getPDRechargeLogsAPI,
  getPDUsageLog as getPDUsageLogsAPI,
  getReviewAdditionalCharges as getReviewAdditionalChargesAPI,
  getInsuffFundsForChecks as getInsuffFundsForChecksAPI,
  getAddCandidateConfig as getAddCandidateConfigAPI,
  getPDBasicInfo as getPDBasicInfoAPI,
  getPDRechargeDetails as getPDRechargeDetailsAPI,
  getGlobalAddonConfiguration as getGlobalAddonConfigurationAPI,
  getPDPackageconfiguration as getPDPackageconfigurationAPI,
  getVerificationOrderSummary as getVerificationOrderSummaryAPI,
  getCompanyAddonConfiguration as getCompanyAddonConfigurationAPI,
  getSkippedCheck as getSkippedCheckAPI,
  getCompanyAuditLogs as getCompanyAuditLogsAPI,
  getPostpaidPackageDetailsMonthlyUsage as getPostpaidPackageDetailsMonthlyUsageAPI,
  getPostpaidPackageDetailsUsageLog as getPostpaidPackageDetailsUsageLogAPI,
  getPostpaidPackageDetailsUsageConfig as getPostpaidPackageDetailsUsageConfigAPI,
  getPostpaidPackageDetailsBasicInfo as getPostpaidPackageDetailsBasicInfoAPI,
  getPostpaidCompanyCreditView as getPostpaidCreditCompanyAPI,
  getPostpaidCreditDetailsMonthlyUsageLogs as getPostpaidCreditDetailsMonthlyUsageAPI,
  getPostpaidCreditDetailsUsageLogs as getPostpaidCreditDetailsUsageLogAPI,
  getPostpaidCreditDetailsAuditLogs as getPostpaidCreditDetailsAuditLogAPI,
  getPostpaidUnbilledCandidatesList as getPostpaidUnbilledCandidatesListAPI,
  getPostpaidUnbilledCandidatesListFilters as getPostpaidUnbilledCandidatesListFiltersAPI,
  getPostpaidCompanyInvoicesList as getPostpaidCompanyInvoicesListAPI,
  getPostpaidCompanyInvoicesFilters as getPostpaidCompanyInvoicesFiltersAPI,
  getBillingInfoTags as getBillingInfoTagsAPI,
  getBillingLogs as getBillingLogsAPI,
  forceLogoutAdmin as forceLogoutAdminAPI,
  updateAdminStatus as updateAdminStatusAPI,
  uploadCandidateCSV as uploadCandidateCSVAPI,
  getLinkedCandidates as getLinkedCandidatesAPI,
  getReportsExternal as getReportsExternalAPI,
  getUniqueRecords as getUniqueRecordsAPI
} from '../api/admin';
import { toCamelCase, snakeToCamel, errToastMessage } from '../utils/Utlities';

export const INVALIDATE_CANDIDATES = 'INVALIDATE_CANDIDATES';
export const REQUEST_CANDIDATES = 'REQUEST_CANDIDATES';
export const SET_CANDIDATES = 'SET_CANDIDATES';

export const INVALIDATE_COMPANY_SERVICES = 'INVALIDATE_COMPANY_SERVICES';
export const REQUEST_COMPANY_SERVICES = 'REQUEST_COMPANY_SERVICES';
export const UPDATE_COMPANY_SERVICES = 'UPDATE_COMPANY_SERVICES';

export const INVALIDATE_VIEW_CANDIDATES = 'INVALIDATE_VIEW_CANDIDATES';
export const REQUEST_VIEW_CANDIDATES = 'REQUEST_VIEW_CANDIDATES';
export const SET_VIEW_CANDIDATES = 'SET_VIEW_CANDIDATES';

export const SET_CREATE_CANDIDATE = 'SET_CREATE_CANDIDATE';

export const SET_SEARCH_CANDIDATE = 'SET_SEARCH_CANDIDATE';
export const REQUEST_SEARCH_CANDIDATE = 'REQUEST_SEARCH_CANDIDATE';
export const INVALIDATE_SEARCH_CANDIDATE = 'INVALIDATE_SEARCH_CANDIDATE';

export const SET_COMPANIES = 'SET_COMPANIES';

export const SET_INSUFFICIENCY = 'SET_INSUFFICIENCY';

export const INVALIDATE_COMPANY_DETAILS = 'INVALIDATE_COMPANY_DETAILS';
export const REQUEST_COMPANY_DETAILS = 'REQUEST_COMPANY_DETAILS';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';

export const INVALIDATE_COMPANY_BGV_CONFIG = 'INVALIDATE_COMPANY_BGV_CONFIG';
export const REQUEST_COMPANY_BGV_CONFIG = 'REQUEST_COMPANY_BGV_CONFIG';
export const SET_COMPANY_BGV_CONFIG = 'SET_COMPANY_BGV_CONFIG';

export const INVALIDATE_REFQUESTIONS = 'INVALIDATE_REFQUESTIONS';
export const REQUEST_REFQUESTIONS = 'REQUEST_REFQUESTIONS';
export const SET_REFQUESTIONS = 'SET_REFQUESTIONS';

export const INVALIDATE_COMPANY_CATEGORIES = 'INVALIDATE_COMPANY_CATEGORIES';
export const REQUEST_COMPANY_CATEGORIES = 'REQUEST_COMPANY_CATEGORIES';
export const SET_COMPANY_CATEGORIES = 'SET_COMPANY_CATEGORIES';

export const INVALIDATE_COMPANYLOGS = 'INVALIDATE_COMPANYLOGS';
export const REQUEST_COMPANYLOGS = 'REQUEST_COMPANYLOGS';
export const SET_COMPANYLOGS = 'SET_COMPANYLOGS';

export const SET_SERVICES = 'SET_SERVICES';

export const SET_COMPANY_ADMIN = 'SET_COMPANY_ADMIN';

export const INVALIDATE_ASSIGNED_SERVICES = 'INVALIDATE_ASSIGNED_SERVICES';
export const REQUEST_ASSIGNED_SERVICES = 'REQUEST_ASSIGNED_SERVICES';
export const SET_ASSIGNED_SERVICES = 'SET_ASSIGNED_SERVICES';

export const INVALIDATE_COMPANY_SETTINGS = 'INVALIDATE_COMPANY_SETTINGS';
export const REQUEST_COMPANY_SETTINGS = 'REQUEST_COMPANY_SETTINGS';
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS';

export const SET_OPS_STATUS = 'SET_OPS_STATUS';

export const INVALIDATE_VENDORS = 'INVALIDATE_VENDORS';
export const REQUEST_VENDORS = 'REQUEST_VENDORS';
export const SET_VENDORS = 'SET_VENDORS';

export const INVALIDATE_DEFAULT_QUESTIONS = 'INVALIDATE_DEFAULT_QUESTIONS ';
export const REQUEST_DEFAULT_QUESTIONS = 'REQUEST_DEFAULT_QUESTIONS ';
export const SET_DEFAULT_QUESTIONS = 'SET_DEFAULT_QUESTIONS';

export const SET_ADMINS = 'SET_ADMINS';

export const INVALIDATE_VIEW_CANDIDATE_DETAILS = 'INVALIDATE_VIEW_CANDIDATE_DETAILS';
export const REQUEST_VIEW_CANDIDATE_DETAILS = 'REQUEST_VIEW_CANDIDATE_DETAILS';
export const SET_VIEW_CANDIDATE_DETAILS = 'SET_VIEW_CANDIDATE_DETAILS';

export const SET_ADC_LIST = 'SET_ADC_LIST';
export const REQUEST_ADC_LIST = 'REQUEST_ADC_LIST';
export const INVALIDATE_ADC_LIST = 'INVALIDATE_ADC_LIST';

export const INVALIDATE_WARNINGS = 'INVALIDATE_WARNINGS';
export const REQUEST_WARNINGS = 'REQUEST_WARNINGS';
export const SET_WARNINGS = 'SET_WARNINGS';

export const SET_IDENTITY_DATA_FOUND = 'SET_IDENTITY_DATA_FOUND';
export const INVALIDATE_IDENTITY_DATA_FOUND = 'INVALIDATE_IDENTITY_DATA_FOUND';
export const SET_IDENTITY_DATA_PROVIDED = 'SET_IDENTITY_DATA_PROVIDED';
export const INVALIDATE_IDENTITY_DATA_PROVIDED = 'INVALIDATE_IDENTITY_DATA_PROVIDED';

export const SET_IDENTITY_RUN_CHECK = 'SET_IDENTITY_RUN_CHECK';
export const INVALIDATE_IDENTITY_RUN_CHECK = 'INVALIDATE_IDENTITY_RUN_CHECK';

export const INVALIDATE_EDUCATION_DATA_PROVIDED = 'INVALIDATE_EDUCATION_DATA_PROVIDED';
export const SET_EDUCATION_DATA_PROVIDED = 'SET_EDUCATION_DATA_PROVIDED';

export const INVALIDATE_EDUCATION_DATA_FOUND = 'INVALIDATE_EDUCATION_DATA_FOUND';
export const SET_EDUCATION_DATA_FOUND = 'SET_EDUCATION_DATA_FOUND';

export const INVALIDATE_EMPLOYMENT_DATA_PROVIDED = 'INVALIDATE_EMPLOYMENT_DATA_PROVIDED';
export const SET_EMPLOYMENT_DATA_PROVIDED = 'SET_EMPLOYMENT_DATA_PROVIDED';
export const REQUEST_EMPLOYMENT_DATA_PROVIDED = 'REQUEST_EMPLOYMENT_DATA_PROVIDED';

export const INVALIDATE_EMPLOYMENT_DATA_FOUND = 'INVALIDATE_EMPLOYMENT_DATA_FOUND';
export const SET_EMPLOYMENT_DATA_FOUND = 'SET_EMPLOYMENT_DATA_FOUND';
export const REQUEST_EMPLOYMENT_DATA_FOUND = 'REQUEST_EMPLOYMENT_DATA_FOUND';

export const SET_DRUG_DATA_FOUND = 'SET_DRUG_DATA_FOUND';
export const INVALIDATE_DRUG_DATA_FOUND = 'INVALIDATE_DRUG_DATA_FOUND';
export const SET_DRUG_DATA_PROVIDED = 'SET_DRUG_DATA_PROVIDED';
export const INVALIDATE_DRUG_DATA_PROVIDED = 'INVALIDATE_DRUG_DATA_PROVIDED';

export const SET_WORLD_DATA_FOUND = 'SET_WORLD_DATA_FOUND';
export const SET_WORLD_DATA_PROVIDED = 'SET_WORLD_DATA_PROVIDED';
export const INVALIDATE_WORLD_DATA_PROVIDED = 'INVALIDATE_WORLD_DATA_PROVIDED';
export const INVALIDATE_WORLD_DATA_FOUND = 'INVALIDATE_WORLD_DATA_FOUND';

export const INVALIDATE_CIBIL_DATA_PROVIDED = 'INVALIDATE_CIBIL_DATA_PROVIDED';
export const SET_CIBIL_DATA_PROVIDED = 'SET_CIBIL_DATA_PROVIDED';
export const REQUEST_CIBIL_DATA_PROVIDED = 'REQUEST_CIBIL_DATA_PROVIDED';

export const INVALIDATE_CIBIL_DATA_FOUND = 'INVALIDATE_CIBIL_DATA_FOUND';
export const SET_CIBIL_DATA_FOUND = 'SET_CIBIL_DATA_FOUND';
export const REQUEST_CIBIL_DATA_FOUND = 'REQUEST_CIBIL_DATA_FOUND';

export const INVALIDATE_REFERENCE_DATA_PROVIDED = 'INVALIDATE_REFERENCE_DATA_PROVIDED';
export const SET_REFERENCE_DATA_PROVIDED = 'SET_REFERENCE_DATA_PROVIDED';

export const INVALIDATE_REFERENCE_DATA_FOUND = 'INVALIDATE_REFERENCE_DATA_FOUND';
export const SET_REFERENCE_DATA_FOUND = 'SET_REFERENCE_DATA_FOUND';

export const INVALIDATE_ADDRESS_DATA_PROVIDED = 'INVALIDATE_ADDRESS_DATA_PROVIDED';
export const SET_ADDRESS_DATA_PROVIDED = 'SET_ADDRESS_DATA_PROVIDED';

export const INVALIDATE_COURT_DATA_PROVIDED = 'INVALIDATE_COURT_DATA_PROVIDED';
export const SET_COURT_DATA_PROVIDED = 'SET_COURT_DATA_PROVIDED';

export const INVALIDATE_COURT_DATA_FOUND = 'INVALIDATE_COURT_DATA_FOUND';
export const SET_COURT_DATA_FOUND = 'SET_COURT_DATA_FOUND';

export const INVALIDATE_ADDRESS_DATA_FOUND = 'INVALIDATE_ADDRESS_DATA_FOUND';
export const SET_ADDRESS_DATA_FOUND = 'SET_ADDRESS_DATA_FOUND';

export const INVALIDATE_NOTES = 'INVALIDATE_NOTES';
export const SET_NOTES = 'SET_NOTES';

export const INVALIDATE_OPS_STATUS_NOTES = 'INVALIDATE_OPS_STATUS_NOTES';
export const SET_OPS_STATUS_NOTES = 'SET_OPS_STATUS_NOTES';

export const INVALIDATE_CANDIDATE_LOGS = 'INVALIDATE_CANDIDATE_LOGS';
export const SET_CANDIDATE_LOGS = 'SET_CANDIDATE_LOGS';
export const REQUEST_CANDIDATE_LOGS = 'REQUEST_CANDIDATE_LOGS';

export const INVALIDATE_CANDIDATE_CALL_LOGS = 'INVALIDATE_CANDIDATE_CALL_LOGS';
export const SET_CANDIDATE_CALL_LOGS = 'SET_CANDIDATE_CALL_LOGS';
export const REQUEST_CANDIDATE_CALL_LOGS = 'REQUEST_CANDIDATE_CALL_LOGS';

export const INVALIDATE_VENDORS_LIST = 'INVALIDATE_VENDORS_LIST';
export const SET_VENDORS_LIST = 'SET_VENDORS_LIST';

export const INVALIDATE_VENDORS_INFO = 'INVALIDATE_VENDORS_INFO';
export const SET_VENDORS_INFO = 'SET_VENDORS_INFO';

export const INVALIDATE_IDENTITY_OCR = 'INVALIDATE_IDENTITY_OCR';
export const SET_IDENTITY_OCR = 'SET_IDENTITY_OCR';

export const INVALIDATE_FEEDBACK = 'INVALIDATE_FEEDBACK';
export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK';
export const SET_FEEDBACK = 'SET_FEEDBACK';

export const INVALIDATE_PROFILE = 'INVALIDATE_PROFILE';
export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';

export const INVALIDATE_REPORTS = 'INVALIDATE_REPORTS';
export const REQUEST_REPORTS = 'REQUEST_REPORTS';
export const SET_REPORTS = 'SET_REPORTS';

export const INVALIDATE_CUSTOM_DATA_FOUND = 'INVALIDATE_CUSTOM_DATA_FOUND';
export const SET_CUSTOM_DATA_FOUND = 'SET_CUSTOM_DATA_FOUND';

export const REQUEST_INSUFF_REVIEW = 'REQUEST_INSUFF_REVIEW';
export const INVALIDATE_INSUFF_REVIEW = 'INVALIDATE_INSUFF_REVIEW';
export const SET_INSUFF_REVIEW = 'SET_INSUFF_REVIEW';

export const INVALIDATE_COMPANY_EMAIL_SETTINGS = 'INVALIDATE_COMPANY_EMAIL_SETTINGS';
export const SET_COMPANY_EMAIL_SETTINGS = 'SET_COMPANY_EMAIL_SETTINGS';
export const REQUEST_COMPANY_EMAIL_SETTINGS = 'REQUEST_COMPANY_EMAIL_SETTINGS';

export const REQUEST_INSUFF_LIST = 'REQUEST_INSUFF_LIST';
export const INVALIDATE_INSUFF_LIST = 'INVALIDATE_INSUFF_LIST';
export const SET_INSUFF_LIST = 'SET_INSUFF_LIST';

export const INVALIDATE_INSUFF_CANDIDATE = 'INVALIDATE_INSUFF_CANDIDATE';
export const REQUEST_INSUFF_CANDIDATE = 'REQUEST_INSUFF_CANDIDATE';
export const SET_INSUFF_CANDIDATE = 'SET_INSUFF_CANDIDATE';

export const INVALIDATE_PACKAGE_DETAILS = 'INVALIDATE_PACKAGE_DETAILS';
export const REQUEST_PACKAGE_DETAILS = 'REQUEST_PACKAGE_DETAILS';
export const SET_PACKAGE_DETAILS = 'SET_PACKAGE_DETAILS';

export const INVALIDATE_PACKAGE_COMPANY_LIST = 'INVALIDATE_PACKAGE_COMPANY_LIST';
export const REQUEST_PACKAGE_COMPANY_LIST = 'REQUEST_PACKAGE_COMPANY_LIST';
export const SET_PACKAGE_COMPANY_LIST = 'SET_PACKAGE_COMPANY_LIST';

export const INVALIDATE_PACKAGE_DETAILS_AUDIT_LOGS = 'INVALIDATE_PACKAGE_DETAILS_AUDIT_LOGS';
export const REQUEST_PACKAGE_DETAILS_AUDIT_LOGS = 'REQUEST_PACKAGE_DETAILS_AUDIT_LOGS';
export const SET_PACKAGE_DETAILS_AUDIT_LOGS = 'SET_PACKAGE_DETAILS_AUDIT_LOGS';

export const INVALIDATE_PACKAGE_LIST = 'INVALIDATE_PACKAGE_LIST';
export const REQUEST_PACKAGE_LIST = 'REQUEST_PACKAGE_LIST';
export const SET_PACKAGE_LIST = 'SET_PACKAGE_LIST';

export const SET_CREDIT_BALANCE = 'SET_CREDIT_BALANCE';

export const REQUEST_COMPANY_CREDIT_BALANCE = 'REQUEST_COMPANY_CREDIT_BALANCE';
export const SET_COMPANY_CREDIT_BALANCE = 'SET_COMPANY_CREDIT_BALANCE';
export const REQUEST_COMPANY_PENDING_ORDERS = 'REQUEST_COMPANY_PENDING_ORDERS';
export const SET_COMPANY_PENDING_ORDERS = 'SET_COMPANY_PENDING_ORDERS';
export const INVALIDATE_COMPANY_PENDING_ORDERS = 'INVALIDATE_COMPANY_PENDING_ORDERS';
export const INVALIDATE_COMPANY_CREDIT_BALANCE = 'INVALIDATE_COMPANY_CREDIT_BALANCE';
export const SET_COMPANY_RECHARGE_LOG = 'SET_COMPANY_RECHARGE_LOG';
export const SET_COMPANY_USAGE_LOG = 'SET_COMPANY_USAGE_LOG';
export const SET_PREPAID_USAGE_LOG = 'SET_PREPAID_USAGE_LOG';

export const SET_OVERALL_PAYMENT_LOGS = 'SET_OVERALL_PAYMENT_LOGS';
export const INVALIDATE_OVERALL_PAYMENT_LOGS = 'INVALIDATE_OVERALL_PAYMENT_LOGS';
export const REQUEST_OVERALL_PAYMENT_LOGS = 'REQUEST_OVERALL_PAYMENT_LOGS';

export const SET_PD_RECHARGE_LOG = 'SET_PD_RECHARGE_LOG';
export const INVALIDATE_PD_RECHARGE_LOG = 'INVALIDATE_PD_RECHARGE_LOG';
export const SET_PD_USAGE_LOG = 'SET_PD_USAGE_LOG';
export const INVALIDATE_PD_USAGE_LOG = 'INVALIDATE_PD_USAGE_LOG';
export const SET_PD_BASIC_INFO = 'SET_PD_USAGE_INFO';
export const INVALIDATE_PD_BASIC_INFO = 'INVALIDATE_PD_USAGE_INFO';
export const SET_PD_RECHARGE_DETAILS = 'SET_PD_RECHARGE_DETAILS';
export const INVALIDATE_PD_RECHARGE_DETAILS = 'INVALIDATE_PD_RECHARGE_DETAILS';
export const SET_PD_PACKAGE_CONFIG = 'SET_PD_PACKAGE_CONFIG';
export const INVALIDATE_PD_PACKAGE_CONFIG = 'INVALIDATE_PD_PACKAGE_CONFIG';

export const SET_REVIEW_ADDITIONAL_CHARGES = 'SET_REVIEW_ADDITIONAL_CHARGES';
export const REQUEST_REVIEW_ADDITIONAL_CHARGES = 'REQUEST_REVIEW_ADDITIONAL_CHARGES';
export const INVALIDATE_REVIEW_ADDITIONAL_CHARGES = 'INVALIDATE_REVIEW_ADDITIONAL_CHARGES';

export const INVALIDATE_INSUFF_FUNDS_FOR_CHECKS = 'INVALIDATE_INSUFF_FUNDS_FOR_CHECKS';
export const REQUEST_INSUFF_FUNDS_FOR_CHECKS = 'REQUEST_INSUFF_FUNDS_FOR_CHECKS';
export const SET_INSUFF_FUNDS_FOR_CHECKS = 'SET_INSUFF_FUNDS_FOR_CHECKS';

export const INVALIDATE_ADD_CANDIDATE_CONFIG = 'INVALIDATE_ADD_CANDIDATE_CONFIG';
export const REQUEST_ADD_CANDIDATE_CONFIG = 'REQUEST_ADD_CANDIDATE_CONFIG';
export const SET_ADD_CANDIDATE_CONFIG = 'SET_ADD_CANDIDATE_CONFIG';

export const SET_GLOBAL_ADDON_CONFIGURATION = 'SET_GLOBAL_ADDON_CONFIGURATION';
export const INVALIDATE_GLOBAL_ADDON_CONFIGURATION = 'INVALIDATE_GLOBAL_ADDON_CONFIGURATION';
export const REQUEST_GLOBAL_ADDON_CONFIGURATION = 'REQUEST_GLOBAL_ADDON_CONFIGURATION';

export const SET_VERIFICATION_ORDER_SUMMARY = 'SET_VERIFICATION_ORDER_SUMMARY';
export const INVALIDATE_VERIFICATION_ORDER_SUMMARY = 'INVALIDATE_VERIFICATION_ORDER_SUMMARY';
export const REQUEST_VERIFICATION_ORDER_SUMMARY = 'REQUEST_VERIFICATION_ORDER_SUMMARY';

export const SET_COMPANY_ADDON_CONFIGURATION = 'SET_COMPANY_ADDON_CONFIGURATION';
export const INVALIDATE_COMPANY_ADDON_CONFIGURATION = 'INVALIDATE_COMPANY_ADDON_CONFIGURATION';
export const REQUEST_COMPANY_ADDON_CONFIGURATION = 'REQUEST_COMPANY_ADDON_CONFIGURATION';

export const SET_SEARCH_PACKAGE = 'SET_SEARCH_PACKAGE';
export const INVALIDATE_SEARCH_PACKAGE = 'INVALIDATE_SEARCH_PACKAGE';

export const SET_SKIPPED_CHECK = 'SET_SKIPPED_CHECK';
export const INVALIDATE_SKIPPED_CHECK = 'INVALIDATE_SKIPPED_CHECK';
export const REQUEST_SKIPPED_CHECK = 'REQUEST_SKIPPED_CHECK';

export const SET_COMPANY_AUDIT_LOGS = 'SET_COMPANY_AUDIT_LOGS';
export const INVALIDATE_COMPANY_AUDIT_LOGS = 'INVALIDATE_COMPANY_AUDIT_LOGS';
export const REQUEST_COMPANY_AUDIT_LOGS = 'REQUEST_COMPANY_AUDIT_LOGS';

export const SET_CREDIT_POSTPAID_COMPANIES = 'SET_CREDIT_POSTPAID_COMPANIES';
export const INVALIDATE_CREDIT_POSTPAID_COMPANIES = 'INVALIDATE_CREDIT_POSTPAID_COMPANIES';
export const REQUEST_CREDIT_POSTPAID_COMPANIES = 'REQUEST_CREDIT_POSTPAID_COMPANIES';

export const SET_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE =
  'SET_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE';
export const INVALIDATE_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE =
  'INVALIDATE_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE';
export const REQUEST_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE =
  'REQUEST_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE';

export const SET_POSTPAID_PACKAGE_DETAILS_USAGE_LOG = 'SET_POSTPAID_PACKAGE_DETAILS_USAGE_LOG';
export const INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_LOG =
  'INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_LOG';
export const REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_LOG =
  'REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_LOG';

export const SET_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG =
  'SET_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG';
export const INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG =
  'INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG';
export const REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG =
  'REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG';

export const SET_POSTPAID_PACKAGE_DETAILS_BASIC_INFO = 'SET_POSTPAID_PACKAGE_DETAILS_BASIC_INFO';
export const INVALIDATE_POSTPAID_PACKAGE_DETAILS_BASIC_INFO =
  'INVALIDATE_POSTPAID_PACKAGE_DETAILS_BASIC_INFO';
export const REQUEST_POSTPAID_PACKAGE_DETAILS_BASIC_INFO =
  'REQUEST_POSTPAID_PACKAGE_DETAILS_BASIC_INFO';

export const SET_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE =
  'SET_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE';
export const INVALIDATE_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE =
  'INVALIDATE_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE';
export const REQUEST_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE =
  'REQUEST_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE';

export const SET_POSTPAID_CREDITS_DETAILS_USAGE_LOG = 'SET_POSTPAID_CREDITS_DETAILS_USAGE_LOG';
export const INVALIDATE_POSTPAID_CREDITS_DETAILS_USAGE_LOG =
  'INVALIDATE_POSTPAID_CREDITS_DETAILS_USAGE_LOG';
export const REQUEST_POSTPAID_CREDITS_DETAILS_USAGE_LOG =
  'REQUEST_POSTPAID_CREDITS_DETAILS_USAGE_LOG';

export const SET_CREDIT_POSTPAID_COMPANY = 'SET_CREDIT_POSTPAID_COMPANY';
export const INVALIDATE_CREDIT_POSTPAID_COMPANY = 'INVALIDATE_CREDIT_POSTPAID_COMPANY';
export const REQUEST_CREDIT_POSTPAID_COMPANY = 'REQUEST_CREDIT_POSTPAID_COMPANY';

export const SET_POSTPAID_CREDITS_DETAILS_AUDIT_LOG = 'SET_POSTPAID_CREDITS_DETAILS_AUDIT_LOG';
export const INVALIDATE_POSTPAID_CREDITS_DETAILS_AUDIT_LOG =
  'INVALIDATE_POSTPAID_CREDITS_DETAILS_AUDIT_LOG';
export const REQUEST_POSTPAID_CREDITS_DETAILS_AUDIT_LOG =
  'REQUEST_POSTPAID_CREDITS_DETAILS_AUDIT_LOG';

export const SET_POSTPAID_UNBILLED_CANDIDATES = 'SET_POSTPAID_UNBILLED_CANDIDATES';
export const INVALIDATE_POSTPAID_UNBILLED_CANDIDATES = 'INVALIDATE_POSTPAID_UNBILLED_CANDIDATES';
export const REQUEST_POSTPAID_UNBILLED_CANDIDATES = 'REQUEST_POSTPAID_UNBILLED_CANDIDATES';

export const SET_POSTPAID_UNBILLED_CANDIDATES_FILTERS = 'SET_POSTPAID_UNBILLED_CANDIDATES_FILTERS';
export const INVALIDATE_POSTPAID_UNBILLED_CANDIDATES_FILTERS =
  'INVALIDATE_POSTPAID_UNBILLED_CANDIDATES_FILTERS';
export const REQUEST_POSTPAID_UNBILLED_CANDIDATES_FILTERS =
  'REQUEST_POSTPAID_UNBILLED_CANDIDATES_FILTERS';

export const SET_POSTPAID_COMPANY_INVOICES_LIST = 'SET_POSTPAID_COMPANY_INVOICES_LIST';
export const INVALIDATE_POSTPAID_COMPANY_INVOICES_LIST =
  'INVALIDATE_POSTPAID_COMPANY_INVOICES_LIST';
export const REQUEST_POSTPAID_COMPANY_INVOICES_LIST = 'REQUEST_POSTPAID_COMPANY_INVOICES_LIST';

export const SET_POSTPAID_COMPANY_INVOICES_FILTERS = 'SET_POSTPAID_COMPANY_INVOICES_FILTERS';
export const INVALIDATE_POSTPAID_COMPANY_INVOICES_FILTERS =
  'INVALIDATE_POSTPAID_COMPANY_INVOICES_FILTERS';
export const REQUEST_POSTPAID_COMPANY_INVOICES_FILTERS =
  'REQUEST_POSTPAID_COMPANY_INVOICES_FILTERS';

export const REQUEST_BILLING_TAGS = 'REQUEST_BILLING_TAGS';
export const SET_BILLING_TAGS = 'SET_BILLING_TAGS';
export const REQUEST_BILLING_LOGS = 'REQUEST_BILLING_LOGS';
export const SET_BILLING_LOGS = 'SET_BILLING_LOGS';
export const UPDATE_ADMIN_STATUS = 'UPDATE_ADMIN_STATUS';
export const REQUEST_RECHARGE_LOGS = 'REQUEST_RECHARGE_LOGS';

export const INVALIDATE_LINKED_CANDIDATES = 'INVALIDATE_LINKED_CANDIDATES';
export const REQUEST_LINKED_CANDIDATES = 'REQUEST_LINKED_CANDIDATES';
export const SET_LINKED_CANDIDATES = 'SET_LINKED_CANDIDATES';

export const REQUEST_UNIQUE_RECORDS = 'REQUEST_UNIQUE_RECORDS';
export const INVALIDATE_UNIQUE_RECORDS = 'INVALIDATE_UNIQUE_RECORDS';
export const SET_UNIQUE_RECORDS = 'SET_UNIQUE_RECORDS';

export const invalidateCandidates = () => {
  return {
    type: INVALIDATE_CANDIDATES
  };
};

export const requestCandidates = () => {
  return {
    type: REQUEST_CANDIDATES
  };
};

export const setCandidates = (index, data) => {
  return {
    type: SET_CANDIDATES,
    index,
    data
  };
};

export const invalidateViewCandidates = () => {
  return {
    type: INVALIDATE_VIEW_CANDIDATES
  };
};

export const requestViewCandidates = () => {
  return {
    type: REQUEST_VIEW_CANDIDATES
  };
};

export const setViewCandidates = (data) => {
  return {
    type: SET_VIEW_CANDIDATES,
    data
  };
};

export const invalidateCompanyServices = () => {
  return {
    type: INVALIDATE_COMPANY_SERVICES
  };
};
export const requestCompanyServices = () => {
  return {
    type: REQUEST_COMPANY_SERVICES
  };
};

export const updateCompanyServices = (data) => {
  return {
    type: UPDATE_COMPANY_SERVICES,
    data
  };
};

export const setCreateCandidate = (data) => {
  return {
    type: SET_CREATE_CANDIDATE,
    data
  };
};

export const setGlobalSearch = (data) => {
  return {
    type: SET_SEARCH_CANDIDATE,
    data: data
  };
};
export const requestGlobalSearch = () => {
  return {
    type: REQUEST_SEARCH_CANDIDATE
  };
};

export const setCompanies = (data) => {
  return {
    type: SET_COMPANIES,
    data
  };
};

export const invalidateGlobalSearch = () => {
  return {
    type: INVALIDATE_SEARCH_CANDIDATE
  };
};

export const setInsufficiency = (data) => {
  return {
    type: SET_INSUFFICIENCY,
    data
  };
};

export const invalidateCompanyDetail = () => {
  return {
    type: INVALIDATE_COMPANY_DETAILS
  };
};

export const requestCompanyDetail = () => {
  return {
    type: REQUEST_COMPANY_DETAILS
  };
};

export const setCompanyDetail = (data) => {
  return {
    type: SET_COMPANY_DETAILS,
    data
  };
};

export const invalidateCompanyBgvConfig = () => {
  return {
    type: INVALIDATE_COMPANY_BGV_CONFIG
  };
};

export const requestCompanyBgvConfig = () => {
  return {
    type: REQUEST_COMPANY_BGV_CONFIG
  };
};

export const setCompanyBgvConfig = (data) => {
  return {
    type: SET_COMPANY_BGV_CONFIG,
    data
  };
};

export const invalidateRefQuestions = () => {
  return {
    type: INVALIDATE_REFQUESTIONS
  };
};

export const requestRefQuestions = () => {
  return {
    type: REQUEST_REFQUESTIONS
  };
};

export const setRefQuestions = (index, data) => {
  return {
    type: SET_REFQUESTIONS,
    index,
    data
  };
};

export const invalidateCompanyCategories = () => {
  return {
    type: INVALIDATE_COMPANY_CATEGORIES
  };
};

export const requestCompanyCategories = () => {
  return {
    type: REQUEST_COMPANY_CATEGORIES
  };
};

export const setCompanyCategories = (index, data) => {
  return {
    type: SET_COMPANY_CATEGORIES,
    index,
    data
  };
};

export const invalidateCompanyLogs = () => {
  return {
    type: INVALIDATE_COMPANYLOGS
  };
};

export const requestCompanyLogs = () => {
  return {
    type: REQUEST_COMPANYLOGS
  };
};

export const setCompanyLogs = (index, data) => {
  return {
    type: SET_COMPANYLOGS,
    index,
    data
  };
};

export const setServices = (data) => {
  return {
    type: SET_SERVICES,
    data
  };
};
export const setCompanyAdmin = (data) => {
  return {
    type: SET_COMPANY_ADMIN,
    data
  };
};

export const setAssignedServices = (data) => {
  return {
    type: SET_ASSIGNED_SERVICES,
    data
  };
};

export const invalidateAssignedServices = () => {
  return {
    type: INVALIDATE_ASSIGNED_SERVICES
  };
};

export const requestAssignedServices = () => {
  return {
    type: REQUEST_ASSIGNED_SERVICES
  };
};

export const setCompanySettings = (data) => {
  return {
    type: SET_COMPANY_SETTINGS,
    data
  };
};

export const invalidateCompanySettings = () => {
  return {
    type: INVALIDATE_COMPANY_SETTINGS
  };
};

export const requestCompanySettings = () => {
  return {
    type: REQUEST_COMPANY_SETTINGS
  };
};

export const setOpsStatus = (data) => {
  return {
    type: SET_OPS_STATUS,
    data
  };
};

export const setVendors = (index, data) => {
  return {
    type: SET_VENDORS,
    index,
    data
  };
};

export const invalidateVendors = () => {
  return {
    type: INVALIDATE_VENDORS
  };
};

export const requestVendors = () => {
  return {
    type: REQUEST_VENDORS
  };
};
export const setDefaultQuestions = (index, data) => {
  return {
    type: SET_DEFAULT_QUESTIONS,
    index,
    data
  };
};

export const invalidateDefaultQuestions = () => {
  return {
    type: INVALIDATE_DEFAULT_QUESTIONS
  };
};

export const requestDefaultQuestions = () => {
  return {
    type: REQUEST_DEFAULT_QUESTIONS
  };
};

export const setAdmins = (data) => {
  return {
    type: SET_ADMINS,
    data
  };
};

export const invalidateViewCandidateDetails = () => {
  return {
    type: INVALIDATE_VIEW_CANDIDATE_DETAILS
  };
};

export const invalidateAdcList = () => {
  return {
    type: INVALIDATE_ADC_LIST
  };
};

export const requestViewCandidateDetails = () => {
  return {
    type: REQUEST_VIEW_CANDIDATE_DETAILS
  };
};

export const setViewCandidateDetails = (data) => {
  return {
    type: SET_VIEW_CANDIDATE_DETAILS,
    data
  };
};

export const requestADCList = () => {
  return {
    type: REQUEST_ADC_LIST
  };
};

export const setADCList = (data) => {
  return {
    type: SET_ADC_LIST,
    data
  };
};

export const setWarnings = (data) => {
  return {
    type: SET_WARNINGS,
    data
  };
};

export const invalidateWarnings = () => {
  return {
    type: INVALIDATE_WARNINGS
  };
};

export const requestWarnings = () => {
  return {
    type: REQUEST_WARNINGS
  };
};

export const setIdentityDataFound = (data) => {
  return {
    type: SET_IDENTITY_DATA_FOUND,
    data
  };
};

export const invalidateIdentityDataFound = () => {
  return {
    type: INVALIDATE_IDENTITY_DATA_FOUND
  };
};

export const setIdentityDataProvided = (data) => {
  return {
    type: SET_IDENTITY_DATA_PROVIDED,
    data
  };
};

export const invalidateIdentityDataProvided = () => {
  return {
    type: INVALIDATE_IDENTITY_DATA_PROVIDED
  };
};

export const setIdentityRunCheck = (data) => {
  return {
    type: SET_IDENTITY_RUN_CHECK,
    data
  };
};

export const invalidateIdentityRunCheck = () => {
  return {
    type: INVALIDATE_IDENTITY_RUN_CHECK
  };
};

export const invalidateIdentityOcr = () => {
  return {
    type: INVALIDATE_IDENTITY_OCR
  };
};

export const setIdentityOCR = (data) => {
  return {
    type: SET_IDENTITY_OCR,
    data
  };
};

export const invalidateEducationDataProvided = () => {
  return {
    type: INVALIDATE_EDUCATION_DATA_PROVIDED
  };
};

export const setEducationDataProvided = (data) => {
  return {
    type: SET_EDUCATION_DATA_PROVIDED,
    data
  };
};

export const requestEmploymentDataProvided = () => {
  return {
    type: REQUEST_EMPLOYMENT_DATA_PROVIDED
  };
};

export const invalidateEmploymentDataProvided = () => {
  return {
    type: INVALIDATE_EMPLOYMENT_DATA_PROVIDED
  };
};

export const setEmploymentDataProvided = (data) => {
  return {
    type: SET_EMPLOYMENT_DATA_PROVIDED,
    data
  };
};
export const requestEmploymentDataFound = () => {
  return {
    type: REQUEST_EMPLOYMENT_DATA_FOUND
  };
};

export const invalidateEmploymentDataFound = () => {
  return {
    type: INVALIDATE_EMPLOYMENT_DATA_FOUND
  };
};

export const setEmploymentDataFound = (data) => {
  return {
    type: SET_EMPLOYMENT_DATA_FOUND,
    data
  };
};

export const invalidateEducationDataFound = () => {
  return {
    type: INVALIDATE_EDUCATION_DATA_FOUND
  };
};

export const setEducationDataFound = (data) => {
  return {
    type: SET_EDUCATION_DATA_FOUND,
    data
  };
};

export const setDrugDataProvided = (data) => {
  return {
    type: SET_DRUG_DATA_PROVIDED,
    data
  };
};

export const invalidateDrugDataProvided = () => {
  return {
    type: INVALIDATE_DRUG_DATA_PROVIDED
  };
};

export const setDrugDataFound = (data) => {
  return {
    type: SET_DRUG_DATA_FOUND,
    data
  };
};
export const invalidateDrugDataFound = () => {
  return {
    type: INVALIDATE_DRUG_DATA_FOUND
  };
};

export const setWorldDataFound = (data) => {
  return {
    type: SET_WORLD_DATA_FOUND,
    data
  };
};

export const setWorldDataProvided = (data) => {
  return {
    type: SET_WORLD_DATA_PROVIDED,
    data
  };
};

export const invalidatetWorldDataFound = () => {
  return {
    type: INVALIDATE_WORLD_DATA_FOUND
  };
};

export const invalidatetWorldDataProvided = () => {
  return {
    type: INVALIDATE_WORLD_DATA_PROVIDED
  };
};

export const requestCibilDataProvided = () => {
  return {
    type: REQUEST_CIBIL_DATA_PROVIDED
  };
};

export const invalidateCibilDataProvided = () => {
  return {
    type: INVALIDATE_CIBIL_DATA_PROVIDED
  };
};

export const setCibilDataProvided = (data) => {
  return {
    type: SET_CIBIL_DATA_PROVIDED,
    data
  };
};
export const requestCibilDataFound = () => {
  return {
    type: REQUEST_CIBIL_DATA_FOUND
  };
};

export const invalidateCibilDataFound = () => {
  return {
    type: INVALIDATE_CIBIL_DATA_FOUND
  };
};

export const setCibilDataFound = (data) => {
  return {
    type: SET_CIBIL_DATA_FOUND,
    data
  };
};

export const invalidateReferenceDataProvided = () => {
  return {
    type: INVALIDATE_REFERENCE_DATA_PROVIDED
  };
};

export const setReferenceDataProvided = (data) => {
  return {
    type: SET_REFERENCE_DATA_PROVIDED,
    data
  };
};

export const invalidateReferenceDataFound = () => {
  return {
    type: INVALIDATE_REFERENCE_DATA_FOUND
  };
};

export const setReferenceDataFound = (data) => {
  return {
    type: SET_REFERENCE_DATA_FOUND,
    data
  };
};

export const invalidateAddressDataProvided = () => {
  return {
    type: INVALIDATE_ADDRESS_DATA_PROVIDED
  };
};

export const setAddressDataProvided = (data) => {
  return {
    type: SET_ADDRESS_DATA_PROVIDED,
    data
  };
};

export const invalidateCourtDataProvided = () => {
  return {
    type: INVALIDATE_COURT_DATA_PROVIDED
  };
};

export const setCourtDataProvided = (data) => {
  return {
    type: SET_COURT_DATA_PROVIDED,
    data
  };
};

export const invalidateCourtDataFound = () => {
  return {
    type: INVALIDATE_COURT_DATA_FOUND
  };
};

export const setCourtDataFound = (data) => {
  return {
    type: SET_COURT_DATA_FOUND,
    data
  };
};

export const invalidateAddressDataFound = () => {
  return {
    type: INVALIDATE_ADDRESS_DATA_FOUND
  };
};

export const setAddressDataFound = (data) => {
  return {
    type: SET_ADDRESS_DATA_FOUND,
    data
  };
};

export const invalidateNotes = () => {
  return {
    type: INVALIDATE_NOTES
  };
};

export const setNotes = (data) => {
  return {
    type: SET_NOTES,
    data
  };
};

export const invalidateOpsStatusNotes = () => {
  return {
    type: INVALIDATE_OPS_STATUS_NOTES
  };
};

export const setOpsStatusNotes = (data) => {
  return {
    type: SET_OPS_STATUS_NOTES,
    data
  };
};

export const requestCandidateLogs = () => {
  return {
    type: REQUEST_CANDIDATE_LOGS
  };
};

export const invalidateCandidateLogs = () => {
  return {
    type: INVALIDATE_CANDIDATE_LOGS
  };
};

export const setCandidateLogs = (index, data) => {
  return {
    type: SET_CANDIDATE_LOGS,
    index,
    data
  };
};

export const requestCandidateCallLogs = () => {
  return {
    type: REQUEST_CANDIDATE_CALL_LOGS
  };
};

export const invalidateCandidateCallLogs = () => {
  return {
    type: INVALIDATE_CANDIDATE_CALL_LOGS
  };
};

export const setCandidateCallLogs = (index, data) => {
  return {
    type: SET_CANDIDATE_CALL_LOGS,
    index,
    data
  };
};

export const invalidateVendorsList = () => {
  return {
    type: INVALIDATE_VENDORS_LIST
  };
};

export const setVendorsList = (data) => {
  return {
    type: SET_VENDORS_LIST,
    data
  };
};

export const invalidateVendorsInfo = () => {
  return {
    type: INVALIDATE_VENDORS_INFO
  };
};

export const setVendorsInfo = (data) => {
  return {
    type: SET_VENDORS_INFO,
    data
  };
};

export const requestFeedback = () => {
  return {
    type: REQUEST_FEEDBACK
  };
};

export const invalidateFeedback = () => {
  return {
    type: INVALIDATE_FEEDBACK
  };
};

export const setFeedback = (index, data) => {
  return {
    type: SET_FEEDBACK,
    index,
    data
  };
};

export const invalidateProfile = () => {
  return {
    type: INVALIDATE_PROFILE
  };
};

export const requestProfile = () => {
  return {
    type: REQUEST_PROFILE
  };
};

export const setProfile = (data) => {
  return {
    type: SET_PROFILE,
    data
  };
};

export const invalidateReports = () => {
  return {
    type: INVALIDATE_REPORTS
  };
};

export const requestReports = () => {
  return {
    type: REQUEST_REPORTS
  };
};

export const setReports = (data) => {
  return {
    type: SET_REPORTS,
    data
  };
};

export const invalidateCustomCheckDataFound = () => {
  return {
    type: INVALIDATE_CUSTOM_DATA_FOUND
  };
};

export const setCustomCheckDataFound = (data) => {
  return {
    type: SET_CUSTOM_DATA_FOUND,
    data
  };
};

export const invalidateInsuffReview = () => {
  return {
    type: INVALIDATE_INSUFF_REVIEW
  };
};

export const requestInsuffReview = () => {
  return {
    type: REQUEST_INSUFF_REVIEW
  };
};

export const setInsuffReview = (index, data) => {
  return {
    type: SET_INSUFF_REVIEW,
    index,
    data
  };
};

export const invalidateCompanyEmailSettings = () => {
  return {
    type: INVALIDATE_COMPANY_EMAIL_SETTINGS
  };
};

export const requestCompanyEmailSettings = () => {
  return {
    type: REQUEST_COMPANY_EMAIL_SETTINGS
  };
};

export const setCompanyEmailSettings = (data) => {
  return {
    type: SET_COMPANY_EMAIL_SETTINGS,
    data
  };
};

export const setInsuffList = (data) => {
  return {
    type: SET_INSUFF_LIST,
    data
  };
};

export const invalidateInsuffList = () => {
  return {
    type: INVALIDATE_INSUFF_LIST
  };
};

export const requestInsuffList = () => {
  return {
    type: REQUEST_INSUFF_LIST
  };
};

export const invalidateInsuffCandidate = () => {
  return {
    type: INVALIDATE_INSUFF_CANDIDATE
  };
};

export const requestInsuffCandidate = () => {
  return {
    type: REQUEST_INSUFF_CANDIDATE
  };
};

export const setInsuffCandidate = (data) => {
  return {
    type: SET_INSUFF_CANDIDATE,
    data
  };
};

export const invalidatePackageDetails = () => {
  return {
    type: INVALIDATE_PACKAGE_DETAILS
  };
};

export const requestPackageDetails = () => {
  return {
    type: REQUEST_PACKAGE_DETAILS
  };
};

export const setPackageDetails = (data) => {
  return {
    type: SET_PACKAGE_DETAILS,
    data
  };
};

export const invalidatePackageCompanyList = () => {
  return {
    type: INVALIDATE_PACKAGE_COMPANY_LIST
  };
};

export const requestPackageCompanyList = () => {
  return {
    type: REQUEST_PACKAGE_COMPANY_LIST
  };
};

export const setPackageCompanyList = (index, data) => {
  return {
    type: SET_PACKAGE_COMPANY_LIST,
    index,
    data
  };
};

export const invalidatePackageDetailsAuditLogs = () => {
  return {
    type: INVALIDATE_PACKAGE_DETAILS_AUDIT_LOGS
  };
};

export const requestPackageDetailsAuditLogs = () => {
  return {
    type: REQUEST_PACKAGE_DETAILS_AUDIT_LOGS
  };
};

export const setPackageDetailsAuditLogs = (index, data) => {
  return {
    type: SET_PACKAGE_DETAILS_AUDIT_LOGS,
    index,
    data
  };
};

export const invalidatePackageList = () => {
  return {
    type: INVALIDATE_PACKAGE_LIST
  };
};

export const requestPackageList = () => {
  return {
    type: REQUEST_PACKAGE_LIST
  };
};

export const setPackageList = (index, data) => {
  return {
    type: SET_PACKAGE_LIST,
    index,
    data
  };
};

export const setCreditBalance = (data) => {
  return {
    type: SET_CREDIT_BALANCE,
    data
  };
};
export const requestCompanyCreditBalance = () => {
  return {
    type: REQUEST_COMPANY_CREDIT_BALANCE
  };
};
export const setCompanyCreditBalance = (data) => {
  return {
    type: SET_COMPANY_CREDIT_BALANCE,
    data
  };
};
export const requestCompanyPendingOrders = () => {
  return {
    type: REQUEST_COMPANY_PENDING_ORDERS
  };
};
export const setCompanyPendingOrders = (data) => {
  return {
    type: SET_COMPANY_PENDING_ORDERS,
    data
  };
};

export const invalidateCompanyPendingOrder = () => {
  return {
    type: INVALIDATE_COMPANY_PENDING_ORDERS
  };
};
export const invalidateCreditBalance = () => {
  return {
    type: INVALIDATE_COMPANY_CREDIT_BALANCE
  };
};

export const setCompanyRechargeLog = (data) => {
  return {
    type: SET_COMPANY_RECHARGE_LOG,
    data
  };
};

export const setCompanyUsageLog = (data) => {
  return {
    type: SET_COMPANY_USAGE_LOG,
    data
  };
};
export const setPrepaidUsageLogs = (data) => {
  return {
    type: SET_PREPAID_USAGE_LOG,
    data
  };
};

export const requestOverallPaymentLogs = () => {
  return {
    type: REQUEST_OVERALL_PAYMENT_LOGS
  };
};

export const invalidateOverallPaymentLogs = () => {
  return {
    type: INVALIDATE_OVERALL_PAYMENT_LOGS
  };
};
export const setOverallPaymentLogs = (data) => {
  return {
    type: SET_OVERALL_PAYMENT_LOGS,
    data
  };
};

export const setPDRechargeLog = (data) => {
  return {
    type: SET_PD_RECHARGE_LOG,
    data
  };
};

export const invalidatePDRechargeLog = () => {
  return {
    type: INVALIDATE_PD_RECHARGE_LOG
  };
};

export const setPDUsageLog = (data) => {
  return {
    type: SET_PD_USAGE_LOG,
    data
  };
};

export const invalidatePDUsageLog = () => {
  return {
    type: INVALIDATE_PD_USAGE_LOG
  };
};

export const setPDBasicInfo = (data) => {
  return {
    type: SET_PD_BASIC_INFO,
    data
  };
};

export const invalidatePDBasicInfo = () => {
  return {
    type: INVALIDATE_PD_BASIC_INFO
  };
};

export const setPDRechargeDetails = (data) => {
  return {
    type: SET_PD_RECHARGE_DETAILS,
    data
  };
};

export const invalidatePDRechargeDetails = () => {
  return {
    type: INVALIDATE_PD_RECHARGE_DETAILS
  };
};

export const setPDPackageConfig = (data) => {
  return {
    type: SET_PD_PACKAGE_CONFIG,
    data
  };
};

export const invalidatePDPackageConfig = () => {
  return {
    type: INVALIDATE_PD_PACKAGE_CONFIG
  };
};

export const requestReviewAdditionalList = () => {
  return {
    type: REQUEST_REVIEW_ADDITIONAL_CHARGES
  };
};

export const setReviewAdditionalList = (data) => {
  return {
    type: SET_REVIEW_ADDITIONAL_CHARGES,
    data
  };
};

export const invalidateReviewAdditionalList = () => {
  return {
    type: INVALIDATE_REVIEW_ADDITIONAL_CHARGES
  };
};

export const invalidateInsuffFundsForChecks = () => {
  return {
    type: INVALIDATE_INSUFF_FUNDS_FOR_CHECKS
  };
};

export const requestInsuffFundsForChecks = () => {
  return {
    type: REQUEST_INSUFF_FUNDS_FOR_CHECKS
  };
};

export const setInsuffFundsForChecks = (data) => {
  return {
    type: SET_INSUFF_FUNDS_FOR_CHECKS,
    data
  };
};

export const invalidateAddCandidateConfig = () => {
  return {
    type: INVALIDATE_ADD_CANDIDATE_CONFIG
  };
};

export const requestAddCandidateConfig = () => {
  return {
    type: REQUEST_ADD_CANDIDATE_CONFIG
  };
};

export const setAddCandidateConfig = (data) => {
  return {
    type: SET_ADD_CANDIDATE_CONFIG,
    data
  };
};

export const setGlobalAddonConfiguration = (data) => {
  return {
    type: SET_GLOBAL_ADDON_CONFIGURATION,
    data
  };
};
export const invalidateGlobalAddonConfiguration = (data) => {
  return {
    type: INVALIDATE_GLOBAL_ADDON_CONFIGURATION,
    data
  };
};
export const requestGlobalAddonConfiguration = (data) => {
  return {
    type: REQUEST_GLOBAL_ADDON_CONFIGURATION,
    data
  };
};

export const invalidateVerificationOrderSummary = () => {
  return {
    type: INVALIDATE_VERIFICATION_ORDER_SUMMARY
  };
};

export const requestVerificationOrderSummary = () => {
  return {
    type: REQUEST_VERIFICATION_ORDER_SUMMARY
  };
};

export const setVerificationOrderSummary = (data) => {
  return {
    type: SET_VERIFICATION_ORDER_SUMMARY,
    data
  };
};

export const setCompanyAddonConfiguration = (data) => {
  return {
    type: SET_COMPANY_ADDON_CONFIGURATION,
    data
  };
};

export const invalidateCompanyAddonConfiguration = (data) => {
  return {
    type: INVALIDATE_COMPANY_ADDON_CONFIGURATION,
    data
  };
};
export const requestCompanyAddonConfiguration = (data) => {
  return {
    type: REQUEST_COMPANY_ADDON_CONFIGURATION,
    data
  };
};

export const setSearchPackage = (data) => {
  return {
    type: SET_SEARCH_PACKAGE,
    data
  };
};
export const invalidateSearchPAckage = () => {
  return {
    type: INVALIDATE_SEARCH_PACKAGE
  };
};

export const invalidateSkippedCheck = () => {
  return {
    type: INVALIDATE_SKIPPED_CHECK
  };
};
export const requestSkippedCheck = () => {
  return {
    type: REQUEST_SKIPPED_CHECK
  };
};
export const setSkippedCheck = (data) => {
  return {
    type: SET_SKIPPED_CHECK,
    data
  };
};

export const invalidateCompanyAuditLogs = () => {
  return {
    type: INVALIDATE_COMPANY_AUDIT_LOGS
  };
};

export const requestCompanyAuditLogs = () => {
  return {
    type: REQUEST_COMPANY_AUDIT_LOGS
  };
};

export const setCompanyAuditLogs = (index, data) => {
  return {
    type: SET_COMPANY_AUDIT_LOGS,
    index,
    data
  };
};

export const invalidatePostpaidCreditCompanies = () => {
  return {
    type: INVALIDATE_CREDIT_POSTPAID_COMPANIES
  };
};

export const requestPostpaidCreditCompanies = () => {
  return {
    type: REQUEST_CREDIT_POSTPAID_COMPANIES
  };
};

export const setPostpaidCreditCompanies = (index, data) => {
  return {
    type: SET_CREDIT_POSTPAID_COMPANIES,
    index,
    data
  };
};

export const invalidatePostpaidPackageDetailsMonthlyUsage = () => {
  return {
    type: INVALIDATE_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE
  };
};

export const requestPostpaidPackageDetailsMonthlyUsage = () => {
  return {
    type: REQUEST_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE
  };
};

export const setPostpaidPackageDetailsMonthlyUsage = (data) => {
  return {
    type: SET_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE,
    data
  };
};

export const invalidatePostpaidPackageDetailsUsageLog = () => {
  return {
    type: INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_LOG
  };
};

export const requestPostpaidPackageDetailsUsageLog = () => {
  return {
    type: REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_LOG
  };
};

export const setPostpaidPackageDetailsUsageLog = (data) => {
  return {
    type: SET_POSTPAID_PACKAGE_DETAILS_USAGE_LOG,
    data
  };
};

export const invalidatePostpaidPackageDetailsBasicInfo = () => {
  return {
    type: INVALIDATE_POSTPAID_PACKAGE_DETAILS_BASIC_INFO
  };
};

export const requestPostpaidPackageDetailsBasicInfo = () => {
  return {
    type: REQUEST_POSTPAID_PACKAGE_DETAILS_BASIC_INFO
  };
};

export const setPostpaidPackageDetailsBasicInfo = (data) => {
  return {
    type: SET_POSTPAID_PACKAGE_DETAILS_BASIC_INFO,
    data
  };
};

export const invalidatePostpaidCreditCompany = () => {
  return {
    type: INVALIDATE_CREDIT_POSTPAID_COMPANY
  };
};

export const requestPostpaidCreditCompany = () => {
  return {
    type: REQUEST_CREDIT_POSTPAID_COMPANY
  };
};

export const setPostpaidCreditCompany = (data) => {
  return {
    type: SET_CREDIT_POSTPAID_COMPANY,
    data
  };
};

export const invalidatePostpaidPackageDetailsUsageConfig = () => {
  return {
    type: INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG
  };
};

export const requestPostpaidPackageDetailsUsageConfig = () => {
  return {
    type: REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG
  };
};

export const setPostpaidPackageDetailsUsageConfig = (data) => {
  return {
    type: SET_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG,
    data
  };
};

export const invalidatePostpaidCreditDetailsMonthlyUsage = () => {
  return {
    type: INVALIDATE_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE
  };
};

export const requestPostpaidCreditDetailsMonthlyUsage = () => {
  return {
    type: REQUEST_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE
  };
};

export const setPostpaidCreditDetailsMonthlyUsage = (data) => {
  return {
    type: SET_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE,
    data
  };
};

export const invalidatePostpaidCreditDetailsUsageLog = () => {
  return {
    type: INVALIDATE_POSTPAID_CREDITS_DETAILS_USAGE_LOG
  };
};

export const requestPostpaidCreditDetailsUsageLog = () => {
  return {
    type: REQUEST_POSTPAID_CREDITS_DETAILS_USAGE_LOG
  };
};

export const setPostpaidCreditDetailsUsageLog = (data) => {
  return {
    type: SET_POSTPAID_CREDITS_DETAILS_USAGE_LOG,
    data
  };
};

export const invalidatePostpaidCreditDetailsAuditLog = () => {
  return {
    type: INVALIDATE_POSTPAID_CREDITS_DETAILS_AUDIT_LOG
  };
};

export const requestPostpaidCreditDetailsAuditLog = () => {
  return {
    type: REQUEST_POSTPAID_CREDITS_DETAILS_AUDIT_LOG
  };
};

export const setPostpaidCreditDetailsAuditLog = (data) => {
  return {
    type: SET_POSTPAID_CREDITS_DETAILS_AUDIT_LOG,
    data
  };
};

export const invalidatePostpaidUnbilledCandidatesList = () => {
  return {
    type: INVALIDATE_POSTPAID_UNBILLED_CANDIDATES
  };
};

export const requestPostpaidUnbilledCandidatesList = () => {
  return {
    type: REQUEST_POSTPAID_UNBILLED_CANDIDATES
  };
};

export const setPostpaidUnbilledCandidatesList = (index, data) => {
  return {
    type: SET_POSTPAID_UNBILLED_CANDIDATES,
    index,
    data
  };
};

export const invalidatePostpaidUnbilledCandidatesListFilter = () => {
  return {
    type: INVALIDATE_POSTPAID_UNBILLED_CANDIDATES_FILTERS
  };
};

export const requestPostpaidUnbilledCandidatesListFilter = () => {
  return {
    type: REQUEST_POSTPAID_UNBILLED_CANDIDATES_FILTERS
  };
};

export const setPostpaidUnbilledCandidatesListFilter = (data) => {
  return {
    type: SET_POSTPAID_UNBILLED_CANDIDATES_FILTERS,
    data
  };
};

export const invalidatePostpaidCompanyInvoicesList = () => {
  return {
    type: INVALIDATE_POSTPAID_COMPANY_INVOICES_LIST
  };
};

export const requestPostpaidCompanyInvoicesList = () => {
  return {
    type: REQUEST_POSTPAID_COMPANY_INVOICES_LIST
  };
};

export const setPostpaidCompanyInvoicesList = (index, data) => {
  return {
    type: SET_POSTPAID_COMPANY_INVOICES_LIST,
    index,
    data
  };
};

export const invalidatePostpaidCompanyInvoicesListFilter = () => {
  return {
    type: INVALIDATE_POSTPAID_COMPANY_INVOICES_FILTERS
  };
};

export const requestPostpaidCompanyInvoicesListFilter = () => {
  return {
    type: REQUEST_POSTPAID_COMPANY_INVOICES_FILTERS
  };
};

export const setPostpaidCompanyInvoicesListFilter = (data) => {
  return {
    type: SET_POSTPAID_COMPANY_INVOICES_FILTERS,
    data
  };
};

export const requestBillingTags = () => {
  return {
    type: REQUEST_BILLING_TAGS
  };
};

export const setBillingTags = (data) => {
  return {
    type: SET_BILLING_TAGS,
    data
  };
};
export const requestBillingLogs = () => {
  return {
    type: REQUEST_BILLING_LOGS
  };
};

export const setBillingLogs = (index, data) => {
  return {
    type: SET_BILLING_LOGS,
    index,
    data
  };
};
export const setAdminStatus = (data) => {
  return {
    type: UPDATE_ADMIN_STATUS,
    data
  };
};
export const requestRechargeLogs = () => {
  return {
    type: REQUEST_RECHARGE_LOGS
  };
};

export const invalidateLinkedCandidates = () => {
  return {
    type: INVALIDATE_LINKED_CANDIDATES
  };
};

export const requestLinkedCandidates = () => {
  return {
    type: REQUEST_LINKED_CANDIDATES
  };
};

export const setLinkedCandidates = (data) => {
  return {
    type: SET_LINKED_CANDIDATES,
    data
  };
};

export const requestUniqueRecords = () => {
  return {
    type: REQUEST_UNIQUE_RECORDS
  };
};

export const invalidateUniqueRecords = () => {
  return {
    type: INVALIDATE_UNIQUE_RECORDS
  };
};

export const setUniqueRecords = (data) => {
  return {
    type: SET_UNIQUE_RECORDS,
    data
  };
};

export const getCandidates =
  (limit, offset, includeVerifications, name, email, companyId, status, category, id) =>
  (dispatch) => {
    dispatch(invalidateCandidates());
    dispatch(requestCandidates());
    return getCandidatesAPI(
      limit,
      offset,
      includeVerifications,
      name,
      email,
      companyId,
      status,
      category,
      id
    )
      .then((response) => {
        const candidates = toCamelCase(response.data.data);
        dispatch(setCandidates(offset, candidates));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const getCompanyServices = (companyId) => (dispatch) => {
  requestCompanyServices();
  return getCompanyServicesAPI(companyId)
    .then((response) => {
      let services = toCamelCase(response.data).data;
      dispatch(updateCompanyServices(services));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const getViewCandidate = (candidateId, companyId) => (dispatch) => {
  dispatch(requestViewCandidates());
  return getViewCandidateAPI(candidateId, companyId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setViewCandidates(data));
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const createCandidate = (data) => (dispatch) => {
  return createCandidateAPI(data);
};

export const editCandidate = (data) => (dispatch) => {
  return editCandidateAPI(data);
};

export const getCompanies =
  (limit, offset, status, search, company_type, trial_account) => (dispatch) => {
    return getCompaniesAPI(limit, offset, status, search, company_type, trial_account)
      .then((response) => {
        const companies = toCamelCase(response.data.data);
        dispatch(setCompanies(companies));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const globalSearch = (payload) => (dispatch) => {
  dispatch(requestGlobalSearch());
  return globalSearchAPI(payload)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      const addedFieldType = {
        search: { ...data },
        field: payload.field,
        candidateQuery: payload.field
      };
      dispatch(setGlobalSearch(addedFieldType));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getInsufficiency =
  (
    limit,
    offset,
    companyId,
    status,
    candidateId,
    candidateName,
    companyName,
    checkType,
    orderAccordingToDays
  ) =>
  (dispatch) => {
    return getInsufficiencyAPI(
      limit,
      offset,
      companyId,
      status,
      candidateId,
      candidateName,
      companyName,
      checkType,
      orderAccordingToDays
    )
      .then((response) => {
        const insuff = toCamelCase(response.data.data);
        dispatch(setInsufficiency(insuff));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const getCompanyById = (companyId) => (dispatch) => {
  return getCompanyByIdAPI(companyId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCompanyDetail(data));
    })
    .catch((error) => {
      dispatch(invalidateCompanyDetail());
      errToastMessage(error);
    });
};

export const getCompanyBgvConfig = (companyId) => (dispatch) => {
  dispatch(requestCompanyBgvConfig());
  return getCompanyBgvConfigAPI(companyId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCompanyBgvConfig(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getRefQuestions = (companyUuid, limit, offset, status) => (dispatch) => {
  dispatch(requestRefQuestions());
  return getRefQuestionsAPI(companyUuid, limit, offset, status)
    .then((response) => {
      const questions = toCamelCase(response.data.data);
      dispatch(setRefQuestions(offset, questions));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCompanyCategories = (companyId, limit, offset, name) => (dispatch) => {
  dispatch(requestCompanyCategories());
  return getCompanyCategoriesAPI(companyId, limit, offset, name)
    .then((response) => {
      const categories = toCamelCase(response.data.data);
      dispatch(setCompanyCategories(offset, categories));
      return categories;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCompanyLogs = (companyUuid, limit, offset, tableName) => (dispatch) => {
  dispatch(requestCompanyLogs());
  return getCompanyLogsAPI(companyUuid, limit, offset, tableName)
    .then((response) => {
      const logs = toCamelCase(response.data);
      dispatch(setCompanyLogs(offset, logs));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getServices = (limit, offset, orderId, id, price, name, status) => (dispatch) => {
  return getServicesAPI(limit, offset, orderId, id, price, name, status)
    .then((response) => {
      const services = toCamelCase(response.data);
      dispatch(setServices(services));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCompanyAdmin = (companyId, limit, offset) => (dispatch) => {
  return getCompanyAdminAPI(companyId, limit, offset)
    .then((response) => {
      const companyAdmin = toCamelCase(response.data.data);
      dispatch(setCompanyAdmin(companyAdmin));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getAssignedServices = (companyId) => (dispatch) => {
  dispatch(requestAssignedServices());
  return getCompanyAssignedServicesAPI(companyId)
    .then((response) => {
      const assignedServices = toCamelCase(response.data.data);
      dispatch(setAssignedServices(assignedServices));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCompanySettingsById = (companyId) => (dispatch) => {
  dispatch(requestCompanySettings());
  return getCompanySettingsAPI(companyId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCompanySettings(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const editCompanySettings = (data) => (dispatch) => {
  return editCompanySettingsAPI(data)
    .then((response) => {})
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getOpsStatus = (limit, offset, name, status) => (dispatch) => {
  return getOpsStatusAPI(limit, offset, name, status)
    .then((response) => {
      const opsStatus = toCamelCase(response.data.data);
      dispatch(setOpsStatus(opsStatus));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getVendors = (limit, offset, status) => (dispatch) => {
  dispatch(requestVendors());
  return getVendorsAPI(limit, offset, status)
    .then((response) => {
      const vendors = toCamelCase(response.data.data);
      dispatch(setVendors(offset, vendors));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getDefaultQuestions = (limit, offset, status) => (dispatch) => {
  dispatch(requestDefaultQuestions());
  return getDefaultQuestionsAPI(limit, offset, status)
    .then((response) => {
      const questions = toCamelCase(response.data.data);
      dispatch(setDefaultQuestions(offset, questions));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getAdmins =
  (limit, offset, includeCompanyAdmins, name, email, accessLevel, status) => (dispatch) => {
    return getAdminsAPI(limit, offset, includeCompanyAdmins, name, email, accessLevel, status)
      .then((response) => {
        const admins = toCamelCase(response.data.data);
        dispatch(setAdmins(admins));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const getADCList = (checkId, checkType, limit, offset) => (dispatch) => {
  dispatch(requestADCList());
  return getADCListAPI(checkId, checkType, limit, offset)
    .then((response) => {
      const data = toCamelCase(response.data);
      dispatch(setADCList(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getViewCandidateDetails = (companyId, candidateId) => (dispatch) => {
  dispatch(requestViewCandidateDetails());
  return getViewCandidateDetailsAPI(companyId, candidateId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setViewCandidateDetails(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getWarnings = (companyId, candidateId) => (dispatch) => {
  dispatch(requestWarnings());
  return getWarningsAPI(companyId, candidateId)
    .then((response) => {
      const warnings = toCamelCase(response.data.data);
      dispatch(setWarnings(warnings));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//identitty get data found
export const getIdentityDataFound = (id, candidateId) => (dispatch) => {
  return getIdenityDataFoundAPI(id, candidateId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setIdentityDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//identitty get data provided
export const getIdentityDataProvided = (id) => (dispatch) => {
  return getIdentityDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setIdentityDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//education get data provided
export const getEducationDataProvided = (id) => (dispatch) => {
  return getEducationDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setEducationDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//education get data found
export const getEducationDataFound = (id) => (dispatch) => {
  return getEducationDataFoundAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setEducationDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

/**
 * Get Employment data provided
 * @param {int/string} id
 */
export const getEmploymentDataProvided = (id) => (dispatch) => {
  dispatch(requestEmploymentDataProvided());
  return getEmploymentDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setEmploymentDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getEmploymentDataProvided

/**
 * Get Employment data found
 * @param {int/string} id
 */
export const getEmploymentDataFound = (id) => (dispatch) => {
  dispatch(requestEmploymentDataFound());
  return getEmploymentDataFoundAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setEmploymentDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getEmploymentDataFound

//Drugs Data Provided
export const getDrugDataProvided = (id, candidateId) => (dispatch) => {
  return getDrugDataProvidedAPI(id, candidateId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setDrugDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getDrugDataProvided

//Drugs Data Found
export const getDrugDataFound = (id, candidateId) => (dispatch) => {
  return getDrugDataFoundAPI(id, candidateId)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setDrugDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getDrugDatafound

//World get Data => for data found send is_verified as 1 and DP as 0
export const getWorldData = (id, isVerified) => (dispatch) => {
  return getWorldDataAPI(id, isVerified)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      if (isVerified) {
        dispatch(setWorldDataFound(data));
      } else {
        dispatch(setWorldDataProvided(data));
      }
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getWorldData

//SET Identity RUN CHECK API
export const getIdentityRunCheck = (data) => (dispatch) => {
  return getIdentityRunCheckAPI(data).then((response) => {
    const data = toCamelCase(response.data.data);
    dispatch(setIdentityRunCheck(data));
  });
}; //End : RUN CHECK API
/**
 * Get Cibil data provided
 * @param {int/string} id
 */
export const getCibilDataProvided = (id) => (dispatch) => {
  dispatch(requestCibilDataProvided());
  return getCibilDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCibilDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getCibilDataProvided

/**
 * Get Cibil data found
 * @param {int/string} id
 */
export const getCibilDataFound = (id) => (dispatch) => {
  dispatch(requestCibilDataFound());
  return getCibilDataFoundAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCibilDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getCibilDataFound

//Reference get data provided
export const getReferenceDataProvided = (id) => (dispatch) => {
  return getReferenceDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setReferenceDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Reference get data found
export const getReferenceDataFound = (id) => (dispatch) => {
  return getReferenceDataFoundAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setReferenceDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//get address Data Provided
export const getAddressDataProvided = (id) => (dispatch) => {
  return getAddressDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setAddressDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //End : getAddressDataProvided

//Court get data provided
export const getCourtDataProvided = (id) => (dispatch) => {
  return getCourtDataProvidedAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCourtDataProvided(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//get address Data Found
export const getAddressDataFound = (id) => (dispatch) => {
  return getAddressDataFoundAPI(id)
    .then((response) => {
      const data = toCamelCase(response?.data?.data);
      dispatch(setAddressDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Reference get data found
export const getCourtDataFound = (id) => (dispatch) => {
  return getCourtDataFoundAPI(id)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCourtDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Notes Get
export const getNotes = (request) => (dispatch) => {
  return getNotesAPI(request)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setNotes(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Ops Status Notes Get
export const getOpsStatusNotes = (request) => (dispatch) => {
  return getOpsStatusNotesAPI(request)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setOpsStatusNotes(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCandidateLogs = (candidateId, limit, offset, filter) => (dispatch) => {
  dispatch(requestCandidateLogs());
  return getCandidateLogsAPI(candidateId, limit, offset, filter)
    .then((response) => {
      const logs = toCamelCase(response.data);
      dispatch(setCandidateLogs(offset, logs));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end func : getCandidateLogs

export const getCandidateCallLogs =
  (candidateId, limit, offset, rights, callStatus, userId) => (dispatch) => {
    dispatch(requestCandidateCallLogs());
    return getCandidateCallLogsAPI(candidateId, limit, offset, rights, callStatus, userId)
      .then((response) => {
        const logs = toCamelCase(response.data.data);
        dispatch(setCandidateCallLogs(offset, logs));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  }; // end func : getCandidateLogs

//Get Vendors Dropdown List
export const getVendorsList = (candidateId, checkUuid) => (dispatch) => {
  return getVendorsListAPI(candidateId, checkUuid)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setVendorsList(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Get Vendors Info
export const getVendorsInfo = (candidateId, checkUuid) => (dispatch) => {
  return getVendorsInfoAPI(candidateId, checkUuid)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setVendorsInfo(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Ocr for identity check
export const getOcr = (data) => (dispatch) => {
  return getIdentityOcrCheckAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setIdentityOCR(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getFeedback =
  (limit, offset, companyId = null) =>
  (dispatch) => {
    dispatch(requestFeedback());
    return getFeedbackAPI(limit, offset, companyId)
      .then((response) => {
        const feedback = toCamelCase(response.data.data);
        dispatch(setFeedback(offset, feedback));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

// Function to login on node only will use later when fully ported to node
export const loginTemp = (data) => (dispatch) => {
  dispatch(invalidateProfile());
  dispatch(requestProfile());
  return loginAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      if (data.token) {
        dispatch(setProfile(data));
        setAuthToken(data.token, 30);
        setCookieValue('isExpirySet', true);
      }
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const googleLogin = (data) => (dispatch) => {
  dispatch(invalidateProfile());
  const googleLoginData = toCamelCase(data);
  if (googleLoginData.token) {
    dispatch(setProfile(googleLoginData));
    setAuthToken(googleLoginData.token, 30);
    setCookieValue('isExpirySet', true);
  }
};

// Function for login in node, php both
export const login = (data) => (dispatch) => {
  dispatch(requestProfile());
  loginAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      loginViaJWTAPI(data.token)
        .then((response) => {
          dispatch(setProfile(data));
          window.open(`${AppConstants.baseURL}dashboard`, '_self');
        })
        .catch((error) => {
          console.error(error);
          errToastMessage('Login failed!, Invalid credentials');
        });
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getReports = (data) => (dispatch) => {
  dispatch(requestReports());
  return getReportsAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setReports(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const getReportsExternal = (data) => (dispatch) => {
  dispatch(requestReports());
  return getReportsExternalAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data);
      dispatch(setReports(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
//custom check get data found
export const getCustomCheckDataFound = (uuid) => (dispatch) => {
  return getCustomCheckDFAPI(uuid)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCustomCheckDataFound(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Handle insuff review
export const getInsuffReview = (data) => (dispatch) => {
  dispatch(requestInsuffReview());
  return getInsuffReviewAPI(data)
    .then((response) => {
      const formattedData = toCamelCase(response.data);
      dispatch(setInsuffReview(data.offset, formattedData));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getCompanyEmailSettings = (data) => (dispatch) => {
  dispatch(requestCompanyEmailSettings());
  return getCompanyEmailSettingsAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setCompanyEmailSettings(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Handle insuff ticket list
export const getInsuffTicketList = (data) => (dispatch) => {
  dispatch(requestInsuffList());
  return getInsuffTicketListAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data);
      dispatch(setInsuffList(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

/**
 * Get Insufficiency for candidate
 * @param   {object} uuid candidate uuid
 * @return  {promise}
 */
export const getInsufficiencyForCandidate = (uuid) => (dispatch) => {
  dispatch(requestInsuffCandidate());
  return getInsufficiencyForCandidateAPI(uuid)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setInsuffCandidate(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end of func : getInsufficiencyForCandidate

/**
 * Get Package details
 * @param   {packageId}
 * @return  {promise}
 */
export const getPackageDetails = (packageId) => (dispatch) => {
  dispatch(requestPackageDetails());
  return getPackageDetailsAPI(packageId)
    .then((response) => {
      const data = toCamelCase(response.data);
      dispatch(setPackageDetails(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end of func : getPackageDetails

// Get company list assigned to particular package
export const getPackageCompanyList = (data) => (dispatch) => {
  dispatch(requestPackageCompanyList());

  return getPackageCompanyListAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPackageCompanyList(data.offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end of func : getPackageCompanyList

// Get company list assigned to particular package
export const getPackageDetailsAuditLogs = (data) => (dispatch) => {
  dispatch(requestPackageDetailsAuditLogs());
  return getPackageDetailsAuditLogsAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPackageDetailsAuditLogs(data.offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end of func : getPackageDetailsAuditLogs

// Get package list
export const getPackageList =
  (limit, offset, packageName, company, status, packageType, companyId) => (dispatch) => {
    dispatch(requestPackageList());

    return getPackageListAPI(limit, offset, packageName, company, status, packageType, companyId)
      .then((response) => {
        const resp = toCamelCase(response.data.data);
        dispatch(setPackageList(offset, resp));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

// Get Credit Balance
export const getCreditBalance = (query) => (dispatch) => {
  return getCreditBalanceAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setCreditBalance(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Get Company Credit Balance
export const getCompanyCreditBalance = (query) => (dispatch) => {
  dispatch(requestCompanyCreditBalance());
  return getCreditBalanceAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setCompanyCreditBalance(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const getCompanyPendingOrders = (data, onlyCount) => (dispatch) => {
  dispatch(requestCompanyPendingOrders());
  return getCompanyPendingOrdersAPI(data)
    .then((response) => {
      const resp = toCamelCase(response.data.data);
      if (onlyCount) {
        resp['paymentOrders'] = [];
      }
      dispatch(setCompanyPendingOrders(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Get Company Recharge Log for Credit Balance
export const getCompanyRechargeLogs = (query) => (dispatch) => {
  return getCompanyRechargeLogsAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setCompanyRechargeLog(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Get Company Usage Log for Credit Balance
export const getCompanyUsageLogs =
  (
    companyId,
    limit,
    offset,
    event,
    from_date,
    to_date,
    candidate_name,
    user_name,
    invoice_number,
    credit_type,
    user_id
  ) =>
  (dispatch) => {
    return getCompanyUsageLogsAPI(
      companyId,
      limit,
      offset,
      event,
      from_date,
      to_date,
      candidate_name,
      user_name,
      invoice_number,
      credit_type,
      user_id
    )
      .then((response) => {
        dispatch(setCompanyUsageLog(response.data));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

// get prepaid usage logs
export const getPrepaidUsageLogs =
  (
    companyId,
    limit,
    offset,
    event,
    from_date,
    to_date,
    candidate_name,
    user_name,
    invoice_number,
    credit_type,
    user_id
  ) =>
  (dispatch) => {
    return getCompanyUsageLogsAPI(
      companyId,
      limit,
      offset,
      event,
      from_date,
      to_date,
      candidate_name,
      user_name,
      invoice_number,
      credit_type,
      user_id
    )
      .then((response) => {
        dispatch(setPrepaidUsageLogs(response.data));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

// Get Company Packages
export const getCompanyPackages = (companyId) => (dispatch) => {
  return getCompanyPackagesAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Get Overall Package / Credits Logs
export const getOverallPaymentLogs = (limit, offset, packageType) => (dispatch) => {
  dispatch(requestOverallPaymentLogs());
  return getOverallPaymentLogsAPI(limit, offset, packageType)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setOverallPaymentLogs(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Get PackageDetails Recharge Log
export const getPackageDetailsRechargeLogs = (query) => (dispatch) => {
  dispatch(requestRechargeLogs());
  return getPDRechargeLogsAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPDRechargeLog(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Get PackageDetails Usage Log
export const getPackageDetailsUsageLogs = (query) => (dispatch) => {
  return getPDUsageLogsAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPDUsageLog(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //end

// Get Review additional charges list
export const getReviewAdditionalChargesList = (query) => (dispatch) => {
  dispatch(requestReviewAdditionalList());
  return getReviewAdditionalChargesAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setReviewAdditionalList(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; //end

//Get Insuff Funds For Checks
export const getInsuffFundsForChecks =
  (limit, offset, status, candidateName, companyName) => (dispatch) => {
    dispatch(requestInsuffFundsForChecks());
    return getInsuffFundsForChecksAPI(limit, offset, status, candidateName, companyName)
      .then((response) => {
        const resp = toCamelCase(response.data);
        dispatch(setInsuffFundsForChecks(resp));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

/**
 * Get Add candidate config
 * @param   {packageId}
 * @return  {promise}
 */
export const getAddCandidateConfig =
  (companyId = null) =>
  (dispatch) => {
    dispatch(requestAddCandidateConfig());
    return getAddCandidateConfigAPI(companyId)
      .then((response) => {
        const data = toCamelCase(response.data);
        dispatch(setAddCandidateConfig(data));
        return data;
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };
// end of func : getAddCandidateConfig

//Get package details Basic info
export const getPDBasicInfo = (companyId, packageID) => (dispatch) => {
  return getPDBasicInfoAPI(companyId, packageID)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPDBasicInfo(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Get Global Configuration
export const getGlobalAddonConfiguration = (modelType) => (dispatch) => {
  return getGlobalAddonConfigurationAPI(modelType)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setGlobalAddonConfiguration({ ...resp, ...{ packageType: modelType } }));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end
//Get package details Recharge details
export const getPDRechargeDetails = (companyId, packageID) => (dispatch) => {
  return getPDRechargeDetailsAPI(companyId, packageID)
    .then((response) => {
      const resp = toCamelCase(response.data.data);
      dispatch(setPDRechargeDetails(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end

//Get package details Package Config
export const getPDPackageConfiguration = (companyId, packageID) => (dispatch) => {
  return getPDPackageconfigurationAPI(companyId, packageID)
    .then((response) => {
      const resp = snakeToCamel(JSON.parse(JSON.stringify(response.data)));
      dispatch(setPDPackageConfig(resp));
    })
    .catch((error) => {
      errToastMessage(error);
    });
}; // end package config

//Get Verification order summary for Candidate
export const getVerificationOrderSummary = (companyId, candidateId) => (dispatch) => {
  dispatch(requestVerificationOrderSummary());

  return getVerificationOrderSummaryAPI(companyId, candidateId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setVerificationOrderSummary(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

//Get Company Addons configuration
export const getCompanyAddonConfiguration = (companyId) => (dispatch) => {
  dispatch(requestCompanyAddonConfiguration());
  return getCompanyAddonConfigurationAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setCompanyAddonConfiguration(resp));
      return resp;
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end

//Get info of check skipped by candidate
export const getSkippedCheck = (companyId, candidateId) => (dispatch) => {
  dispatch(requestSkippedCheck());

  return getSkippedCheckAPI(companyId, candidateId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setSkippedCheck(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
}; // end get Skipped check

/**
 * Get company package audit logs
 * @param   {companyId, packageID}
 * @return  {promise}
 */
export const getCompanyAuditLogs = (companyID, packageID, limit, offset) => (dispatch) => {
  dispatch(requestCompanyAuditLogs());
  return getCompanyAuditLogsAPI(companyID, packageID, limit, offset)
    .then((response) => {
      const resp = toCamelCase(response.data.data);
      dispatch(setCompanyAuditLogs(offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

/**
 * Get Postpaid credit companies
 * @param   {companyID, packageID, limit, offset}
 * @return  {promise}
 */
export const getPostpaidCreditCompanies = (limit, offset, companyName) => (dispatch) => {
  dispatch(requestPostpaidCreditCompanies());
  return getPostpaidCreditCompaniesAPI(limit, offset, companyName)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidCreditCompanies(offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidPackageDetailsMonthlyUsage =
  (companyId, packageId, limit, offset) => (dispatch) => {
    dispatch(requestPostpaidPackageDetailsMonthlyUsage());
    return getPostpaidPackageDetailsMonthlyUsageAPI(companyId, packageId, limit, offset)
      .then((response) => {
        const resp = toCamelCase(response.data);
        dispatch(setPostpaidPackageDetailsMonthlyUsage(resp));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const getPostpaidPackageDetailsUsageLog = (query) => (dispatch) => {
  dispatch(requestPostpaidPackageDetailsUsageLog());
  return getPostpaidPackageDetailsUsageLogAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidPackageDetailsUsageLog(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidPackageDetailsUsageConfig = (query, companyId) => (dispatch) => {
  dispatch(requestPostpaidPackageDetailsUsageConfig());
  return getPostpaidPackageDetailsUsageConfigAPI(query, companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidPackageDetailsUsageConfig(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

/**
 * Get Postpaid credit company
 * @param   {companyID}
 * @return  {promise}
 */
export const getPostpaidCreditCompany = (companyId) => (dispatch) => {
  dispatch(requestPostpaidCreditCompany());
  return getPostpaidCreditCompanyAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidCreditCompany(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidPackageDetailsBasicInfo = (query) => (dispatch) => {
  dispatch(requestPostpaidPackageDetailsBasicInfo());
  return getPostpaidPackageDetailsBasicInfoAPI(query)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidPackageDetailsBasicInfo(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidCreditDetailsMonthlyUsage = (companyId, limit, offset) => (dispatch) => {
  dispatch(requestPostpaidCreditDetailsMonthlyUsage());
  return getPostpaidCreditDetailsMonthlyUsageAPI(companyId, limit, offset)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidCreditDetailsMonthlyUsage(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidCreditDetailsUsageLog =
  (companyId, limit, offset, month, year) => (dispatch) => {
    dispatch(requestPostpaidCreditDetailsUsageLog());
    return getPostpaidCreditDetailsUsageLogAPI(companyId, limit, offset, month, year)
      .then((response) => {
        const resp = toCamelCase(response.data);
        dispatch(setPostpaidCreditDetailsUsageLog(resp));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const getPostpaidCreditDetailsAuditLog = (companyId, limit, offset) => (dispatch) => {
  dispatch(requestPostpaidCreditDetailsAuditLog());
  return getPostpaidCreditDetailsAuditLogAPI(companyId, limit, offset)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidCreditDetailsAuditLog(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidUnbilledCandidatesList =
  (split, limit, offset, companyId, month, year) => (dispatch) => {
    dispatch(requestPostpaidUnbilledCandidatesList());
    return getPostpaidUnbilledCandidatesListAPI(split, limit, offset, companyId, month, year)
      .then((response) => {
        const resp = toCamelCase(response.data);
        dispatch(setPostpaidUnbilledCandidatesList(offset, resp));
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

export const getPostpaidUnbilledCandidatesListFilters = (companyId) => (dispatch) => {
  dispatch(requestPostpaidUnbilledCandidatesListFilter());
  return getPostpaidUnbilledCandidatesListFiltersAPI(companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidUnbilledCandidatesListFilter(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidUnbilledCandidateWithoutAPI = (offset, data) => (dispatch) => {
  dispatch(setPostpaidUnbilledCandidatesList(offset, data));
};

export const getPostpaidCompanyInvoicesList = (limit, offset, invoiceId) => (dispatch) => {
  dispatch(requestPostpaidCompanyInvoicesList());
  return getPostpaidCompanyInvoicesListAPI(limit, offset, invoiceId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setPostpaidCompanyInvoicesList(offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getPostpaidCompanyInvoicesListFilters = (companyId) => (dispatch) => {
  dispatch(requestPostpaidCompanyInvoicesListFilter());
  return getPostpaidCompanyInvoicesFiltersAPI(companyId)
    .then((response) => {
      let resp = orderBy(
        response?.data?.response,
        ['year', (bill) => BILLING_MONTHS_LIST.indexOf(bill.month)],
        ['desc', 'desc']
      );
      resp = { response: resp };
      resp = toCamelCase(resp);
      dispatch(setPostpaidCompanyInvoicesListFilter(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const getBillingInfoTags = (candidateId, companyId) => (dispatch) => {
  dispatch(requestBillingTags());
  return getBillingInfoTagsAPI(candidateId, companyId)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setBillingTags(resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

// Get Billing logs
export const getBillingLogs = (companyId, limit, offset) => (dispatch) => {
  dispatch(requestBillingLogs());
  return getBillingLogsAPI(companyId, limit, offset)
    .then((response) => {
      const resp = toCamelCase(response.data);
      dispatch(setBillingLogs(offset, resp));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const forceLogoutAdmin = (data) => (dispatch) => {
  return forceLogoutAdminAPI(data);
};

export const updateAdminStatus = (data) => (dispatch) => {
  return updateAdminStatusAPI(data);
};
export const uploadCandidateCSV = (data) => (dispatch) => {
  return uploadCandidateCSVAPI(data);
};

export const getLinkedCandidates = (candidateId, companyId) => (dispatch) => {
  dispatch(requestLinkedCandidates());
  return getLinkedCandidatesAPI(candidateId, companyId)
    .then((response) => {
      const candidates = toCamelCase(response.data);
      dispatch(setLinkedCandidates(candidates));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};

export const getLogsUniqueData = (candidateId) => (dispatch) => {
  dispatch(requestUniqueRecords());
  return getUniqueRecordsAPI(candidateId)
    .then((response) => {
      const uniqueRecords = toCamelCase(response.data);
      dispatch(setUniqueRecords(uniqueRecords));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
