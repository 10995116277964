import * as Yup from 'yup';
import {
  getNameValidationSchema,
  getGSTValidationSchema,
  getBrandNameValidationSchema,
  getLinkedinUrlValidation,
  COMPANY_URL_VALIDATION
} from '../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getNameValidationSchema({ message: 'name', isRequired: true }),
  friendlyName: getBrandNameValidationSchema({isNullable: true, isRequired: false}),
  companyUrl: Yup.string()
    .lowercase()
    .trim()
    .matches(COMPANY_URL_VALIDATION, {
      message: 'Please provide a valid company URL.',
      excludeEmptyString: false
    })
    .required('Company website cannot be blank'),
  linkedInUrl: getLinkedinUrlValidation({isRequired: false, isNullable: true}),
  gstin: getGSTValidationSchema(),
  hrNote: Yup.string()
    .trim()
    .required(`HR Note is Required`)
    .min(10, 'Must be more than 10 characters')
    .max(160, 'Must be less than of 160 characters'),
  hrWarningNote: Yup.string()
    .trim()
    .required('HR Warning Note is Required')
    .min(10, 'Must be more than 10 characters ')
    .max(160, 'Must be less than of 160 characters')
});
