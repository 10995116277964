import { isEmpty } from 'lodash';
import {
  INVALIDATE_CANDIDATES,
  REQUEST_CANDIDATES,
  SET_CANDIDATES,
  INVALIDATE_VIEW_CANDIDATES,
  REQUEST_VIEW_CANDIDATES,
  SET_VIEW_CANDIDATES,
  UPDATE_COMPANY_SERVICES,
  INVALIDATE_COMPANY_SERVICES,
  REQUEST_COMPANY_SERVICES,
  SET_SEARCH_CANDIDATE,
  REQUEST_SEARCH_CANDIDATE,
  INVALIDATE_SEARCH_CANDIDATE,
  SET_COMPANIES,
  SET_INSUFFICIENCY,
  INVALIDATE_COMPANY_DETAILS,
  REQUEST_COMPANY_DETAILS,
  SET_COMPANY_DETAILS,
  INVALIDATE_REFQUESTIONS,
  REQUEST_REFQUESTIONS,
  SET_REFQUESTIONS,
  INVALIDATE_COMPANY_BGV_CONFIG,
  REQUEST_COMPANY_BGV_CONFIG,
  SET_COMPANY_BGV_CONFIG,
  INVALIDATE_COMPANY_CATEGORIES,
  REQUEST_COMPANY_CATEGORIES,
  SET_COMPANY_CATEGORIES,
  INVALIDATE_COMPANYLOGS,
  REQUEST_COMPANYLOGS,
  SET_COMPANYLOGS,
  SET_SERVICES,
  SET_COMPANY_ADMIN,
  INVALIDATE_ASSIGNED_SERVICES,
  REQUEST_ASSIGNED_SERVICES,
  SET_ASSIGNED_SERVICES,
  INVALIDATE_COMPANY_SETTINGS,
  REQUEST_COMPANY_SETTINGS,
  SET_COMPANY_SETTINGS,
  SET_OPS_STATUS,
  INVALIDATE_VENDORS,
  REQUEST_VENDORS,
  SET_VENDORS,
  INVALIDATE_DEFAULT_QUESTIONS,
  REQUEST_DEFAULT_QUESTIONS,
  SET_DEFAULT_QUESTIONS,
  SET_ADMINS,
  INVALIDATE_VIEW_CANDIDATE_DETAILS,
  REQUEST_VIEW_CANDIDATE_DETAILS,
  SET_VIEW_CANDIDATE_DETAILS,
  // SET_ADC_COUNT,
  // INVALIDATE_ADC_COUNT,
  SET_ADC_LIST,
  REQUEST_ADC_LIST,
  INVALIDATE_ADC_LIST,
  INVALIDATE_WARNINGS,
  REQUEST_WARNINGS,
  SET_WARNINGS,
  SET_IDENTITY_DATA_FOUND,
  SET_IDENTITY_DATA_PROVIDED,
  INVALIDATE_EDUCATION_DATA_PROVIDED,
  SET_EDUCATION_DATA_PROVIDED,
  INVALIDATE_EDUCATION_DATA_FOUND,
  SET_EDUCATION_DATA_FOUND,
  INVALIDATE_EMPLOYMENT_DATA_PROVIDED,
  SET_EMPLOYMENT_DATA_PROVIDED,
  REQUEST_EMPLOYMENT_DATA_PROVIDED,
  INVALIDATE_EMPLOYMENT_DATA_FOUND,
  SET_EMPLOYMENT_DATA_FOUND,
  REQUEST_EMPLOYMENT_DATA_FOUND,
  SET_DRUG_DATA_FOUND,
  SET_DRUG_DATA_PROVIDED,
  SET_WORLD_DATA_FOUND,
  SET_WORLD_DATA_PROVIDED,
  SET_IDENTITY_RUN_CHECK,
  INVALIDATE_IDENTITY_RUN_CHECK,
  INVALIDATE_CIBIL_DATA_PROVIDED,
  SET_CIBIL_DATA_PROVIDED,
  REQUEST_CIBIL_DATA_PROVIDED,
  INVALIDATE_CIBIL_DATA_FOUND,
  SET_CIBIL_DATA_FOUND,
  REQUEST_CIBIL_DATA_FOUND,
  INVALIDATE_REFERENCE_DATA_PROVIDED,
  SET_REFERENCE_DATA_PROVIDED,
  INVALIDATE_REFERENCE_DATA_FOUND,
  SET_REFERENCE_DATA_FOUND,
  INVALIDATE_ADDRESS_DATA_PROVIDED,
  SET_ADDRESS_DATA_PROVIDED,
  INVALIDATE_COURT_DATA_PROVIDED,
  SET_COURT_DATA_PROVIDED,
  INVALIDATE_COURT_DATA_FOUND,
  SET_COURT_DATA_FOUND,
  INVALIDATE_ADDRESS_DATA_FOUND,
  SET_ADDRESS_DATA_FOUND,
  INVALIDATE_NOTES,
  SET_NOTES,
  INVALIDATE_OPS_STATUS_NOTES,
  SET_OPS_STATUS_NOTES,
  INVALIDATE_IDENTITY_DATA_FOUND,
  INVALIDATE_IDENTITY_DATA_PROVIDED,
  INVALIDATE_WORLD_DATA_FOUND,
  INVALIDATE_WORLD_DATA_PROVIDED,
  INVALIDATE_DRUG_DATA_FOUND,
  INVALIDATE_DRUG_DATA_PROVIDED,
  INVALIDATE_CANDIDATE_LOGS,
  REQUEST_CANDIDATE_LOGS,
  SET_CANDIDATE_LOGS,
  INVALIDATE_CANDIDATE_CALL_LOGS,
  REQUEST_CANDIDATE_CALL_LOGS,
  SET_CANDIDATE_CALL_LOGS,
  SET_VENDORS_LIST,
  INVALIDATE_VENDORS_LIST,
  INVALIDATE_VENDORS_INFO,
  SET_VENDORS_INFO,
  SET_IDENTITY_OCR,
  INVALIDATE_IDENTITY_OCR,
  INVALIDATE_FEEDBACK,
  REQUEST_FEEDBACK,
  SET_FEEDBACK,
  INVALIDATE_PROFILE,
  REQUEST_PROFILE,
  SET_PROFILE,
  INVALIDATE_REPORTS,
  REQUEST_REPORTS,
  SET_REPORTS,
  INVALIDATE_CUSTOM_DATA_FOUND,
  SET_CUSTOM_DATA_FOUND,
  INVALIDATE_INSUFF_REVIEW,
  REQUEST_INSUFF_REVIEW,
  SET_INSUFF_REVIEW,
  INVALIDATE_COMPANY_EMAIL_SETTINGS,
  REQUEST_COMPANY_EMAIL_SETTINGS,
  SET_COMPANY_EMAIL_SETTINGS,
  SET_INSUFF_LIST,
  INVALIDATE_INSUFF_LIST,
  INVALIDATE_INSUFF_CANDIDATE,
  REQUEST_INSUFF_CANDIDATE,
  SET_INSUFF_CANDIDATE,
  INVALIDATE_PACKAGE_DETAILS,
  REQUEST_PACKAGE_DETAILS,
  SET_PACKAGE_DETAILS,
  INVALIDATE_PACKAGE_COMPANY_LIST,
  REQUEST_PACKAGE_COMPANY_LIST,
  SET_PACKAGE_COMPANY_LIST,
  INVALIDATE_PACKAGE_DETAILS_AUDIT_LOGS,
  REQUEST_PACKAGE_DETAILS_AUDIT_LOGS,
  SET_PACKAGE_DETAILS_AUDIT_LOGS,
  INVALIDATE_PACKAGE_LIST,
  REQUEST_PACKAGE_LIST,
  SET_PACKAGE_LIST,
  SET_CREDIT_BALANCE,
  SET_COMPANY_CREDIT_BALANCE,
  SET_COMPANY_PENDING_ORDERS,
  INVALIDATE_COMPANY_CREDIT_BALANCE,
  SET_COMPANY_RECHARGE_LOG,
  SET_COMPANY_USAGE_LOG,
  SET_OVERALL_PAYMENT_LOGS,
  SET_PD_RECHARGE_LOG,
  SET_PD_USAGE_LOG,
  SET_PD_RECHARGE_DETAILS,
  SET_PD_BASIC_INFO,
  INVALIDATE_PD_BASIC_INFO,
  INVALIDATE_PD_RECHARGE_LOG,
  REQUEST_RECHARGE_LOGS,
  INVALIDATE_PD_RECHARGE_DETAILS,
  INVALIDATE_PD_USAGE_LOG,
  SET_PD_PACKAGE_CONFIG,
  INVALIDATE_PD_PACKAGE_CONFIG,
  SET_REVIEW_ADDITIONAL_CHARGES,
  INVALIDATE_REVIEW_ADDITIONAL_CHARGES,
  INVALIDATE_INSUFF_FUNDS_FOR_CHECKS,
  REQUEST_INSUFF_FUNDS_FOR_CHECKS,
  SET_INSUFF_FUNDS_FOR_CHECKS,
  INVALIDATE_ADD_CANDIDATE_CONFIG,
  REQUEST_ADD_CANDIDATE_CONFIG,
  SET_ADD_CANDIDATE_CONFIG,
  SET_GLOBAL_ADDON_CONFIGURATION,
  INVALIDATE_GLOBAL_ADDON_CONFIGURATION,
  REQUEST_GLOBAL_ADDON_CONFIGURATION,
  INVALIDATE_VERIFICATION_ORDER_SUMMARY,
  REQUEST_VERIFICATION_ORDER_SUMMARY,
  SET_VERIFICATION_ORDER_SUMMARY,
  SET_COMPANY_ADDON_CONFIGURATION,
  INVALIDATE_COMPANY_ADDON_CONFIGURATION,
  REQUEST_COMPANY_ADDON_CONFIGURATION,
  SET_SEARCH_PACKAGE,
  INVALIDATE_SEARCH_PACKAGE,
  INVALIDATE_SKIPPED_CHECK,
  REQUEST_SKIPPED_CHECK,
  SET_SKIPPED_CHECK,
  SET_COMPANY_AUDIT_LOGS,
  INVALIDATE_COMPANY_AUDIT_LOGS,
  REQUEST_COMPANY_AUDIT_LOGS,
  SET_CREDIT_POSTPAID_COMPANIES,
  INVALIDATE_CREDIT_POSTPAID_COMPANIES,
  REQUEST_CREDIT_POSTPAID_COMPANIES,
  REQUEST_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE,
  INVALIDATE_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE,
  SET_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE,
  REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_LOG,
  INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_LOG,
  SET_POSTPAID_PACKAGE_DETAILS_USAGE_LOG,
  REQUEST_POSTPAID_PACKAGE_DETAILS_BASIC_INFO,
  REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG,
  SET_POSTPAID_PACKAGE_DETAILS_BASIC_INFO,
  SET_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG,
  INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG,
  INVALIDATE_POSTPAID_PACKAGE_DETAILS_BASIC_INFO,
  SET_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE,
  INVALIDATE_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE,
  REQUEST_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE,
  SET_POSTPAID_CREDITS_DETAILS_USAGE_LOG,
  INVALIDATE_POSTPAID_CREDITS_DETAILS_USAGE_LOG,
  REQUEST_POSTPAID_CREDITS_DETAILS_USAGE_LOG,
  SET_POSTPAID_CREDITS_DETAILS_AUDIT_LOG,
  INVALIDATE_POSTPAID_CREDITS_DETAILS_AUDIT_LOG,
  REQUEST_POSTPAID_CREDITS_DETAILS_AUDIT_LOG,
  SET_CREDIT_POSTPAID_COMPANY,
  INVALIDATE_CREDIT_POSTPAID_COMPANY,
  REQUEST_CREDIT_POSTPAID_COMPANY,
  SET_POSTPAID_UNBILLED_CANDIDATES,
  INVALIDATE_POSTPAID_UNBILLED_CANDIDATES,
  REQUEST_POSTPAID_UNBILLED_CANDIDATES,
  SET_POSTPAID_UNBILLED_CANDIDATES_FILTERS,
  INVALIDATE_POSTPAID_UNBILLED_CANDIDATES_FILTERS,
  REQUEST_POSTPAID_UNBILLED_CANDIDATES_FILTERS,
  SET_POSTPAID_COMPANY_INVOICES_LIST,
  INVALIDATE_POSTPAID_COMPANY_INVOICES_LIST,
  REQUEST_POSTPAID_COMPANY_INVOICES_LIST,
  SET_POSTPAID_COMPANY_INVOICES_FILTERS,
  INVALIDATE_POSTPAID_COMPANY_INVOICES_FILTERS,
  REQUEST_POSTPAID_COMPANY_INVOICES_FILTERS,
  REQUEST_BILLING_TAGS,
  SET_BILLING_TAGS,
  REQUEST_BILLING_LOGS,
  SET_BILLING_LOGS,
  SET_PREPAID_USAGE_LOG,
  INVALIDATE_LINKED_CANDIDATES,
  REQUEST_LINKED_CANDIDATES,
  SET_LINKED_CANDIDATES,
  REQUEST_REVIEW_ADDITIONAL_CHARGES,
  REQUEST_OVERALL_PAYMENT_LOGS,
  INVALIDATE_OVERALL_PAYMENT_LOGS,
  REQUEST_COMPANY_CREDIT_BALANCE,
  REQUEST_COMPANY_PENDING_ORDERS,
  INVALIDATE_COMPANY_PENDING_ORDERS,
  INVALIDATE_UNIQUE_RECORDS,
  REQUEST_UNIQUE_RECORDS,
  SET_UNIQUE_RECORDS
} from '../actions/admin';
import {
  SET_BULK_UPLOAD_DATA,
  INVALIDATE_BULK_UPLOAD_DATA,
  SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA,
  SET_SECURITY_SETTINGS_CONFIGURATION,
  REQUEST_SECURITY_SETTINGS_CONFIGURATION,
  INVALIDATE_SECURITY_SETTINGS_CONFIGURATION,
  SET_WALLET_DETAIL,
  REQUEST_WALLET_DETAIL,
  INVALIDATE_WALLET_DETAIL,
  INVALIDATE_SELECTED_TIERS,
  SET_SELECTED_TIERS,
  INVALIDATE_VIEW_BUY_PACKAGES,
  REQUEST_VIEW_BUY_PACKAGES,
  SET_VIEW_BUY_PACKAGES,
  REQUEST_PAYMENT_DETAILS,
  SET_PAYMENT_DETAILS
} from '../actions/company';
export default (state = {}, action) => {
  return {
    candidates: candidates(state.candidates, action),
    viewCandidateData: viewCandidateData(state.viewCandidateData, action),
    companyServices: companyServices(state.companyServices, action),
    search: searchCandidate(state.search, action),
    companies: companies(state.companies, action),
    updateCompanyData: companyDetails(state.updateCompanyData, action),
    refQuestions: refQuestions(state.refQuestions, action),
    companyBgvConfig: companyBgvConfig(state.companyBgvConfig, action),
    companyCategories: companyCategories(state.companyCategories, action),
    companyLogs: companyLogs(state.companyLogs, action),
    services: services(state.services, action),
    companyAdmin: setCompanyAdmin(state.companyAdmin, action),
    assignedServices: assignedServices(state.assignedServices, action),
    companySettings: companySettings(state.companySettings, action),
    opsStatus: opsStatus(state.opsStatus, action),
    vendors: vendors(state.vendors, action),
    defaultQuestions: defaultQuestions(state.vendors, action),
    admins: setAdmins(state.admins, action),
    candidateDetails: setViewCandidateDetails(state.candidateDetails, action),
    // adcCount: setAdcCount(state.adcCount, action),
    adcList: setADCList(state.adcList, action),
    warnings: getWarnings(state.warnings, action),
    identityDataProvided: setIdentityDataProvided(state.identityDataProvided, action),
    identityDataFound: setIdentityDataFound(state.identityDataFound, action),
    identityRunOcrCheck: setIdentityRunCheck(state.identityRunOcrCheck, action),
    educationDataProvided: educationDataProvided(state.educationDataProvided, action),
    educationDataFound: educationDataFound(state.educationDataFound, action),
    employmentDataProvided: employmentDataProvided(state.employmentDataProvided, action),
    employmentDataFound: employmentDataFound(state.employmentDataFound, action),
    drugDataProvided: setDrugDataProvided(state.drugDataProvided, action),
    drugDataFound: setDrugDataFound(state.drugDataFound, action),
    worldCheckDataFound: setWorldDataFound(state.worldCheckDataFound, action),
    worldCheckDataProvided: setWorldDataProvided(state.worldCheckDataProvided, action),
    cibilDataProvided: getCibilDataProvided(state.cibilDataProvided, action),
    cibilDataFound: getCibilDataFound(state.cibilDataFound, action),
    referenceDataProvided: referenceDataProvided(state.referenceDataProvided, action),
    referenceDataFound: referenceDataFound(state.referenceDataFound, action),
    addressDataProvided: addressDataProvided(state.addressDataProvided, action),
    courtDataProvided: courtDataProvided(state.courtDataProvided, action),
    courtDataFound: courtDataFound(state.courtDataFound, action),
    addressDataFound: addressDataFound(state.addressDataFound, action),
    notes: notes(state.notes, action),
    opsStatusNotes: opsStatusNotes(state.opsStatusNotes, action),
    candidateLogs: candidateLogs(state.candidateLogs, action),
    candidateCallLogs: candidateCallLogs(state.candidateCallLogs, action),
    vendorList: vendorList(state.vendorList, action),
    vendorData: vendorInfo(state.vendorData, action),
    ocrData: setIdentityOCR(state.ocrData, action),
    feedback: setFeedback(state.feedback, action),
    profile: setUser(state.profile, action),
    reportData: setReports(state.reportData, action),
    customCheckDF: setCustomCheckDF(state.customCheckDF, action),
    insuffData: setInsuffReview(state.insuffData, action),
    companyEmailSettings: setCompanyEmailSettings(state.companyEmailSettings, action),
    insuffListData: setInsuffList(state.insuffListData, action),
    candidateInsufficiency: setCandidateInsufficiency(state.candidateInsufficiency, action),
    packageDetails: setPackageDetails(state.packageDetails, action),
    packageCompanyList: setPackageCompanyList(state.packageCompanyList, action),
    packageDetailsAuditLogs: setPackageDetailsAuditLogs(state.packageDetailsAuditLogs, action),
    packageList: setPackageList(state.packageList, action),
    creditBalance: setCreditBalance(state.creditBalance, action),
    companyCreditBalance: setCompanyCreditBalance(state.companyCreditBalance, action),
    companyPendingOrders: setCompanyPendingOrders(state.companyPendingOrders, action),
    companyRechargeLogs: setCompanyRechargeLogs(state.companyRechargeLogs, action),
    companyUsageLogs: setCompanyUsageLogs(state.companyUsageLogs, action),
    prepaidUsageLogs: setPrepaidUsageLogs(state.prepaidUsageLogs, action),
    overallPaymentLogs: setOverallPaymentLogs(state.overallPaymentLogs, action),
    packageDetailsRechargeLogs: setPackageDetailsRechargeLogs(
      state.packageDetailsRechargeLogs,
      action
    ),
    packageDetailsUsageLogs: setPackageDetailsUsageLogs(state.packageDetailsUsageLogs, action),
    reviewAdditionalChargesList: setReviewAdditionalChargesList(
      state.reviewAdditionalChargesList,
      action
    ),
    insuffFundsForChecks: setInsuffFundsForChecks(state.insuffFundsForChecks, action),
    addCandidateConfig: setAddCandidateConfig(state.addCandidateConfig, action),
    packageDetailsBasicInfo: setPackageDetailsBasicInfo(state.packageDetailsBasicInfo, action),
    packageDetailsRechargeDetails: setPackageDetailsRechargeDetails(
      state.packageDetailsRechargeDetails,
      action
    ),
    globalAddonConfiguration: setGlobalAddonConfiguration(state.globalAddonConfiguration, action),
    packageDetailsPackageConfig: setPackageDetailsPackageConfig(
      state.packageDetailsPackageConfig,
      action
    ),
    verificationOrderSummary: setVerificationOrderSummary(state.verificationOrderSummary, action),
    companyAddonConfig: setCompanyAddonConfiguration(state.companyAddonConfig, action),
    securitySettings: setSecuritySettings(state.securitySettings, action),
    walletDetail: setWalletDetail(state ? state.walletDetail : {}, action),
    viewBuyPackages: setViewBuyPackages(state ? state.viewBuyPackages : {}, action),
    paymentDetails: getCompanyPaymentDetails(state ? state.paymentDetails : {}, action),
    selectedTiers: selectedTiersReducer(state ? state.selectedTiers : {}, action),
    searchPackage: setSearchPackage(state.searchPackage, action),
    skippedCheck: setSkippedCheck(state.skippedCheck, action),
    companyAuditLogs: setCompanyAuditLogs(state.companyAuditLogs, action),
    postpaidCreditCompanies: setPostpaidCreditCompanies(state.postpaidCreditCompanies, action),
    postpaidPackageDetailsMonthlyUsage: setPostpaidPackageDetailsMonthlyUsage(
      state.postpaidPackageDetailsMonthlyUsage,
      action
    ),
    postpaidPackageDetailsUsageLog: setPostpaidPackageDetailsUsageLog(
      state.postpaidPackageDetailsUsageLog,
      action
    ),
    postpaidPackageDetailsUsageConfig: setPostpaidPackageDetailsUsageConfig(
      state.postpaidPackageDetailsUsageConfig,
      action
    ),
    postpaidPackageDetailsBasicInfo: setPostpaidPackageDetailsBasicInfo(
      state.postpaidPackageDetailsBasicInfo,
      action
    ),
    postpaidCreditCompany: setPostpaidCreditCompany(state.postpaidCreditCompany, action),
    postpaidCreditDetailsMonthlyUsage: setPostpaidCreditDetailsMonthlyUsage(
      state.postpaidCreditDetailsMonthlyUsage,
      action
    ),
    postpaidCreditDetailsUsageLog: setPostpaidCreditDetailsUsageLog(
      state.postpaidCreditDetailsUsageLog,
      action
    ),
    postpaidCreditDetailsAuditLog: setPostpaidCreditDetailsAuditLog(
      state.postpaidCreditDetailsAuditLog,
      action
    ),
    postpaidUnbilledCandidatesList: setPostpaidUnbilledCandidatesList(
      state.postpaidUnbilledCandidatesList,
      action
    ),
    postpaidUnbilledCandidatesFilters: setPostpaidUnbilledCandidatesListFilter(
      state.postpaidUnbilledCandidatesFilters,
      action
    ),
    postpaidCompanyInvoicesList: setPostpaidCompanyInvoicesList(
      state.postpaidCompanyInvoicesList,
      action
    ),
    postpaidCompanyInvoicesFilters: setPostpaidCompanyInvoicesListFilter(
      state.postpaidCompanyInvoicesFilters,
      action
    ),
    billingTags: setBillingtags(state.billingTags ? state.billingTags : {}, action),
    billingLogs: setBillingLogs(state.billingLogs, action),
    bulkData: setBulkUploadData(state ? state.bulkData : {}, action),
    linkedCandidates: getLinkedCandidates(state.linkedCandidates, action),
    candidateLogsUniqueRecords: candidateLogsUniqueRecords(state.candidateLogsUniqueRecords, action)
  };
};

const candidates = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_CANDIDATES:
      return { all: [] };
    case REQUEST_CANDIDATES:
      return { ...state, loading: true };
    case SET_CANDIDATES:
      const candidates = state.all;
      candidates[action.index] = action.data;
      return { ...state, all: candidates, loading: false };
    default:
      return state;
  }
};

const viewCandidateData = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VIEW_CANDIDATES:
      return {};
    case REQUEST_VIEW_CANDIDATES:
      return { ...state, loading: true };
    case SET_VIEW_CANDIDATES:
      const testing = { ...state };
      testing.viewCandidateData = { ...action.data };
      return { ...state, viewCandidateData: { ...action.data }, loading: false };
    default:
      return state;
  }
};

const companyServices = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_SERVICES:
      return {};
    case REQUEST_COMPANY_SERVICES:
      return { ...state, loading: true };
    case UPDATE_COMPANY_SERVICES:
      return { ...state, companyServices: { ...action.data }, loading: false };
    default:
      return state;
  }
};

const searchCandidate = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SEARCH_CANDIDATE:
      return {};
    case REQUEST_SEARCH_CANDIDATE:
      return { ...state, loading: true };
    case SET_SEARCH_CANDIDATE:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const companies = (state = {}, action) => {
  switch (action.type) {
    case SET_COMPANIES:
      return { ...state, ...action.data, loading: false };
    case SET_INSUFFICIENCY:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const companyDetails = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_DETAILS:
      return {};
    case REQUEST_COMPANY_DETAILS:
      return { ...state, loading: true };
    case SET_COMPANY_DETAILS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const refQuestions = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_REFQUESTIONS:
      return { all: [] };
    case REQUEST_REFQUESTIONS:
      return { ...state, loading: true };
    case SET_REFQUESTIONS:
      const refQuestions = state.all;
      refQuestions[action.index] = action.data;
      return { ...state, all: refQuestions, loading: false };
    default:
      return state;
  }
};

const companyBgvConfig = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_BGV_CONFIG:
      return {};
    case REQUEST_COMPANY_BGV_CONFIG:
      return { ...state, loading: true };
    case SET_COMPANY_BGV_CONFIG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const companyCategories = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_CATEGORIES:
      return { all: [] };
    case REQUEST_COMPANY_CATEGORIES:
      return { ...state, loading: true };
    case SET_COMPANY_CATEGORIES:
      const companyCategories = state.all;
      if (action.index === null) {
        companyCategories['data'] = action.data;
      } else {
        companyCategories[action.index] = action.data;
      }

      return { ...state, all: companyCategories, loading: false };
    default:
      return state;
  }
};

const companyLogs = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANYLOGS:
      return { all: [] };
    case REQUEST_COMPANYLOGS:
      return { ...state, loading: true };
    case SET_COMPANYLOGS:
      const companyLogs = state.all;
      companyLogs[action.index] = action.data;
      return { ...state, all: companyLogs, loading: false };
    default:
      return state;
  }
};

const services = (state = {}, action) => {
  switch (action.type) {
    case SET_SERVICES:
      return { ...state, ...action.data, loading: true };
    default:
      return state;
  }
};

const setCompanyAdmin = (state = {}, action) => {
  switch (action.type) {
    case SET_COMPANY_ADMIN:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const assignedServices = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ASSIGNED_SERVICES:
      return {};
    case REQUEST_ASSIGNED_SERVICES:
      return { ...state, loading: true };
    case SET_ASSIGNED_SERVICES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const companySettings = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_SETTINGS:
      return {};
    case REQUEST_COMPANY_SETTINGS:
      return { ...state, loading: true };
    case SET_COMPANY_SETTINGS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const opsStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_OPS_STATUS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const vendors = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_VENDORS:
      return { all: [] };
    case REQUEST_VENDORS:
      return { ...state, loading: true };
    case SET_VENDORS:
      const vendors = state.all;
      vendors[action.index] = action.data;
      return { ...state, all: vendors, loading: false };
    default:
      return state;
  }
};

const defaultQuestions = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_DEFAULT_QUESTIONS:
      return { all: [] };
    case REQUEST_DEFAULT_QUESTIONS:
      return { ...state, loading: true };
    case SET_DEFAULT_QUESTIONS:
      const questions = state.all;
      questions[action.index] = action.data;
      return { ...state, all: questions, loading: false };
    default:
      return state;
  }
};

const setAdmins = (state = {}, action) => {
  switch (action.type) {
    case SET_ADMINS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setViewCandidateDetails = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VIEW_CANDIDATE_DETAILS:
      return {};
    case REQUEST_VIEW_CANDIDATE_DETAILS:
      return { ...state, loading: true };
    case SET_VIEW_CANDIDATE_DETAILS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setADCList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ADC_LIST:
      return {};
    case REQUEST_ADC_LIST:
      return { ...state, loading: true };
    case SET_ADC_LIST:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const getWarnings = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_WARNINGS:
      return {};
    case REQUEST_WARNINGS:
      return { ...state, loading: true };
    case SET_WARNINGS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setIdentityDataFound = (state = {}, action) => {
  switch (action.type) {
    case SET_IDENTITY_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    case INVALIDATE_IDENTITY_DATA_FOUND:
      return {};

    default:
      return state;
  }
};

const setIdentityDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_DATA_PROVIDED:
      return {};
    case SET_IDENTITY_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setIdentityRunCheck = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_RUN_CHECK:
      return {};
    case SET_IDENTITY_RUN_CHECK:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setIdentityOCR = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_OCR:
      return {};
    case SET_IDENTITY_OCR:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const educationDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EDUCATION_DATA_PROVIDED:
      return {};
    case SET_EDUCATION_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const educationDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EDUCATION_DATA_FOUND:
      return {};
    case SET_EDUCATION_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

/**
 * Reducer for emp DP
 */
const employmentDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EMPLOYMENT_DATA_PROVIDED:
      return {};
    case REQUEST_EMPLOYMENT_DATA_PROVIDED:
      return { ...state, loading: true };
    case SET_EMPLOYMENT_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
}; // End : employmentDataProvided

/**
 * Reducer for emp DF
 */
const employmentDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EMPLOYMENT_DATA_FOUND:
      return {};
    case REQUEST_EMPLOYMENT_DATA_FOUND:
      return { ...state, loading: true };
    case SET_EMPLOYMENT_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
}; // End : employmentDataFound

//Drug check Data Provided
const setDrugDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_DRUG_DATA_PROVIDED:
      return {};
    case SET_DRUG_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
}; //End: Drug check Data Provided

//Drug check Data Found
const setDrugDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_DRUG_DATA_FOUND:
      return {};
    case SET_DRUG_DATA_FOUND:
      return { ...state, ...action.data, loading: false };

    default:
      return state;
  }
}; //End: Drug check Data Found

//World check DF
const setWorldDataFound = (state = {}, action) => {
  switch (action.type) {
    case SET_WORLD_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    case INVALIDATE_WORLD_DATA_FOUND:
      return {};
    default:
      return state;
  }
}; //End: World check

//World check DP
const setWorldDataProvided = (state = {}, action) => {
  switch (action.type) {
    case SET_WORLD_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    case INVALIDATE_WORLD_DATA_PROVIDED:
      return {};
    default:
      return state;
  }
}; //End: World check

/**
 * Reducer for emp DP
 */
const getCibilDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_CIBIL_DATA_PROVIDED:
      return {};
    case REQUEST_CIBIL_DATA_PROVIDED:
      return { ...state, loading: true };
    case SET_CIBIL_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
}; // End : getCibilDataProvided

/**
 * Reducer for emp DF
 */
const getCibilDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_CIBIL_DATA_FOUND:
      return {};
    case REQUEST_CIBIL_DATA_FOUND:
      return { ...state, loading: true };
    case SET_CIBIL_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
}; // End : CibilDataFound

// Reducer for Ref. DP
const referenceDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_REFERENCE_DATA_PROVIDED:
      return {};
    case SET_REFERENCE_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Reducer for Ref DF
const referenceDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_REFERENCE_DATA_FOUND:
      return {};
    case SET_REFERENCE_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Address Data Provided
const addressDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_DATA_PROVIDED:
      return {};
    case SET_ADDRESS_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Reducer for Court DP
const courtDataProvided = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COURT_DATA_PROVIDED:
      return {};
    case SET_COURT_DATA_PROVIDED:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Reducer for Court DF
const courtDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COURT_DATA_FOUND:
      return {};
    case SET_COURT_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Address Data Found
const addressDataFound = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_DATA_FOUND:
      return {};
    case SET_ADDRESS_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Notes
const notes = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_NOTES:
      return {};
    case SET_NOTES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

// Ops Status Notes
const opsStatusNotes = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_OPS_STATUS_NOTES:
      return {};
    case SET_OPS_STATUS_NOTES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const candidateLogs = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_CANDIDATE_LOGS:
      return { all: [] };
    case REQUEST_CANDIDATE_LOGS:
      return { ...state, loading: true };
    case SET_CANDIDATE_LOGS:
      const CANDIDATE_LOGS = state.all;
      CANDIDATE_LOGS[action.index] = action.data;
      return { ...state, all: CANDIDATE_LOGS, loading: false };
    default:
      return state;
  }
};

const candidateCallLogs = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_CANDIDATE_CALL_LOGS:
      return { all: [] };
    case REQUEST_CANDIDATE_CALL_LOGS:
      return { ...state, loading: true };
    case SET_CANDIDATE_CALL_LOGS:
      const CANDIDATE_CALL_LOGS = state.all;
      CANDIDATE_CALL_LOGS[action.index] = action.data;
      return { ...state, all: CANDIDATE_CALL_LOGS, loading: false };
    default:
      return state;
  }
};

// Vendors dropdown list
const vendorList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VENDORS_LIST:
      return {};
    case SET_VENDORS_LIST:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

// Vendor Info
const vendorInfo = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VENDORS_INFO:
      return {};
    case SET_VENDORS_INFO:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

const setFeedback = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_FEEDBACK:
      return { all: [] };
    case REQUEST_FEEDBACK:
      return { ...state, loading: true };
    case SET_FEEDBACK:
      const FEEDBACK = state.all;
      FEEDBACK[action.index] = action.data;
      return { ...state, all: FEEDBACK, loading: false };
    default:
      return state;
  }
};

const setUser = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PROFILE:
      return {};
    case REQUEST_PROFILE:
      return { ...state, loading: true };
    case SET_PROFILE:
      const data = !isEmpty(action.data.user) ? { ...action.data.user } : {};
      data['token'] = action.data.token ? action.data.token : null;
      return { ...state, ...data, loading: false };
    default:
      return state;
  }
};

const setReports = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_REPORTS:
      return {};
    case REQUEST_REPORTS:
      return { ...state, loading: true };
    case SET_REPORTS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setCustomCheckDF = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_CUSTOM_DATA_FOUND:
      return {};
    case SET_CUSTOM_DATA_FOUND:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setInsuffReview = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_INSUFF_REVIEW:
      return { all: [] };
    case REQUEST_INSUFF_REVIEW:
      return { ...state, loading: true };
    case SET_INSUFF_REVIEW:
      const insuffReview = state.all;
      insuffReview[action.index] = action.data;
      return { ...state, all: insuffReview, loading: false };
    default:
      return state;
  }
};

const setCompanyEmailSettings = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_EMAIL_SETTINGS:
      return {};
    case REQUEST_COMPANY_EMAIL_SETTINGS:
      return { ...state, loading: true };
    case SET_COMPANY_EMAIL_SETTINGS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setInsuffList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_INSUFF_LIST:
      return {};
    case SET_INSUFF_LIST:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setCandidateInsufficiency = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_INSUFF_CANDIDATE:
      return {};
    case REQUEST_INSUFF_CANDIDATE:
      return { ...state, loading: true };
    case SET_INSUFF_CANDIDATE:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageDetails = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PACKAGE_DETAILS:
      return {};
    case REQUEST_PACKAGE_DETAILS:
      return { ...state, loading: true };
    case SET_PACKAGE_DETAILS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageCompanyList = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_PACKAGE_COMPANY_LIST:
      return { all: [] };
    case REQUEST_PACKAGE_COMPANY_LIST:
      return { ...state, loading: true };
    case SET_PACKAGE_COMPANY_LIST:
      const list = state.all;
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setPackageDetailsAuditLogs = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_PACKAGE_DETAILS_AUDIT_LOGS:
      return { all: [] };
    case REQUEST_PACKAGE_DETAILS_AUDIT_LOGS:
      return { ...state, loading: true };
    case SET_PACKAGE_DETAILS_AUDIT_LOGS:
      const list = state.all;
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setPackageList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PACKAGE_LIST:
      return { all: [] };
    case REQUEST_PACKAGE_LIST:
      return { ...state, loading: true };
    case SET_PACKAGE_LIST:
      const list = state.all;
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setCreditBalance = (state = {}, action) => {
  switch (action.type) {
    case SET_CREDIT_BALANCE:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setCompanyCreditBalance = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_CREDIT_BALANCE:
      return {};
    case REQUEST_COMPANY_CREDIT_BALANCE:
      return { ...state, loading: true };
    case SET_COMPANY_CREDIT_BALANCE:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};
const setCompanyPendingOrders = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_PENDING_ORDERS:
      return {};
    case REQUEST_COMPANY_PENDING_ORDERS:
      return { ...state, loading: true };
    case SET_COMPANY_PENDING_ORDERS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setCompanyRechargeLogs = (state = {}, action) => {
  switch (action.type) {
    case SET_COMPANY_RECHARGE_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setCompanyUsageLogs = (state = {}, action) => {
  switch (action.type) {
    case SET_COMPANY_USAGE_LOG:
      return { ...state, companyUsageLogs: action.data, loading: false };
    default:
      return state;
  }
};
const setPrepaidUsageLogs = (state = {}, action) => {
  switch (action.type) {
    case SET_PREPAID_USAGE_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};
const setOverallPaymentLogs = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_OVERALL_PAYMENT_LOGS:
      return {};
    case REQUEST_OVERALL_PAYMENT_LOGS:
      return { ...state, loading: true };
    case SET_OVERALL_PAYMENT_LOGS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageDetailsRechargeLogs = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PD_RECHARGE_LOG:
      return {};
    case REQUEST_RECHARGE_LOGS:
      return { ...state, loading: true };
    case SET_PD_RECHARGE_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageDetailsUsageLogs = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PD_USAGE_LOG:
      return {};
    case SET_PD_USAGE_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setReviewAdditionalChargesList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_REVIEW_ADDITIONAL_CHARGES:
      return {};
    case REQUEST_REVIEW_ADDITIONAL_CHARGES:
      return { ...state, loading: true };
    case SET_REVIEW_ADDITIONAL_CHARGES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setInsuffFundsForChecks = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_INSUFF_FUNDS_FOR_CHECKS:
      return { all: [] };
    case REQUEST_INSUFF_FUNDS_FOR_CHECKS:
      return { ...state, loading: true };
    case SET_INSUFF_FUNDS_FOR_CHECKS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setAddCandidateConfig = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ADD_CANDIDATE_CONFIG:
      return {};
    case REQUEST_ADD_CANDIDATE_CONFIG:
      return { ...state, loading: true };
    case SET_ADD_CANDIDATE_CONFIG:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageDetailsBasicInfo = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PD_BASIC_INFO:
      return {};
    case SET_PD_BASIC_INFO:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageDetailsRechargeDetails = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PD_RECHARGE_DETAILS:
      return {};
    case SET_PD_RECHARGE_DETAILS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setGlobalAddonConfiguration = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_GLOBAL_ADDON_CONFIGURATION:
      return {};
    case REQUEST_GLOBAL_ADDON_CONFIGURATION:
      return { loading: true };
    case SET_GLOBAL_ADDON_CONFIGURATION:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPackageDetailsPackageConfig = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PD_PACKAGE_CONFIG:
      return {};
    case SET_PD_PACKAGE_CONFIG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setVerificationOrderSummary = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VERIFICATION_ORDER_SUMMARY:
      return {};
    case REQUEST_VERIFICATION_ORDER_SUMMARY:
      return { loading: true };
    case SET_VERIFICATION_ORDER_SUMMARY:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setCompanyAddonConfiguration = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_ADDON_CONFIGURATION:
      return {};
    case REQUEST_COMPANY_ADDON_CONFIGURATION:
      return { loading: true };
    case SET_COMPANY_ADDON_CONFIGURATION:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setSecuritySettings = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SECURITY_SETTINGS_CONFIGURATION:
      return {};
    case REQUEST_SECURITY_SETTINGS_CONFIGURATION:
      return { loading: true };
    case SET_SECURITY_SETTINGS_CONFIGURATION:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setWalletDetail = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_WALLET_DETAIL:
      return {};
    case REQUEST_WALLET_DETAIL:
      return { loading: true };
    case SET_WALLET_DETAIL:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setViewBuyPackages = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VIEW_BUY_PACKAGES:
      return {};
    case REQUEST_VIEW_BUY_PACKAGES:
      return { loading: true };
    case SET_VIEW_BUY_PACKAGES:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const getCompanyPaymentDetails = (state, action) => {
  switch (action.type) {
    case REQUEST_PAYMENT_DETAILS:
      return { loading: true };
    case SET_PAYMENT_DETAILS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const selectedTiersReducer = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SELECTED_TIERS:
      return {};
    case SET_SELECTED_TIERS:
      return { ...action.data };
    default:
      return state;
  }
};

const setSearchPackage = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SEARCH_PACKAGE:
      return {};
    case SET_SEARCH_PACKAGE:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setSkippedCheck = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SKIPPED_CHECK:
      return {};
    case REQUEST_SKIPPED_CHECK:
      return { loading: true };
    case SET_SKIPPED_CHECK:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setCompanyAuditLogs = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_COMPANY_AUDIT_LOGS:
      return {};
    case REQUEST_COMPANY_AUDIT_LOGS:
      return { loading: true };
    case SET_COMPANY_AUDIT_LOGS:
      const list = state.all || [];
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setPostpaidCreditCompanies = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_CREDIT_POSTPAID_COMPANIES:
      return {};
    case REQUEST_CREDIT_POSTPAID_COMPANIES:
      return { loading: true };
    case SET_CREDIT_POSTPAID_COMPANIES:
      const list = state.all || [];
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setPostpaidPackageDetailsMonthlyUsage = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE:
      return {};
    case REQUEST_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE:
      return { loading: true };
    case SET_POSTPAID_PACKAGE_DETAILS_MONTHLY_USAGE:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidPackageDetailsUsageLog = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_LOG:
      return {};
    case REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_LOG:
      return { loading: true };
    case SET_POSTPAID_PACKAGE_DETAILS_USAGE_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidPackageDetailsUsageConfig = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG:
      return {};
    case REQUEST_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG:
      return { loading: true };
    case SET_POSTPAID_PACKAGE_DETAILS_USAGE_CONFIG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidCreditCompany = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_CREDIT_POSTPAID_COMPANY:
      return {};
    case REQUEST_CREDIT_POSTPAID_COMPANY:
      return { loading: true };
    case SET_CREDIT_POSTPAID_COMPANY:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidPackageDetailsBasicInfo = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_PACKAGE_DETAILS_BASIC_INFO:
      return {};
    case REQUEST_POSTPAID_PACKAGE_DETAILS_BASIC_INFO:
      return { loading: true };
    case SET_POSTPAID_PACKAGE_DETAILS_BASIC_INFO:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidCreditDetailsMonthlyUsage = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE:
      return {};
    case REQUEST_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE:
      return { loading: true };
    case SET_POSTPAID_CREDITS_DETAILS_MONTHLY_USAGE:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidCreditDetailsUsageLog = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_CREDITS_DETAILS_USAGE_LOG:
      return {};
    case REQUEST_POSTPAID_CREDITS_DETAILS_USAGE_LOG:
      return { loading: true };
    case SET_POSTPAID_CREDITS_DETAILS_USAGE_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidCreditDetailsAuditLog = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_CREDITS_DETAILS_AUDIT_LOG:
      return {};
    case REQUEST_POSTPAID_CREDITS_DETAILS_AUDIT_LOG:
      return { loading: true };
    case SET_POSTPAID_CREDITS_DETAILS_AUDIT_LOG:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidUnbilledCandidatesList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_UNBILLED_CANDIDATES:
      return {};
    case REQUEST_POSTPAID_UNBILLED_CANDIDATES:
      return { loading: true };
    case SET_POSTPAID_UNBILLED_CANDIDATES:
      const list = state.all || [];
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setPostpaidUnbilledCandidatesListFilter = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_UNBILLED_CANDIDATES_FILTERS:
      return {};
    case REQUEST_POSTPAID_UNBILLED_CANDIDATES_FILTERS:
      return { loading: true };
    case SET_POSTPAID_UNBILLED_CANDIDATES_FILTERS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setPostpaidCompanyInvoicesList = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_COMPANY_INVOICES_LIST:
      return {};
    case REQUEST_POSTPAID_COMPANY_INVOICES_LIST:
      return { loading: true };
    case SET_POSTPAID_COMPANY_INVOICES_LIST:
      const list = state.all || [];
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setPostpaidCompanyInvoicesListFilter = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_POSTPAID_COMPANY_INVOICES_FILTERS:
      return {};
    case REQUEST_POSTPAID_COMPANY_INVOICES_FILTERS:
      return { loading: true };
    case SET_POSTPAID_COMPANY_INVOICES_FILTERS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};
const setBillingtags = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_BILLING_TAGS:
      return { ...state, loading: true };
    case SET_BILLING_TAGS:
      return { ...state, billingTags: action.data.data, loading: false };
    default:
      return state;
  }
};
const setBillingLogs = (state = { all: [] }, action) => {
  switch (action.type) {
    case REQUEST_BILLING_LOGS:
      return { ...state, loading: true };
    case SET_BILLING_LOGS:
      const list = state.all;
      list[action.index] = action.data;
      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const setBulkUploadData = (state = { bulkData: {} }, action) => {
  switch (action.type) {
    case INVALIDATE_BULK_UPLOAD_DATA:
      return {};
    case SET_BULK_UPLOAD_DATA:
      return { ...state, rows: action?.data };
    case SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA:
      return { ...state, values: action?.data };
    default:
      return state;
  }
};

const getLinkedCandidates = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_LINKED_CANDIDATES:
      return {};
    case REQUEST_LINKED_CANDIDATES:
      return { ...state, loading: true };
    case SET_LINKED_CANDIDATES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};
const candidateLogsUniqueRecords = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_UNIQUE_RECORDS:
      return {};
    case REQUEST_UNIQUE_RECORDS:
      return { ...state, loading: true };
    case SET_UNIQUE_RECORDS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};
