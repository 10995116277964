import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment';
import DateField from '../../../core-components/PickerOrTextDate';
import CustomizedBreadcrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import { getChartData as getChartDatAPI, getCompanyById } from '../../../api/admin';
import GraphComponent from './GraphComponent/GraphComponent';
import Dropdown from '../../../core-components/DropdownInput';
import { faDownload, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './analytics.css';
import Editor from './Editor/Editor';
import DataEditor from './DataEditor/DataEditor';
import { Spinner } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';
const dummyData = require('./dummyResponse.json');

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    marginTop: '50px'
  }
}));

export default function Analytics() {
  const classes = useStyles();
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  const [companyName, setCompanyName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [graphData, setGraphData] = useState(dummyData);
  const [graphDataBackup, setGraphDataBackup] = useState(dummyData);
  const [showSettings, setShowSettings] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allChartsReferenceMap, setAllChartsReferenceMap] = useState({});

  useEffect(() => {
    getCompanyById(companyId)
      .then((res) => {
        setCompanyName(res.data.data.name);
      })
      .catch((error) => {
        console.error(error);
        toast.error(getErrorMessage(error));
      });
  }, []);

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  const handleFetchChartData = (params) => {
    const { startDate: start = startDate, endDate: end = endDate } = params;
    if (!start || !end) return;

    const sy = start.split('-')[0];
    const sm = start.split('-')[1];
    const ey = end.split('-')[0];
    const em = end.split('-')[1];
    setLoading(true);

    getChartDatAPI({
      start_year: sy,
      start_month: sm,
      end_year: ey,
      end_month: em,
      company_id: companyId
    })
      .then((res) => {
        setLoading(false);
        setGraphData(res.data.data);
        setGraphDataBackup(res.data.data);
        setChartOptions({
          ...chartOptions,
          fillColors: defaultOptions.fillColors.slice(
            0,
            res.data.data[Object.keys(res.data.data)?.[0]]?.series?.length || 6
          )
        });
        setDataFetched(true);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const filterValues = Object.keys(graphData).map((key) => ({
    label: graphData[key].options.title.text,
    value: key
  }));
  filterValues.unshift({ label: 'All', value: 'All' });

  const monthData = {
    1: 'Last month',
    2: 'Last 2 month',
    3: 'Last 3 month',
    6: 'Last 6 month'
  };

  const handleMonthChange = (key) => {
    setStartDate(moment().subtract(key, 'months').format('YYYY-MM'));
    setEndDate(moment().subtract(1, 'months').format('YYYY-MM'));
    handleFetchChartData({
      startDate: moment().subtract(key, 'months').format('YYYY-MM'),
      endDate: moment().subtract(1, 'months').format('YYYY-MM')
    });
  };

  const downloadChart = (chartRef, isShowToast, graphData) => {
    html2canvas(chartRef.current).then((canvas) => {
      const link = document.createElement('a');
      link.download = `${graphData?.options?.title?.text || 'chart'}_${startDate || ''}_${
        endDate || ''
      }.png`;
      link.href = canvas.toDataURL();
      link.click();
      if (isShowToast) toast.success('Chart downloaded');
    });
  };

  const downloadAllCharts = async () => {
    Object.keys(graphData).map((key, index) => {
      downloadChart(allChartsReferenceMap[index], false, graphData[key]);
    });

    toast.success('All charts downloaded');
  };

  const defaultOptions = {
    height: 440,
    columnWidth: 55,
    datalabels: false,
    yAxisTitle: 'Business Days',
    fillOpacity: 1,
    fillColors: [
      '#008ffb',
      '#00e396',
      '#feb019',
      '#ff4560',
      '#775dd0',
      '#ff6384',
      '#36a2eb',
      '#4bc0c0',
      '#ffce56',
      '#adff2f',
      '#6495ed',
      '#20b2aa'
    ],
    width: 80,
    backgroundColor: '#ffffff'
  };
  const [chartOptions, setChartOptions] = useState({
    ...defaultOptions,
    fillColors: defaultOptions.fillColors.slice(0, 6)
  });

  return (
    <>
      <CustomizedBreadcrumbs linksArray={breadCrumbsArray} current={'Analytics'} />
      <h3 className='analytics-header'>Analytics</h3>
      <div
        className='analyticsContainer'
        style={{
          display: 'flex',
          flexDirection: showSettings ? 'row' : 'column',
          justifyContent: showSettings ? 'center' : 'flex-start',
          flexWrap: 'wrap',
          width: '100%',
          minHeight: '1200px'
        }}
      >
        <div
          className='analyticsSettings'
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            className='setDate'
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              marginTop: '20px',
              marginBottom: '20px'
            }}
          >
            <div style={{ width: '30%' }}>
              <DateField
                value={startDate}
                label={'Start Date'}
                name={'startDate'}
                format={'YYYY-MM'}
                views={['year', 'month']}
                onChange={(value) => {
                  setStartDate(value ? moment(value).format('YYYY-MM') : null);
                }}
              />
            </div>
            <div style={{ width: '30%' }}>
              <DateField
                value={endDate}
                label={'End Date'}
                name={'endDate'}
                format={'YYYY-MM'}
                views={['year', 'month']}
                onChange={(value) => {
                  setEndDate(value ? moment(value).format('YYYY-MM') : null);
                }}
              />
            </div>
            <Button
              variant='contained'
              color='primary'
              disabled={!startDate || !endDate}
              onClick={handleFetchChartData}
              style={{
                minWidth: '160px'
              }}
            >
              {!loading ? 'Generate Charts' : <Spinner animation='border' variant='secondary' />}
            </Button>
          </div>
          <h6 style={{ marginBottom: '20px' }}>
            Search Trends:{' '}
            {Object.keys(monthData).map((key) => (
              <span key={key} onClick={() => handleMonthChange(key)} className='monthLink'>
                {`${monthData[key]}`}
              </span>
            ))}
          </h6>
          {showSettings && (
            <>
              <h4
                style={{
                  fontWeight: '400'
                }}
              >
                Configure Chart
              </h4>
              <Editor
                chartOptions={chartOptions}
                setChartOptions={setChartOptions}
                defaultOptions={defaultOptions}
              />
              <DataEditor
                graphData={graphData}
                setGraphData={setGraphData}
                graphDataBackup={graphDataBackup}
              />
            </>
          )}
        </div>
        <div
          className='chartsContainer'
          style={{ width: showSettings ? '60%' : '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{
              width: '100%',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Dropdown
              dropdownValues={filterValues}
              label={'Select Chart'}
              value={'All'}
              handleSelect={(value) => {
                setSelectedFilter(value);
              }}
            />
            <div
              className='downloadChart'
              style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}
            >
              <button onClick={downloadAllCharts} className='tpbtn'>
                <FontAwesomeIcon icon={faDownload} /> Download All
              </button>
              <button onClick={() => setShowSettings(!showSettings)} className='tpbtn'>
                <FontAwesomeIcon icon={faSlidersH} />{' '}
                {showSettings ? 'Hide Settings' : 'Show Settings'}
              </button>
            </div>
          </div>
          <div className='scrollableCharts'>
            {!dataFetched && (
              <div className='info'>
                <h5>Showing dummy charts. Generate Chart to see actual data.</h5>
              </div>
            )}
            {Object.keys(graphData).map((key, index) =>
              selectedFilter === 'All' || key === selectedFilter ? (
                <GraphComponent
                  key={key}
                  setAllChartsReferenceMap={setAllChartsReferenceMap}
                  index={index}
                  downloadChart={downloadChart}
                  chartOptions={chartOptions}
                  graphData={graphData[key]}
                  startDate={startDate}
                  endDate={endDate}
                />
              ) : null
            )}
          </div>
        </div>
      </div>
    </>
  );
}
