//----------------------------- API-CALLS FILE FOR COMMON FRONTEND REPO ONLY---------------------------//

import {
  toSnakeCase,
  covertObjToQueryString
} from '../components/SuperAdmin/frontend-common/core-components/Utilities/utilities';
import AppConstants from '../core-components/AppConstants';
import http from './http';
import { omitBy } from 'lodash';
const companyId = new URLSearchParams(document.location.search).get('company_id');

export const uploadCandidateFile = (data) => {
  return http.post('company/candidate-bulk-upload', data);
};

export const getAddCandidateConfig = (companyId = null) => {
  return http.get(`/company/candidate/config?company_id=${companyId}`);
};

export const validateDataForBulkUpload = (data) => {
  return http.post('candidate/profile/validations', toSnakeCase(data));
};

export const candidatesBulkUpload = (data) => {
  return http.post('candidate/profile/bulk-candidates', toSnakeCase(data));
};

export const getSecuritySettings = (companyId) => {
  return http.get(`/company/security-settings?company_id=${companyId}`);
};

export const postSecuritySettings = (data) => {
  return http.put(`company/security-settings`, data);
};

export const getWalletDetail = (companyId) => {
  return http.get(`payments/credits/wallet-details?company_id=${companyId}`);
};

export const createFriendlyName = (data) => {
  return http.put(`payments/packages/subtype-friendly-name`, toSnakeCase(data));
};

export const validateFriendlyName = (data) => {
  return http.post(`payments/packages/check-subtype-friendly-name`, toSnakeCase(data));
};

export const getViewBuyPackages = (data) => {
  return http.get(`payments/packages`, {
    params: {
      limit: data.limit,
      offset: data.offset,
      company_id: data.companyId
    }
  });
};

export const buyOrder = (data) => {
  return http.post(`payments/packages/purchase`, data);
};

export const getPaymentsPackagesReviewOrder = (packageId, companyId) => {
  return http.get(`payments/packages/review-order`, {
    params: {
      package_id: packageId,
      company_id: companyId
    }
  });
};

export const finalizeRazorpayPayment = (data) => {
  return http.post(`payments/packages/purchase/finalize-razorpay`, data);
};

export const getRequestPaymentUsers = (company_id) => {
  return http.get(`payments/purchase-order/user-details?company_id=${company_id}`);
};
export const requestPayment = (data) => {
  return http.post('/payments/purchase-order/request-payment', data);
};

export const getAdministrators = (limit, offset, company_id) => {
  return http.get('/company/admins', {
    params: {
      company_id,
      limit,
      offset
    }
  });
};

/**
 *
 * @param {Object} data
 * @param {Number} companyId
 * @returns API call to edit brand settings
 */
export const editBrandSettings = ({ companyId, data }) => {
  return http.put(`/company/brand-settings?company_id=${companyId}`, toSnakeCase(data));
};

/**
 *
 * @param {Number} companyId
 * @returns API call to get brand settings
 */
export const getBrandSettings = (companyId) => {
  return http.get(`/company/brand-settings`, {
    params: {
      company_id: companyId
    }
  });
};

/**
 * @param {Object} data
 * @returns API call to update company configuration
 */
export const updateCompanyConfiguration = (data) => {
  return http.put(`/company/alumni-verification/config`, toSnakeCase(data));
};

/**
 * @param {Object} data
 * @returns API call to get company configuration
 */
export const getCompanyConfiguration = ({ companyId }) => {
  return http.get(`/company/alumni-verification/config`, {
    params: {
      company_id: companyId
    }
  });
};

// curl --location 'http://localhost:3011/company/alumni-verification/alumni/record?company_id=2&alumni_employee_record_id=4'
/**
 * @param {Number} companyId - company id
 * @param {Number} employeeId - employee id
 * @returns API call to get alumni record data
 */
export const getAlumniRecord = ({ companyId, employeeId }) => {
  return http.get(`/company/alumni-verification/alumni/record`, {
    params: {
      company_id: companyId,
      alumni_employee_record_id: employeeId
    }
  });
};

/**
 * @param {Object} data
 * @returns API call to add alumni record
 */
export const addAlumniRecord = (data) => {
  return http.post(`/company/alumni-verification/alumni`, data);
};

export const uploadExEmpCSV = (data) => {
  return http.post(`/company/alumni-verification/alumni/bulk-upload`, data);
};

export const validateExEmpData = (data) => {
  return http.post(`/company/alumni-verification/alumni/validate-records`, toSnakeCase(data));
};

export const validateBulkUploadFile = (data) => {
  return http.post(`/company/alumni-verification/alumni/validate-bulk-upload-file`, data);
};

export const getAlumniRequestTrends = () => {
  return http.get(`company/alumni-verification/analytics/requests-trend`);
};

export const getAlumniRecordTrends = () => {
  return http.get(`company/alumni-verification/analytics/records-trend`);
};

export const getAlumniRequestOverview = (month) => {
  return http.get(`company/alumni-verification/analytics/request-overview?date_range=${month}`);
};

export const getAlumniRecordOverview = () => {
  return http.get(`company/alumni-verification/analytics/records-overview`);
};

export const getAlumniTableData = (data, tableType) => {
  const queryParams = covertObjToQueryString(
    omitBy(toSnakeCase(data), (v) => v == null || v == undefined)
  );
  let apiEndpoint = '';
  switch (tableType) {
    case 'DATA_SYNC':
      apiEndpoint = 'company/alumni-verification/dashboard/data-sync-logs?';
      break;
    case 'ALUMNI_RECORDS':
      apiEndpoint = 'company/alumni-verification/dashboard/alumni-records?';
      break;
    default:
      apiEndpoint = 'company/alumni-verification/dashboard/recent-requests?';
      break;
  }
  return http.get(apiEndpoint + queryParams);
};

export const getUniqueRecords = (data) => {
  return http.get(`company/filters?source=ALUMNI`, {
    params: toSnakeCase(data)
  });
};

export const getAlumniSettings = (companyId) => {
  return http.get(`/company/alumni-verification/settings`, {
    params: {
      company_id: companyId
    }
  });
};

export const editAlumniSettings = (data) => {
  return http.put(`/company/alumni-verification/settings`, toSnakeCase(data));
};

export const editEmailCommunication = (data) => {
  return http.put(`company/communication-settings`, toSnakeCase(data));
};

export const getAlumniVerificationDetails = (companyId) => {
  return http.get(`/company/alumni-verification/alumni/details`, {
    params: {
      company_id: companyId
    }
  });
};

// Get report Blob data
export const downloadReportAPI = (companyId, candidateId, token) => {
  return http.get(`/candidate/report/pdf`, {
    params: {
      company_id: companyId,
      candidate_id: candidateId,
      token
    },
    responseType: 'blob'
  });
};

export const matchColumns = (columns, options) => {
  return http.post(`/ai/matchColumns`, {
    prompt: {
      columns,
      options
    }
  });
};

export const deleteAlumniRecord = (employee_record_id, company_id) => {
  return http.delete(
    `/company/alumni-verification/alumni?alumni_employee_record_id=${employee_record_id}&company_id=${company_id}`
  );
};

export const getIntegrations = ({company_id}) => {
  return http.get(`/company/integrations?&company_id=${company_id}`);
};